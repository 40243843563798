import { ICellRendererParams } from "ag-grid-community";
import "./LazyLoading.css";

export const LazyLoading = () => {
	return (
		<>
			<div className="lds-ring">
				<div />
				<div />
				<div />
				<div />
			</div>
		</>
	);
};

export default (props: ICellRendererParams & { loadingMessage: string }) => {
	return <LazyLoading />;
};
