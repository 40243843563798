import { Question } from "./Question";

export const INITIAL_VALUES_QUESTION: Question = {
	QuestionId: null,
	SectionId: null,
	QuestionTypeCode: "R",
	QuestionText: "",
	IncludesComment: true,
	IsRequired: true,
};
