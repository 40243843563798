import { Section } from "./Section";

export const INITIAL_VALUES_SECTION: Section = {
	SectionId: null,
	SectionName: "",
	FormId: null,
	SortOrder: null,
	Subtitle: "",
	BackgroundColor: "",
	LayoutParameters: "",
	IsActive: true,
};
