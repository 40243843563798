import { get } from "react-hook-form";
import { getFormDataByFormId } from "../repositories/FormsRepository";
import { useFormsContext } from "../context/FormsContext";
import { getGroupedInstance } from "@/pages/forms/helpers";
type Props = {
	setDataForPreviewIsLoading: (isLoading: boolean) => void;
	setDataForPreviewError: (err: string) => void;
	FormId: number;
};

export const useSearchDataForFormPreview = ({
	setDataForPreviewIsLoading,
	setDataForPreviewError,
	FormId,
}: Props) => {
	const {
		actions: {
			setFormDataForPreview,
			setFormInstanceSections,
			setFormInstanceQuestions,
		},
	} = useFormsContext();

	return async () => {
		setFormDataForPreview([]);
		setDataForPreviewIsLoading(true);

		const res = await getFormDataByFormId(FormId as number);
		if (res.ok) {
			const rowsSource = get(res, "data.tables[0].rows", []);
			const { formInstance, groupedSections, groupedQuestions } =
				getGroupedInstance(rowsSource);
			if (rowsSource.length) {
				setFormDataForPreview(rowsSource);
				setFormInstanceSections(groupedSections);
				setFormInstanceQuestions(groupedQuestions);
			} else {
				setFormDataForPreview([]);
			}
			setDataForPreviewIsLoading(false);
		} else {
			setDataForPreviewError(res.message);
			setDataForPreviewIsLoading(false);
		}
	};
};
