import {
	FormControl,
	Grid,
	MenuItem,
	Select,
	Theme,
	createStyles,
	makeStyles,
} from "@material-ui/core";
import { get } from "lodash-es";
import { useEffect, useState } from "react";
import { useFormsContext } from "../../../../context/FormsContext";
import { ErrorModal, getUsers } from "@dexteel/mesf-core";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			"& .MuiSelect-root": {
				backgroundColor: "#FFF",
			},
		},
		inputLabel: {
			position: "absolute",
			top: -6,
			left: 8,
			fontSize: 11.3,
			backgroundColor: "#FFF",
			color: "#0000008A",
			width: "auto",
			height: "auto",
			zIndex: 100,
			padding: "0 3px",
			borderRadius: 3,
			fontWeight: 400,
			lineHeight: "1.1876em",
			letterSpacing: "0.04208em",
			fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		},
		formControl: {
			width: "99%",
			marginTop: 8,
		},
		chips: {
			display: "flex",
			flexWrap: "wrap",
		},
		chip: {
			margin: 2,
		},
		noLabel: {
			marginTop: theme.spacing(3),
		},
	}),
);

type Props = {
	value: number;
	onChange: Function;
	disabled?: boolean;
};

export const UsersPicker = ({ value, onChange, disabled }: Props) => {
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState("");
	const {
		state: { users },
		actions: { setUsers },
	} = useFormsContext();

	useEffect(() => {
		const searchUsers = async () => {
			setIsLoading(true);
			const res = await getUsers();
			if (res.ok) {
				const listUsers = get(res, "data.tables[0].rows", []);
				// Ordenar usuarios alfabéticamente
				listUsers.sort((a: any, b: any) =>
					a.UserName.localeCompare(b.UserName),
				);
				setUsers(listUsers);
				setIsLoading(false);
			} else {
				setError(res.message);
				setIsLoading(false);
			}
		};

		searchUsers();
	}, []);

	return (
		<div className={classes.root}>
			{users.length ? (
				<FormControl
					className={classes.formControl}
					style={{ position: "relative" }}
				>
					<p className={classes.inputLabel}>User</p>
					<Select
						disabled={disabled ? disabled : false}
						style={{ padding: "0 !important" }}
						variant="outlined"
						margin="dense"
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						autoWidth={true}
						value={value}
						onChange={onChange as any}
						MenuProps={MenuProps}
					>
						{users.map((user) => (
							<MenuItem
								key={`user-${user.UserId}`}
								value={user.UserId as number}
							>
								<Grid container alignItems="center">
									<div
										style={{
											color: "#FFF",
											fontWeight: 600,
											margin: "auto 0",
											lineHeight: 0,
										}}
									>
										<div style={{ marginLeft: "-40px" }}>
											{user.UserName.charAt(0) || ""}
										</div>
									</div>
									<div>{user.UserName}</div>
								</Grid>
							</MenuItem>
						))}
					</Select>
				</FormControl>
			) : (
				<></>
			)}
			<ErrorModal error={error} onHide={() => setError("")} />
		</div>
	);
};
