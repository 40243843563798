import { Button } from "@material-ui/core";
import { EditableCallbackParams } from "ag-grid-community";
import { useFormsContext } from "../../context/FormsContext";

type Props = {
	params: EditableCallbackParams;
	setOpenSectionsTable: Function;
};

export const ButtonToShowFormSectionsTable = ({
	params,
	setOpenSectionsTable,
}: Props) => {
	const {
		actions: { setFormId, setFormName },
	} = useFormsContext();

	const {
		data: { TotalQuestions, TotalSections },
	} = params;

	const handleOpenFormSectionsTable = () => {
		setOpenSectionsTable(true);
		setFormId(params.data?.FormId);
		setFormName(params.data?.FormName);
	};

	if (params.data) {
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "100%",
					padding: 5,
				}}
			>
				<Button
					style={{ height: "100%", maxHeight: "27px" }}
					fullWidth
					variant={
						params.data?.TotalSections !== 0 ||
						params.data?.TotalQuestions !== 0
							? "contained"
							: "outlined"
					}
					color="primary"
					onClick={() => handleOpenFormSectionsTable()}
				>
					{TotalSections ? TotalSections : 0} Sec /{" "}
					{TotalQuestions ? TotalQuestions : 0} Q
				</Button>
			</div>
		);
	} else return <></>;
};
