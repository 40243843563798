import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { EditableCallbackParams } from "ag-grid-community";
import { useAssetContext } from "@dexteel/mesf-core";

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			"& .ag-icon-menu": {
				display: "none",
			},
			"& .ag-checkbox-input-wrapper": {
				margin: "0 10px",
			},
			"& .ag-cell": {
				alignItems: "center",
				padding: "0 !important",
				backgroundColor: "#FFFF !important",
				border: "unset !important",
			},
			"& .ag-header-cell": {
				padding: "0px !important",
				textAlign: "center !important",
				fontSize: 10,
			},
			"& .ag-root-wrapper": {
				minHeight: "100% !important",
				borderRadius: 3,
				paddingBottom: 45,
			},
			"& .ag-cell-wrapper": {
				width: "95%",
				textAlign: "center",
			},
			"& .ag-body-viewport": {
				borderBottom: "2px solid #DDE2EB",
			},
			"& .ag-menu-option": {
				cursor: "pointer",
			},
		},
	}),
);

type Props = EditableCallbackParams;

export const AssetNameColumn = ({ data }: Props) => {
	const classes = useStyles();
	const [assetName, setAssetName] = useState<string>("");
	const {
		state: { allAssets },
	} = useAssetContext();

	const getAssetName = () => {
		setAssetName(
			allAssets.find((asset) => asset.AssetId === parseInt(data.AssetId))
				?.AssetName || "Unknown",
		);
	};

	useEffect(() => {
		getAssetName();
	}, []);

	if (data) {
		return (
			<Grid
				container
				className={classes.root}
				spacing={1}
				style={{ alignItems: "center", justifyContent: "center" }}
			>
				<Grid item style={{ alignItems: "center", justifyContent: "center" }}>
					{assetName}
				</Grid>
			</Grid>
		);
	} else {
		return <></>;
	}
};
