import { useEffect, useState } from "react";
import { useFormsContext } from "../context/formsContext";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .MuiSelect-root": {
			backgroundColor: "#FFF",
		},
	},
	inputLabel: {
		position: "absolute",
		top: -6,
		left: 8,
		fontSize: 11.3,
		backgroundColor: "#FFF",
		color: "#0000008A",
		width: "auto",
		height: "auto",
		zIndex: 100,
		padding: "0 3px",
		borderRadius: 3,
		fontWeight: 400,
		lineHeight: "1.1876em",
		letterSpacing: "0.04208em",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
	},
}));
interface Props {
	selectedValue: string | undefined;
}

export const FormAssetSelectorModal = () => {
	const {
		state: {
			formsForUser,
			dataToGetFormInstances: { AssetId, FormId },
		},
		actions: { setNewInstanceFormId, setNewInstanceAssetId },
	} = useFormsContext();
	const [selectedValue, setSelectedValue] = useState<string>("");

	const classes = useStyles();

	const handleSelectChange = (event: any) => {
		const selected = event.target.value as string;
		setSelectedValue(selected);
		const [formId, assetId] = selected.split("-");
		setNewInstanceFormId(formId);
		setNewInstanceAssetId(assetId);
	};
	useEffect(() => {
		if (AssetId && FormId) {
			setSelectedValue(`${FormId}-${AssetId}`);
			setNewInstanceFormId(FormId);
			setNewInstanceAssetId(AssetId);
		} else if (
			formsForUser[0] &&
			formsForUser[0].FormId &&
			formsForUser[0].AssetId
		) {
			setSelectedValue(`${formsForUser[0].FormId}-${formsForUser[0].AssetId}`);
			setNewInstanceFormId(formsForUser[0].FormId);
			setNewInstanceAssetId(formsForUser[0].AssetId);
		}
	}, []);

	return (
		<div className={classes.root} style={{ position: "relative" }}>
			<p className={classes.inputLabel}>Form - Asset</p>
			<Select
				variant="outlined"
				margin="dense"
				labelId="demo-simple-select-label"
				id="demo-simple-select"
				value={selectedValue}
				onChange={handleSelectChange}
				style={{ minWidth: "100%" }}
			>
				{formsForUser.map((form) => (
					<MenuItem
						key={`logId-${form.FormId}-${form.AssetId}`}
						value={`${form.FormId}-${form.AssetId}`}
					>
						<Grid container alignItems="center">
							{`${form.FormName} - ${form.AssetName}`}
						</Grid>
					</MenuItem>
				))}
			</Select>
		</div>
	);
};
