import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogProps,
	DialogTitle,
	Snackbar,
	makeStyles,
} from "@material-ui/core";
import { SyntheticEvent, useState } from "react";
import { useFormsContext } from "../../../../context/FormsContext";
import { Alert } from "@material-ui/lab";
import { deleteFormAsset } from "../../../../repositories/FormsRepository";
import { ErrorModal } from "@dexteel/mesf-core";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .input-group-text": {
			display: "none",
		},
		"& .MuiDialogContent-dividers": {
			padding: 0,
		},
	},
	modalTitle: {
		backgroundColor: "#ced2cc",
		color: "#495057",
		textAlign: "center",
	},
	modalTitleCloseWO: {
		backgroundColor: "#28a745",
		color: "white",
		textAlign: "center",
	},
	modalWarning: {
		color: "white",
		backgroundColor: "#dc3545",
		textAlign: "center",
	},
	messageTitle: {
		fontSize: 15,
		fontWeight: "bold",
		color: "#0000008A",
	},
	formControl: {
		margin: theme.spacing(2),
		minWidth: 120,
	},
	errorLabel: {
		fontSize: 12,
		color: "#F44336",
	},
	infoLabel: {
		fontSize: 12,
		color: "#757575",
	},
}));

type Props = {
	show: boolean;
	onHide: (onHide: boolean) => void;
};

export const DeleteFormAssetModal = ({ show, onHide }: Props) => {
	const classes = useStyles();
	const [fullWidth, setFullWidth] = useState(true);
	const [maxWidth, setMaxWidth] = useState<DialogProps["maxWidth"]>("sm");
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [error, setError] = useState<string>("");
	const [formAssetRemovedSuccess, setFormAssetRemovedSuccess] =
		useState<boolean>(false);
	const {
		state: {
			FormId,
			userFormAssetData: { AssetId },
		},
	} = useFormsContext();

	const handleCancel = () => {
		setIsSubmitLoading(false);
		onHide(false);
	};

	const handleClose = (event?: SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setFormAssetRemovedSuccess(false);
	};

	const handleRemoveFormAsset = async () => {
		setIsSubmitLoading(true);

		const res = await deleteFormAsset(FormId, AssetId);
		if (res.ok) {
			setFormAssetRemovedSuccess(true);
			setIsSubmitLoading(false);
			onHide(true);
		} else {
			setError(res.message);
			setIsSubmitLoading(false);
		}
	};

	return (
		<>
			<Dialog
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				open={show}
				onClose={() => handleCancel()}
				disableEnforceFocus
				className={classes.root}
			>
				<DialogTitle id="responsive-dialog-title">{"WARNING"}</DialogTitle>
				<DialogContent>
					<DialogContentText style={{ padding: 0, margin: 0 }}>
						Be careful, once deleted you will not be able to recover this, are
						you sure you want to delete?
					</DialogContentText>
				</DialogContent>
				<DialogActions style={{ padding: "16px 24px" }}>
					<Button onClick={() => onHide(false)} autoFocus color="default">
						CANCEL
					</Button>
					<Button
						autoFocus
						color="secondary"
						startIcon={isSubmitLoading && <CircularProgress size="1rem" />}
						onClick={handleRemoveFormAsset}
					>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
			<Snackbar
				open={formAssetRemovedSuccess}
				autoHideDuration={2500}
				onClose={handleClose}
			>
				<Alert severity="info" onClose={handleClose}>
					Success
				</Alert>
			</Snackbar>
			<ErrorModal error={error} onHide={() => setError("")} />
		</>
	);
};
