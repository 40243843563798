import "./LazyLoading.css";

export const LazyLoading = () => {
	return (
		<>
			<div className="lds-ring">
				<div />
				<div />
				<div />
				<div />
			</div>
		</>
	);
};
