import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			"& .MuiSelect-root": {
				backgroundColor: "#FFF",
			},
		},
		inputLabel: {
			position: "absolute",
			top: -6,
			left: 8,
			fontSize: 11.5,
			backgroundColor: "#FFF",
			color: "rgba(0, 0, 0, 0.38)",
			width: "auto",
			height: "auto",
			zIndex: 100,
			padding: "0 3px",
			borderRadius: 3,
			fontWeight: 400,
			lineHeight: "1.1876em",
			letterSpacing: "0.04208em",
			fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		},
		formControl: {
			width: "99%",
			marginTop: 8,
		},
		chips: {
			display: "flex",
			flexWrap: "wrap",
		},
		chip: {
			margin: 2,
		},
		noLabel: {
			marginTop: theme.spacing(3),
		},
	}),
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

type Props = {
	value: string;
	onChange: Function;
};

export const ScheduleCodePicker = ({ value, onChange }: Props) => {
	const classes = useStyles();

	const SchedulesCodes = [
		{
			SchedulingId: 1,
			SchedulingNameCode: "None",
			SchedulePolicyCode: "N",
		},
		{
			SchedulingId: 2,
			SchedulingNameCode: "Shift",
			SchedulePolicyCode: "S",
		},
		{
			SchedulingId: 3,
			SchedulingNameCode: "Daily",
			SchedulePolicyCode: "D",
		},
		{
			SchedulingId: 4,
			SchedulingNameCode: "Weekly",
			SchedulePolicyCode: "W",
		},
		{
			SchedulingId: 5,
			SchedulingNameCode: "Monthly",
			SchedulePolicyCode: "M",
		},
	];
	return (
		<div className={classes.root} style={{ position: "relative" }}>
			{SchedulesCodes.length && (
				<FormControl className={classes.formControl}>
					<p className={classes.inputLabel}>Schedule</p>
					<Select
						style={{ padding: "0 !important" }}
						variant="outlined"
						margin="dense"
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						autoWidth={true}
						value={value}
						onChange={onChange as any}
						MenuProps={MenuProps}
					>
						{SchedulesCodes.map((sc) => (
							<MenuItem
								key={`ScheduleId-${sc.SchedulingId}`}
								value={sc.SchedulePolicyCode}
							>
								<div style={{ display: "flex", alignItems: "center" }}>
									<div>{sc.SchedulingNameCode}</div>
								</div>
							</MenuItem>
						))}
					</Select>
				</FormControl>
			)}
		</div>
	);
};
