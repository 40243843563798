import { get } from "react-hook-form";
import { getFormAssetsByFormAssetId } from "../repositories/FormsRepository";
import { useFormsContext } from "../context/FormsContext";
type Props = {
	setIsLoading: (isLoading: boolean) => void;
	setError: (err: string) => void;
	FormAssetIdToSearchUserFormAssets: number;
};

export const useSearchFormAsset = ({
	setIsLoading,
	setError,
	FormAssetIdToSearchUserFormAssets,
}: Props) => {
	const {
		actions: { setFormAsset },
	} = useFormsContext();

	const searchFormAsset = async () => {
		setFormAsset([]);
		setIsLoading(true);
		const res = await getFormAssetsByFormAssetId(
			FormAssetIdToSearchUserFormAssets,
		);
		if (res.ok) {
			const rowsSource = get(res, "data.tables[0].rows", []);
			if (rowsSource.length) {
				setFormAsset(rowsSource[0]);
			} else {
				setFormAsset([]);
			}
			setIsLoading(false);
		} else {
			setError(res.message);
			setIsLoading(false);
		}
	};
	return searchFormAsset;
};
