import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { useEffect, useState } from "react";
import { FormsTable } from "./components/formsTable/formsTable";
import { useFilterInQuery } from "./components/filters/hooks/useFilterInQuery";
import { useSearchForms } from "./hooks/useSearchForms";

type Props = {};

export const ConfigurationFormsPage = (prop: Props) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>("");
	// const { state: { searchText }, actions: { setSearchText } } = useFormsContext();
	// const [searchText, setSearchText] = useState("");
	// const setupIsReady = useFilterInQuery([
	// 	{
	// 		key: 'search',
	// 		value: searchText,
	// 		setter: setSearchText
	// 	}
	// ])

	const searchForms = useSearchForms({ setIsLoading, setError });

	useEffect(() => {
		searchForms();
	}, []);

	return (
		<Grid container justifyContent="center" alignItems="center">
			<Grid item md={12} xs={12} style={{ margin: 0 }}>
				<Paper
					elevation={1}
					style={{
						width: "100%",
						padding: "10px 0 20px",
						borderRadius: "0.75rem",
					}}
				>
					<FormsTable
						isLoading={isLoading}
						searchForms={searchForms}
						error={error}
						setError={setError}
					/>
				</Paper>
			</Grid>
		</Grid>
	);
};
