import { Asset } from "@dexteel/mesf-core/dist/models/Asset";

interface AssetCode {
	id: number;
	parentId: number;
	name: string;
	isActive: boolean | null;
}

export interface TreeCode {
	id: number;
	name: string;
	parentId: number | null;
	TreeId: number;
	ParentTreeId: number | null;
	Title: string | null;
	Abbrev: string | null;
	UniqueKey: string | null;
	isLeaf: boolean | null;
	isActive: boolean | null;
	children?: TreeCode[] | null;
}

const TransformAssetCodeModel = (data: Asset): AssetCode => {
	const model: AssetCode = {
		id: data.AssetId,
		parentId: data.ParentAssetId as number,
		name: data.AssetName,
		isActive: true,
	};
	return model;
};

const TransformTreeCodeModel = (data: {
	TreeId: number;
	ParentTreeId: number;
	Title: string | null;
	Abbrev: string | null;
	UniqueKey: string | null;
	isLeaf: boolean | null;
}): TreeCode => {
	const model: TreeCode = {
		id: data.TreeId,
		name: data.Title || "",
		parentId: data.ParentTreeId ?? 0,
		TreeId: data.TreeId,
		ParentTreeId: data.ParentTreeId,
		Title: data.Title,
		Abbrev: data.Abbrev,
		UniqueKey: data.UniqueKey,
		isLeaf: data.isLeaf,
		isActive: true,
	};
	return model;
};

export { TransformAssetCodeModel, TransformTreeCodeModel };
