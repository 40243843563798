import { Button } from "@material-ui/core";
import { EditableCallbackParams } from "ag-grid-community";
import { useFormsContext } from "../../../context/FormsContext";

type Props = {
	params: EditableCallbackParams;
	setOpenQuestionsAndOptionsTable: Function;
};

export const ButtonToShowOptionsQuestions = ({
	params,
	setOpenQuestionsAndOptionsTable,
}: Props) => {
	const {
		actions: { setSectionId, setSectionName },
	} = useFormsContext();

	const {
		data: { TotalQuestions, TotalOptions },
	} = params;

	const handleOpenFormSectionsTable = () => {
		setOpenQuestionsAndOptionsTable(true);
		setSectionId(params.data?.SectionId);
		setSectionName(params.data?.SectionName);
	};

	if (params.data) {
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "100%",
					padding: 5,
				}}
			>
				<Button
					style={{ height: "100%", maxHeight: "27px", minWidth: 130 }}
					fullWidth
					variant={params.data?.AssetCount !== 0 ? "contained" : "outlined"}
					color="primary"
					onClick={() => handleOpenFormSectionsTable()}
				>
					{TotalQuestions ? TotalQuestions : 0} Q /{" "}
					{TotalOptions ? TotalOptions : 0} O
				</Button>
			</div>
		);
	} else return <></>;
};
