import { ErrorModal } from "@dexteel/mesf-core";
import {
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	DialogTitle,
	MenuItem,
	Select,
	TextField,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { SyntheticEvent, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Section } from "../../../models/Section";
import { useFormsContext } from "../../../context/FormsContext";
import { INITIAL_VALUES_SECTION } from "../../../models/SectionInitialValues";
import { upsertSection } from "../../../repositories/FormsRepository";
import { ColorPicker } from "./color-picker";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .input-group-text": {
			display: "none",
		},
		"& .MuiDialogContent-dividers": {
			padding: 0,
		},
		"& .MuiSelect-root": {
			height: 40,
			backgroundColor: "unset",
			display: "flex",
			alignItems: "center",
		},
	},
	assetPicker: {
		"& .MuiGrid-item": {
			width: "500px",
		},
	},

	checkbox: {
		userSelect: "none",
	},
	modalTitle: {
		backgroundColor: "#ced2cc",
		color: "#495057",
		textAlign: "center",
	},
	modalWarning: {
		color: "white",
		backgroundColor: "#dc3545",
		textAlign: "center",
	},
	messageTitle: {
		fontSize: 15,
		fontWeight: "bold",
		color: "#0000008A",
	},
	errorLabel: {
		fontSize: 12,
		color: "#F44336",
	},
	inputEditable: {
		"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			border: "2px solid #3F51B5",
		},
	},
	Subtitle: {
		"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			border: "1px solid #3F51B5",
		},
	},
}));

type Props = {
	show: boolean;
	onHide: (shouldUpdate: boolean) => void;
	data: Section | null;
	newMode?: boolean;
};

export const NewAndEditSectionModal = ({
	show,
	onHide,
	data,
	newMode = false,
}: Props) => {
	const classes = useStyles();
	const {
		state: { FormId, SectionId },
		actions: {
			setSectionNameSelectedInTable,
			setSectionBackgroundColorSelectedInTable,
			setSectionSubtitleSelectedInTable,
			setSectionLayoutParametersSelectedInTable,
		},
	} = useFormsContext();
	const [editedSuccess, setEditedSuccess] = useState(false);
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);

	const [error, setError] = useState<string>("");
	const [deleteByIdError, setDeleteByIdError] = useState<string>("");
	const {
		control,
		handleSubmit,
		watch,
		setValue,
		formState: { errors },
		reset,
	} = useForm<Section>({ defaultValues: INITIAL_VALUES_SECTION });

	//states
	const [fullWidth, setFullWidth] = useState(true);
	const [maxWidth, setMaxWidth] = useState<DialogProps["maxWidth"]>("sm");

	const handleClose = (event?: SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setEditedSuccess(false);
	};
	const handleCancel = async () => {
		reset();
		onHide(false);
	};

	const sectionName = watch("SectionName");

	const changeSectionName = () => {
		setSectionNameSelectedInTable(sectionName);
	};

	const subtitle = watch("Subtitle");

	const changeSubtitle = () => {
		setSectionSubtitleSelectedInTable(subtitle);
	};

	const layoutParameters = watch("LayoutParameters");

	const changeLayoutParameters = () => {
		setSectionSubtitleSelectedInTable(layoutParameters);
	};

	const backgroundColor = watch("BackgroundColor");

	const changeBackgroundColor = () => {
		setSectionBackgroundColorSelectedInTable(backgroundColor);
	};

	const onSubmit = async (sectionData: Section) => {
		setIsSubmitLoading(true);
		const res = await upsertSection(
			FormId,
			SectionId,
			sectionData?.SectionName,
			sectionData?.BackgroundColor as string,
			sectionData?.Subtitle as string,
			sectionData?.LayoutParameters as string,
		);
		if (res.ok) {
			reset();
			setIsSubmitLoading(false);
			setEditedSuccess(true);
			onHide(true);
		} else {
			setIsSubmitLoading(false);
			setError(res.message);
		}
	};

	useEffect(() => {
		if (data != null && show) {
			reset(INITIAL_VALUES_SECTION);
			setSectionNameSelectedInTable(data.SectionName);
			setSectionBackgroundColorSelectedInTable(data.BackgroundColor);
			setSectionSubtitleSelectedInTable(data.Subtitle);
			setSectionLayoutParametersSelectedInTable(data.LayoutParameters);
			setValue("SectionName", data.SectionName);
			setValue("BackgroundColor", data.BackgroundColor);
			setValue("Subtitle", data.Subtitle);
			setValue("LayoutParameters", data.LayoutParameters);
			setIsSubmitLoading(false);
		} else {
			reset(INITIAL_VALUES_SECTION);
		}
	}, [show]);

	useEffect(() => {
		changeSectionName();
	}, [sectionName]);

	useEffect(() => {
		changeBackgroundColor();
	}, [backgroundColor]);

	useEffect(() => {
		changeSubtitle();
	}, [subtitle]);

	useEffect(() => {
		changeLayoutParameters();
	}, [layoutParameters]);

	return (
		<>
			<Dialog
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				open={show}
				onClose={() => handleCancel()}
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container style={{ padding: "15px 0 20px" }}>
						<DialogTitle
							style={{
								padding: "5px 40px 10px 24px",
								marginRight: 20,
								width: "100%",
							}}
						>
							<Grid item md={12} xs={12}>
								<h1 style={{ fontSize: "1.7em" }}>
									{newMode
										? `SECTION: ${sectionName}`
										: `EDIT: ${data?.SectionName}`}
								</h1>
							</Grid>
						</DialogTitle>
						<Grid container>
							<DialogContent dividers>
								<Grid container>
									<Grid
										item
										md={6}
										xs={6}
										style={{ padding: 0, marginTop: "-5px" }}
									>
										<Controller
											name="SectionName"
											control={control}
											rules={{
												validate: (value) => value !== "",
											}}
											render={({ field: { onChange, value } }) => (
												<TextField
													label="Section Name"
													variant="outlined"
													fullWidth
													error={!!errors.SectionName}
													margin="dense"
													autoComplete="off"
													value={value}
													onChange={(e) => onChange(e)}
													className={classes.inputEditable}
												/>
											)}
										/>
									</Grid>
									<Grid
										container
										item
										md={6}
										xs={6}
										style={{
											alignItems: "center",
											justifyContent: "flex-end",
											paddingLeft: 20,
										}}
										spacing={2}
									>
										<Grid item md={6} xs={6}>
											<Controller
												name="BackgroundColor"
												control={control}
												render={({ field: { value, onChange } }) => (
													<ColorPicker
														onChange={onChange}
														color={value as any}
													/>
												)}
											/>
											{errors.BackgroundColor && (
												<span className={classes.errorLabel}>
													{"Select Color"}
												</span>
											)}
										</Grid>
										<Grid item md={6} xs={6}>
											<Controller
												name="LayoutParameters"
												control={control}
												render={({ field: { value, onChange } }) => (
													<div className={classes.root}>
														<Select
															value={value}
															variant={"outlined"}
															onChange={(e) =>
																onChange(e.target.value as string)
															}
															style={{ height: "40px", paddingLeft: 10 }}
														>
															<MenuItem value="Column=1">Column 1</MenuItem>
															<MenuItem value="Column=2">Column 2</MenuItem>
															<MenuItem value="Column=3">Column 3</MenuItem>
														</Select>
													</div>
												)}
											/>
										</Grid>
									</Grid>
									<Grid container justifyContent="flex-end">
										<Grid
											item
											md={12}
											xs={12}
											style={{
												marginBottom: "10px",
												marginTop: "10px",
												padding: 0,
											}}
										>
											<Controller
												name="Subtitle"
												control={control}
												render={({ field: { onChange, value } }) => (
													<TextField
														label="Subtitle"
														variant="outlined"
														fullWidth
														error={!!errors.Subtitle}
														margin="dense"
														autoComplete="off"
														value={value}
														onChange={(e) => onChange(e)}
														className={classes.inputEditable}
													/>
												)}
											/>
										</Grid>
									</Grid>
								</Grid>
							</DialogContent>
							<Grid
								item
								md={12}
								xs={12}
								justifyContent="flex-end"
								style={{ marginTop: "15px" }}
							>
								<DialogActions style={{ padding: "0px 24px" }}>
									<Grid
										container
										justifyContent="flex-end"
										spacing={2}
										style={{ margin: 0 }}
									>
										<Grid item md={3} xs={4}>
											<Button
												fullWidth
												variant="contained"
												color="default"
												onClick={handleCancel}
											>
												Cancel
											</Button>
										</Grid>
										<Grid item md={3} xs={4} style={{ paddingRight: 0 }}>
											<Button
												fullWidth
												startIcon={
													isSubmitLoading && <CircularProgress size="1rem" />
												}
												disabled={isSubmitLoading}
												variant="contained"
												color="primary"
												type="submit"
											>
												Save
											</Button>
										</Grid>
									</Grid>
								</DialogActions>
							</Grid>
						</Grid>
					</Grid>
				</form>
			</Dialog>
			<Snackbar
				open={editedSuccess}
				autoHideDuration={2500}
				onClose={handleClose}
			>
				<Alert severity="info" onClose={handleClose}>
					Success
				</Alert>
			</Snackbar>
			<ErrorModal error={error} onHide={() => setError("")} />
			<ErrorModal
				error={deleteByIdError}
				onHide={() => setDeleteByIdError("")}
			/>
		</>
	);
};
