import { CustomSuspense } from "@/components/custom-suspense/custom-suspense";
import { LazyLoading } from "@/components/loaders/Loader/LazyLoading";
import { WorkOrderModalsProvider } from "@/shared/work-orders-modals/context/work-order-modals.context";
import { useUserContext } from "@dexteel/mesf-core";
import { Grid } from "@material-ui/core";
import { lazy } from "react";
import { Route, Routes } from "react-router-dom";

const WorkOrdersPage = lazy(() => import("@/pages/work-orders"));
const TaskList = lazy(() => import("@/pages/task-list"));
const MyMap = lazy(() => import("@/pages/map"));
const AssetDetailsPage = lazy(() => import("@/pages/asset-details"));
const TemplatesPage = lazy(() => import("@/pages/templates"));
const DrawingsSearchPage = lazy(
	() => import("@/pages/drawings/search-documents"),
);
const DrawingsBrowserPage = lazy(
	() => import("@/pages/drawings/browser-documents"),
);
const FakeData = lazy(() =>
	import("@/pages/fake-data").then((mod) => ({ default: mod.FakeData })),
);
const FormsPage = lazy(() => import("@/pages/forms"));

const LogbookIndex = lazy(() => import("@/pages/logbook"));

const CustomRoutes = () => {
	const {
		state: { defaultAreaId },
	} = useUserContext();
	return defaultAreaId ? (
		<WorkOrderModalsProvider>
			<Routes>
				<Route
					path="/work-orders"
					element={
						<CustomSuspense>
							<WorkOrdersPage />
						</CustomSuspense>
					}
				/>
				<Route
					path="/work-orders/:AssetIdParam"
					element={
						<CustomSuspense>
							<WorkOrdersPage />
						</CustomSuspense>
					}
				/>
				<Route
					path="/templates"
					element={
						<CustomSuspense>
							<TemplatesPage />
						</CustomSuspense>
					}
				/>
				<Route
					path="/templates/:AssetIdParam"
					element={
						<CustomSuspense>
							<TemplatesPage />
						</CustomSuspense>
					}
				/>
				<Route
					path="/task-list"
					element={
						<CustomSuspense>
							<TaskList />
						</CustomSuspense>
					}
				/>
				<Route
					path="/task-list/:AssetIdParam"
					element={
						<CustomSuspense>
							<TaskList />
						</CustomSuspense>
					}
				/>
				<Route
					path="/map/main"
					element={
						<CustomSuspense>
							<MyMap />
						</CustomSuspense>
					}
				/>
				<Route
					path="/home"
					element={
						<CustomSuspense>
							<MyMap />
						</CustomSuspense>
					}
				/>
				<Route
					path="/map/*"
					element={
						<CustomSuspense>
							<MyMap />
						</CustomSuspense>
					}
				/>
				<Route
					path="/asset-details"
					element={
						<CustomSuspense>
							<AssetDetailsPage />
						</CustomSuspense>
					}
				/>
				<Route
					path="/asset-details/:AssetIdParam"
					element={
						<CustomSuspense>
							<AssetDetailsPage />
						</CustomSuspense>
					}
				/>
				<Route
					path="/search-documents/*"
					element={
						<CustomSuspense>
							<DrawingsSearchPage />
						</CustomSuspense>
					}
				/>
				<Route
					path="/browser-documents/*"
					element={
						<CustomSuspense>
							<DrawingsBrowserPage />
						</CustomSuspense>
					}
				/>
				<Route
					path="/fake-data/"
					element={
						<CustomSuspense>
							<FakeData />
						</CustomSuspense>
					}
				/>
				<Route
					path="/forms/"
					element={
						<CustomSuspense>
							<FormsPage />
						</CustomSuspense>
					}
				/>
				<Route
					path="/logbook/*"
					element={
						<CustomSuspense>
							<LogbookIndex />
						</CustomSuspense>
					}
				/>
			</Routes>
		</WorkOrderModalsProvider>
	) : (
		<Grid
			container
			style={{
				width: "100%",
				justifyContent: "center",
				alignItems: "center",
				minHeight: "80vh",
			}}
		>
			<LazyLoading />
		</Grid>
	);
};

export { CustomRoutes };
