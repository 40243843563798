import { createContext, default as React, ReactNode, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { WorkOrderModalsReducer } from "../reducers/work-order-modals.reducer";

const WorkOrderModalsContext = createContext({
	state: WorkOrderModalsReducer.getInitialState(),
	actions: WorkOrderModalsReducer.actions,
});

export const useWorkOrderModalsContext = () =>
	useContext(WorkOrderModalsContext);

export const WorkOrderModalsProvider = ({
	children,
}: { children: ReactNode }) => {
	const [state, actions] = useComplexState({
		initialState: WorkOrderModalsReducer.getInitialState(),
		reducers: WorkOrderModalsReducer.caseReducers,
	});

	return (
		<WorkOrderModalsContext.Provider value={{ state, actions }}>
			{children}
		</WorkOrderModalsContext.Provider>
	);
};
