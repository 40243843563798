import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { FormAsset } from "../models/FormAsset";

export const getForms = async (SearchText: string | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "SearchText", value: SearchText });

	const resp = await apiService.callV2(`[FORMS].[GetForms]`, parameters);
	return resp;
};

export const getForm = async (FormId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "FormId", value: FormId });

	const resp = await apiService.callV2(`[FORMS].[GetForm]`, parameters);
	return resp;
};

export const upsertForm = async (
	FormId: number | null,
	FormName: string,
	IsEnabled: boolean,
	LayoutCode: string,
	IncludesComment: boolean,
) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "FormId", value: FormId });
	parameters.push({ name: "FormName", value: FormName });
	parameters.push({ name: "IsEnabled", value: IsEnabled });
	parameters.push({ name: "LayoutCode", value: LayoutCode });
	parameters.push({ name: "IncludesComment", value: IncludesComment });

	const resp = await apiService.callV2(`[FORMS].[UpsertForm]`, parameters);
	return resp;
};

export const deleteForm = async (FormId: number) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "FormId", value: FormId });

	const resp = await apiService.callV2(`[FORMS].[DeleteForm]`, parameters);
	return resp;
};

export const getSectionsByFormId = async (
	FormId: number,
	searchSectionsText: string | null,
) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "FormId", value: FormId });
	parameters.push({ name: "Search", value: searchSectionsText });

	const resp = await apiService.callV2(
		`[FORMS].[GetSectionsByFormId]`,
		parameters,
	);
	return resp;
};

export const upsertSection = async (
	FormId: number | null,
	SectionId: number | null,
	SectionName: string | null,
	BackgroundColor: string | null,
	Subtitle: string | null,
	LayoutParameters: string | null,
) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "SectionId", value: SectionId });
	parameters.push({ name: "SectionName", value: SectionName });
	parameters.push({ name: "FormId", value: FormId });
	parameters.push({ name: "BackgroundColor", value: BackgroundColor });
	parameters.push({ name: "Subtitle", value: Subtitle });
	parameters.push({ name: "LayoutParameters", value: LayoutParameters });

	const resp = await apiService.callV2(`[FORMS].[UpsertSection]`, parameters);
	return resp;
};

export const deleteSection = async (SectionId: number) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "SectionId", value: SectionId });

	const resp = await apiService.callV2(`[FORMS].[DeleteSection]`, parameters);
	return resp;
};

export const upsertQuestion = async (
	QuestionId: number | null,
	SectionId: number | null,
	QuestionTypeCode: string,
	QuestionText: string,
	IncludesComment: boolean,
	IsRequired: boolean,
) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "QuestionId", value: QuestionId });
	parameters.push({ name: "SectionId", value: SectionId });
	parameters.push({ name: "QuestionTypeCode", value: QuestionTypeCode });
	parameters.push({ name: "QuestionText", value: QuestionText });
	parameters.push({ name: "IncludesComment", value: IncludesComment });
	parameters.push({ name: "IsRequired", value: IsRequired });

	const resp = await apiService.callV2(`[FORMS].[UpsertQuestion]`, parameters);
	return resp;
};

export const getQuestionsBySectionId = async (SectionId: number) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "SectionId", value: SectionId });

	const resp = await apiService.callV2(
		`[FORMS].[GetQuestionsBySectionId]`,
		parameters,
	);
	return resp;
};

export const deleteQuestion = async (QuestionId: number) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "QuestionId", value: QuestionId });

	const resp = await apiService.callV2(`[FORMS].[DeleteQuestion]`, parameters);
	return resp;
};

//OPTIONS

export const getOptionsByQuestionId = async (QuestionId: number) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "QuestionId", value: QuestionId });

	const resp = await apiService.callV2(
		`[FORMS].[GetOptionsByQuestionId]`,
		parameters,
	);
	return resp;
};

export const upsertOption = async (
	OptionId: number | null,
	QuestionId: number | null,
	OptionText: string,
	OptionTypeCode: string,
	IsEnabled: boolean,
) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "OptionId", value: OptionId });
	parameters.push({ name: "OptionText", value: OptionText });
	parameters.push({ name: "QuestionId", value: QuestionId });
	parameters.push({ name: "IsEnabled", value: IsEnabled });
	parameters.push({ name: "OptionTypeCode", value: OptionTypeCode });
	parameters.push({ name: "SortOrder", value: null });

	const resp = await apiService.callV2(`[FORMS].[UpsertOption]`, parameters);
	return resp;
};

export const deleteOption = async (OptionId: number) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "OptionId", value: OptionId });

	const resp = await apiService.callV2(
		`[FORMS].[DeleteOptionById]`,
		parameters,
	);
	return resp;
};

// FORM ASSET USERS

export const getFormAssets = async (FormId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "FormId", value: FormId });

	const resp = await apiService.callV2(`[FORMS].[GetFormAssets]`, parameters);
	return resp;
};

export const getFormAssetsByFormAssetId = async (
	FormAssetId: number | null,
) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "FormAssetId", value: FormAssetId });

	const resp = await apiService.callV2(
		`[FORMS].[GetFormAssetsByFormAssetId]`,
		parameters,
	);
	return resp;
};

export const upsertFormAsset = async (data: FormAsset) => {
	const {
		FormAssetId,
		FormId,
		AssetId,
		SchedulePolicyCode,
		Periods,
		StartDate,
	} = data;
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "FormAssetId", value: FormAssetId });
	parameters.push({ name: "AssetId", value: AssetId });
	parameters.push({ name: "FormId", value: FormId });
	parameters.push({ name: "SchedulePolicyCode", value: SchedulePolicyCode });
	parameters.push({ name: "Periods", value: parseInt(Periods as string) });
	parameters.push({ name: "StartDate", value: StartDate });

	const resp = await apiService.callV2(`[FORMS].[UpsertFormAsset]`, parameters);
	return resp;
};

export const deleteFormAsset = async (
	FormId: number | null,
	AssetId: number | null,
) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "FormId", value: FormId });
	parameters.push({ name: "AssetId", value: AssetId });

	const resp = await apiService.callV2(`[FORMS].[DeleteFormAsset]`, parameters);
	return resp;
};

export const getUsersFormAssets = async (FormAssetId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "FormAssetId", value: FormAssetId });

	const resp = await apiService.callV2(
		`[FORMS].[GetUsersFormAssets]`,
		parameters,
	);
	return resp;
};

export const deleteUserFormAsset = async (
	UserId: number | null,
	FormAssetId: number | null,
) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "UserId", value: UserId });
	parameters.push({ name: "FormAssetId", value: FormAssetId });

	const resp = await apiService.callV2(
		`[FORMS].[DeleteUserFormAsset]`,
		parameters,
	);
	return resp;
};

export const upsertUserFormAsset = async (
	UserId: number | null,
	FormAssetId: number | null,
	IsDefault: boolean,
) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "UserId", value: UserId });
	parameters.push({ name: "FormAssetId", value: FormAssetId });
	parameters.push({ name: "IsDefault", value: IsDefault });

	const resp = await apiService.callV2(
		`[FORMS].[UpsertUserFormAsset]`,
		parameters,
	);
	return resp;
};
export const getFormDataByFormId = async (FormId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "FormId", value: FormId });

	const resp = await apiService.callV2(
		`[FORMS].[GetFormDataByFormId]`,
		parameters,
	);
	return resp;
};

export const cloneForm = async (FormId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "OriginalFormId", value: FormId });

	const resp = await apiService.callV2(`[FORMS].[CloneForm]`, parameters);
	return resp;
};

export const cloneSection = async (SectionId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "OriginalSectionId", value: SectionId });

	const resp = await apiService.callV2(`[FORMS].[CloneSection]`, parameters);
	return resp;
};

export const cloneQuestionAndOptions = async (QuestionId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "OriginalQuestionId", value: QuestionId });

	const resp = await apiService.callV2(
		`[FORMS].[CloneQuestionAndOptions]`,
		parameters,
	);
	return resp;
};
