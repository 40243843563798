export const tagColors: string[] = [
	"#FFFFFF",
	"#5eaf6050",
	"#00800050",
	"#62bd9c50",
	"#53a28550",
	"#add8e650",
	"#0000ff50",
	"#5293c550",
	"#3d556f50",
	"#a362bf50",
	"#dab0d850",
	"#00000050",
	"#e74b3c50",
	"#ff000050",
	"#bf3b2c50",
];
