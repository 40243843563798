import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { set, useForm } from "react-hook-form";
import {
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	DialogTitle,
	Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import { useFormsContext } from "../context/formsContext";
import { ParametersNewForm } from "../models/ParametersNewForm";

import { Stepper, Step, StepLabel, makeStyles } from "@material-ui/core";
import { FormAndAssetStep, ProductionDateStep, ShiftStep } from "./";
import { useCreateNewInstance, useGetShiftsByProductionDate } from "../hooks";

import CustomLoadingOverlay from "../../../components/loaders/LazyLoading/LazyLoading";
import { ErrorModal } from "@dexteel/mesf-core";

const useStyles = makeStyles((theme) => ({
	stepper: {
		background: "none",
	},
	stepIcon: {
		color: theme.palette.primary.main,
		background: theme.palette.background.paper, // Background color of step circle
		"&$completedStepIcon": {
			color: theme.palette.success.main,
		},
	},
	completedStepIcon: {}, // Marker for completed step
}));

const steps = [
	"Select Production Date",
	"Select Shift",
	"Select Form and Asset",
	"Answer Questions",
];

type Props = {
	show: boolean;
	onHide: (onHide: boolean) => void;
};

export const CreateForm = ({ show, onHide }: Props) => {
	const {
		state: { newInstanceId, apiMessage },
		actions: {
			setFormInstanceSections,
			setFormInstanceQuestions,
			setFormInstanceModifiedRows,
			setTriggerEditIntance,
			setNewInstanceId,
			setApiMessage,
		},
	} = useFormsContext();

	const [error, setError] = useState("");
	const [open, setOpen] = useState(false);
	const [showDialogMessage, setShowDialogMessage] = useState(false);
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [fullWidth, setFullWidth] = useState(true);
	const [maxWidth, setMaxWidth] = useState<DialogProps["maxWidth"]>("md");
	const [activeStep, setActiveStep] = useState(0);
	const classes = useStyles();
	const { reset } = useForm<ParametersNewForm>();

	const getShiftsByProductionDate = useGetShiftsByProductionDate();
	const createNewInstance = useCreateNewInstance();

	const handleNext = () => {
		if (activeStep === 0) {
			// Active Step from 0 to 1 --> fetch shift list
			getShiftsByProductionDate().then((res) => {
				if (!res.ok) {
					setActiveStep(0);
					setError((prev) => {
						if (prev) {
							return `${prev}. ${res.message}`;
						} else {
							return res.message;
						}
					});
				}
			});
		}
		if (activeStep === 1) {
			// Active Step from 1 to 2 --> fetch form-asset list
			// form-asset list is already fetched when the page is first loaded using the user
		}
		if (activeStep === 2) {
			// Active Step from 2 to 3 --> fetch new instance
			createNewInstance().then((res: any) => {
				if (res) {
					onHide(true);
				} else {
					setActiveStep(0);
					setError(res.message);
				}
			});
		}
		setActiveStep(activeStep + 1);
		setShowDialogMessage(false);
	};

	const handleBack = () => {
		setActiveStep(activeStep - 1);

		setShowDialogMessage(false);
	};

	const handleResetActiveStep = () => {
		setActiveStep(0);
		setShowDialogMessage(false);
		setApiMessage(null);
	};

	const handleCancel = () => {
		onHide(false);
	};

	const handleClose = (event?: SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
		setApiMessage(null);
	};

	const loadingOverlayComponent = useMemo<any>(() => {
		return CustomLoadingOverlay;
	}, []);

	useEffect(() => {
		if (show === false) {
			setTimeout(() => {
				handleResetActiveStep();
			}, 200);
		}
		reset();
	}, [show]);

	useEffect(() => {
		if (newInstanceId !== null && activeStep === 3) {
			setTriggerEditIntance(true);
		}
		if (apiMessage !== null && activeStep === 3) {
			setOpen(true);
			handleBack();
			setShowDialogMessage(true);
		}
	}, [newInstanceId, apiMessage]);

	useEffect(() => {
		setNewInstanceId(null);
		setFormInstanceSections([]);
		setFormInstanceQuestions([]);
		setFormInstanceModifiedRows([]);
	}, []);

	return (
		<>
			<Grid container>
				<Grid item>
					<Dialog
						fullWidth={fullWidth}
						maxWidth={maxWidth}
						open={show}
						onClose={() => handleCancel()}
					>
						<form style={{ paddingTop: "15px" }}>
							{" "}
							{/* onSubmit={handleSubmit(onSubmit)} */}
							<Grid item md={12} xs={12}>
								{/* <DialogTitle id="max-width-dialog-title" style={{ padding: "0 24px 10px" }}>CREATE NEW FORM</DialogTitle> */}
								<DialogTitle
									id="max-width-dialog-title"
									style={{ padding: "0 24px 10px" }}
								>
									<Grid item md={12} xs={12}>
										<Typography
											variant="h5"
											style={{
												margin: "10px 0",
												fontWeight: 600,
												userSelect: "none",
												marginLeft: 5,
											}}
										>
											NEW FORM INSTANCE
										</Typography>
									</Grid>
								</DialogTitle>
							</Grid>
							<DialogContent style={{ padding: "10px 24px" }} dividers>
								{activeStep !== steps.length - 1 ? (
									<Stepper
										activeStep={activeStep}
										alternativeLabel
										className={classes.stepper}
									>
										{steps.map((label) => (
											<Step key={label}>
												<StepLabel
													StepIconProps={{
														classes: {
															root: classes.stepIcon,
															completed: classes.completedStepIcon,
														},
													}}
												>
													{label}
												</StepLabel>
											</Step>
										))}
									</Stepper>
								) : (
									<></>
								)}
								{activeStep === 0 && <ProductionDateStep />}
								{activeStep === 1 && <ShiftStep />}
								{activeStep === 2 && <FormAndAssetStep />}
								{activeStep === 3 && (
									<div style={{ display: "flex", justifyContent: "center" }}>
										{loadingOverlayComponent()}
									</div>
								)}
							</DialogContent>
							<DialogActions style={{ margin: "15px 0", padding: "0 10px" }}>
								<Grid
									item
									md={3}
									xs={12}
									style={{ marginRight: "auto", padding: "8px 10px" }}
								>
									<Button
										fullWidth
										variant="contained"
										color="default"
										onClick={handleCancel}
									>
										Close
									</Button>
								</Grid>
								<Grid
									item
									md={3}
									xs={12}
									style={{ margin: 0, padding: "8px 10px" }}
								>
									<Button
										disabled={activeStep === 0 || activeStep === 3}
										variant="outlined"
										color="secondary"
										onClick={handleBack}
										fullWidth
									>
										Back
									</Button>
								</Grid>
								<Grid
									item
									md={3}
									xs={12}
									style={{ margin: 0, padding: "8px 10px" }}
								>
									<Button
										disabled={activeStep === 3}
										variant="contained"
										color="primary"
										onClick={handleNext}
										fullWidth
									>
										Next
									</Button>
								</Grid>
							</DialogActions>
							{showDialogMessage && (
								<DialogContent dividers>
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											color: "#F50057",
										}}
									>
										Error: This form already exists. Please change the
										Production Date, Shift or Asset and try again.
									</div>
								</DialogContent>
							)}
						</form>
					</Dialog>
				</Grid>
			</Grid>
			<Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
				<Alert severity="error" onClose={handleClose}>
					{apiMessage}
				</Alert>
			</Snackbar>

			<ErrorModal error={error} onHide={() => setError("")} />
		</>
	);
};
