import { ModalWorkOrder } from "./ModalWorkOrder";

export const INITIAL_VALUES_MODAL: ModalWorkOrder = {
	Comments: null,
	TemplateTitle: null,
	WorkOrderPlannedHours: null,
	WorkOrderNumberOfWorkers: null,
	WorkOrderOpenedBy: null,
	WorkOrderOpenTimestamp: null,
	WorkOrderName: null,
	Title: null,
	AssetName: null,
	WorkOrderId: null,
	WorkOrderStatusCode: null,
	AssetId: null,
	DownHours: null,
	CraftId: null,
	CloseTimestamp: null,
	ConsumedHours: null,
	AttachmentsLinked: null,
	TypeCode: null,
};
