import { useEffect, useMemo, useRef, useState } from "react";
import { useFormsContext } from "../context/formsContext";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { useSearchParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .MuiSelect-root": {
			backgroundColor: "#FFF",
		},
		"& .MuiGrid-root": {
			paddingRight: 120,
			overflow: "hidden",
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
		},
	},
	inputLabel: {
		position: "absolute",
		top: -6,
		left: 8,
		fontSize: 11.3,
		backgroundColor: "#FFF",
		color: "#0000008A",
		width: "auto",
		height: "auto",
		zIndex: 100,
		padding: "0 3px",
		borderRadius: 3,
		fontWeight: 400,
		lineHeight: "1.1876em",
		letterSpacing: "0.04208em",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
	},
}));

export const FormAssetFilter = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const {
		state: { formsForUser, selectedFormAsset },
		actions: { setAssetIdForms, setFormIdForms, setSelectedFormAsset },
	} = useFormsContext();
	const isMounted = useRef(true);
	const classes = useStyles();

	useEffect(() => {
		return () => {
			// When the component unmounts, update the ref to false
			isMounted.current = false;
		};
	}, []);

	const handleFilter = (event: any) => {
		const selected = event.target.value as string;
		setSelectedFormAsset(selected);

		const [FormId, AssetId] = selected.split("-");
		if (selected === "all") {
			setFormIdForms(null);
			setAssetIdForms(null);
		} else {
			setFormIdForms(FormId);
			setAssetIdForms(AssetId);
		}

		searchParams.set("formAsset", event.target.value);
		setSearchParams(searchParams, { replace: true });
	};

	useEffect(() => {
		if (formsForUser.length && isMounted.current) {
			const defaultFormAsset = formsForUser.find(
				(element) => element.isDefault,
			);
			if (defaultFormAsset) {
				setAssetIdForms(defaultFormAsset.AssetId);
				setFormIdForms(defaultFormAsset.FormId);
				const def = `${defaultFormAsset.FormId}-${defaultFormAsset.AssetId}`;
				setSelectedFormAsset(def);
			} else {
				// If there is no default, set to 'all' or whatever the default should be
				setAssetIdForms(null);
				setFormIdForms(null);
				setSelectedFormAsset("all");
			}
		}
	}, [formsForUser]);

	return (
		<div className={classes.root} style={{ position: "relative" }}>
			<p className={classes.inputLabel}>Form - Asset</p>
			<Select
				variant="outlined"
				margin="dense"
				labelId="demo-simple-select-label"
				id="demo-simple-select"
				value={selectedFormAsset || "all"}
				onChange={handleFilter}
				style={{ minWidth: "100%", maxWidth: 250 }}
			>
				<MenuItem value="all">All</MenuItem>
				{formsForUser.map((form) => (
					<MenuItem
						key={`logId-${form.FormId}-${form.AssetId}`}
						value={`${form.FormId}-${form.AssetId}`}
					>
						<Grid container alignItems="center">
							{`${form.FormName} - ${form.AssetName}`}
						</Grid>
					</MenuItem>
				))}
			</Select>
		</div>
	);
};
