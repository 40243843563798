import { ErrorModal } from "@dexteel/mesf-core";
import {
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	DialogTitle,
	FormControlLabel,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { SyntheticEvent, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useFormsContext } from "src/setup/configurations/forms/context/FormsContext";
import { UserFormAssetData } from "src/setup/configurations/forms/models/userFormAssetData";
import { INITIAL_VALUES_USER_FORM_ASSET } from "src/setup/configurations/forms/models/UserFormAssetInitialValue";
import { upsertUserFormAsset } from "src/setup/configurations/forms/repositories/FormsRepository";
import { UsersPicker } from "../picker/usersPicker";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .input-group-text": {
			display: "none",
		},
		"& .MuiDialogContent-dividers": {
			padding: 0,
		},
	},
	assetPicker: {
		"& .MuiGrid-item": {
			width: "500px",
		},
	},

	checkbox: {
		userSelect: "none",
	},
	modalTitle: {
		backgroundColor: "#ced2cc",
		color: "#495057",
		textAlign: "center",
	},
	modalWarning: {
		color: "white",
		backgroundColor: "#dc3545",
		textAlign: "center",
	},
	messageTitle: {
		fontSize: 15,
		fontWeight: "bold",
		color: "#0000008A",
	},
	errorLabel: {
		fontSize: 12,
		color: "#F44336",
	},
	inputEditable: {
		"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			border: "2px solid #3F51B5",
		},
	},
	Subtitle: {
		"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			border: "1px solid #3F51B5",
		},
	},
}));

type Props = {
	show: boolean;
	onHide: (shouldUpdate: boolean) => void;
	setShouldUpdateWhenExit: (shouldUpdate: boolean) => void;
	data: UserFormAssetData | null;
	newMode?: boolean;
	FormAssetIdToSearchUserFormAssets: number;
};

export const NewAndEditUserFormAssetModal = ({
	show,
	onHide,
	data,
	newMode = false,
	setShouldUpdateWhenExit,
	FormAssetIdToSearchUserFormAssets,
}: Props) => {
	const classes = useStyles();
	const {
		state: {},
		actions: {
			setUserIdFormAssetDataSelectedInTable,
			setIsDefaultUserFormAssetDataSelectedInTable,
			setFormAssetId,
		},
	} = useFormsContext();

	const [editedSuccess, setEditedSuccess] = useState(false);
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);

	const [error, setError] = useState<string>("");
	const [deleteByIdError, setDeleteByIdError] = useState<string>("");
	const { control, handleSubmit, watch, setValue, reset } =
		useForm<UserFormAssetData>({
			defaultValues: INITIAL_VALUES_USER_FORM_ASSET,
		});

	//states
	const [fullWidth, setFullWidth] = useState(true);
	const [maxWidth, setMaxWidth] = useState<DialogProps["maxWidth"]>("sm");

	const handleClose = (event?: SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setEditedSuccess(false);
	};
	const handleCancel = async () => {
		reset();
		onHide(false);
	};

	const userId = watch("UserId");
	const changeUserId = () => {
		setUserIdFormAssetDataSelectedInTable(userId);
	};

	const isDefault = watch("IsDefault");
	const changeIsDefault = () => {
		setIsDefaultUserFormAssetDataSelectedInTable(isDefault);
	};

	const onSubmit = async (UserFormAssetData: UserFormAssetData) => {
		setIsSubmitLoading(true);
		const res = await upsertUserFormAsset(
			UserFormAssetData.UserId,
			FormAssetIdToSearchUserFormAssets,
			UserFormAssetData.IsDefault,
		);
		if (res.ok) {
			reset();
			setFormAssetId(null);
			setIsSubmitLoading(false);
			setEditedSuccess(true);
			setShouldUpdateWhenExit(true);
			onHide(true);
		} else {
			setIsSubmitLoading(false);
			setError(res.message);
		}
	};

	useEffect(() => {
		if (data != null && show) {
			reset(INITIAL_VALUES_USER_FORM_ASSET);
			setValue("UserId", data.UserId);
			setValue("IsDefault", data.IsDefault);
			setIsSubmitLoading(false);
		} else {
			reset(INITIAL_VALUES_USER_FORM_ASSET);
		}
	}, [show]);

	useEffect(() => {
		changeUserId();
	}, [userId]);

	useEffect(() => {
		changeIsDefault();
	}, [isDefault]);

	return (
		<>
			<Dialog
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				open={show}
				onClose={() => handleCancel()}
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container style={{ padding: "15px 0 20px" }}>
						<DialogTitle
							style={{
								padding: "5px 40px 10px 24px",
								marginRight: 20,
								width: "100%",
							}}
						>
							<Grid item md={12} xs={12}>
								<h1 style={{ fontSize: "1.7em" }}>
									{newMode
										? `USER / FORM / ASSET: ${data?.UserName}`
										: `EDIT: ${data?.UserName}`}
								</h1>
							</Grid>
						</DialogTitle>
						<Grid container>
							<DialogContent dividers>
								<Grid container>
									<Grid item md={6} xs={12} style={{ paddingLeft: 0 }}>
										<Controller
											name="UserId"
											control={control}
											rules={{ required: "User is required" }}
											render={({ field: { onChange, value } }) => (
												<UsersPicker
													value={value as number}
													onChange={onChange}
													disabled={!newMode}
												/>
											)}
										/>
									</Grid>
									<Grid
										item
										xs={12}
										md={6}
										style={{ marginTop: 8, paddingLeft: "20px" }}
									>
										<Controller
											name="IsDefault"
											control={control}
											render={({ field }) => (
												<FormControlLabel
													className={classes.checkbox}
													control={
														<Checkbox
															checked={field.value as boolean}
															onChange={field.onChange}
															name="IsDefault"
															color="primary"
														/>
													}
													label="Default for User"
												/>
											)}
										/>
									</Grid>
								</Grid>
							</DialogContent>
							<Grid
								item
								md={12}
								xs={12}
								justifyContent="flex-end"
								style={{ marginTop: "15px" }}
							>
								<DialogActions style={{ padding: "0px 24px" }}>
									<Grid
										container
										justifyContent="flex-end"
										spacing={2}
										style={{ margin: 0 }}
									>
										<Grid item md={3} xs={4}>
											<Button
												fullWidth
												variant="contained"
												color="default"
												onClick={handleCancel}
											>
												Cancel
											</Button>
										</Grid>
										<Grid item md={3} xs={4} style={{ paddingRight: 0 }}>
											<Button
												fullWidth
												startIcon={
													isSubmitLoading && <CircularProgress size="1rem" />
												}
												disabled={isSubmitLoading}
												variant="contained"
												color="primary"
												type="submit"
											>
												Save
											</Button>
										</Grid>
									</Grid>
								</DialogActions>
							</Grid>
						</Grid>
					</Grid>
				</form>
			</Dialog>
			<Snackbar
				open={editedSuccess}
				autoHideDuration={2500}
				onClose={handleClose}
			>
				<Alert severity="info" onClose={handleClose}>
					Success
				</Alert>
			</Snackbar>
			<ErrorModal error={error} onHide={() => setError("")} />
			<ErrorModal
				error={deleteByIdError}
				onHide={() => setDeleteByIdError("")}
			/>
		</>
	);
};
