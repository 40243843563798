import { createSlice } from "@reduxjs/toolkit";
import { User } from "@dexteel/mesf-core";
import { Form } from "../models/Form";
import { INITIAL_VALUES_MODAL } from "../models/ModalInitialValue";
import { UserFormAssetData } from "../models/userFormAssetData";
import { INITIAL_VALUES_FORM } from "../models/FormInitialValues";
import { Section } from "../models/Section";
import { INITIAL_VALUES_SECTION } from "../models/SectionInitialValues";
import { Question } from "../models/Question";
import { Option } from "../models/Option";
import { INITIAL_VALUES_QUESTION } from "../models/QuestionInitialValue";
import { INITIAL_VALUES_OPTION } from "../models/OptionInitialValue";
import { FormAsset } from "../models/FormAsset";
import { INITIAL_VALUES_USER_FORM_ASSET } from "../models/UserFormAssetInitialValue";
import { INITIAL_VALUES_FORM_ASSET } from "../models/FormAssetInitialValue";

const FormsInitialState: {
	formsList: Form[];
	formAssetsList: FormAsset[];
	formSectionsList: Section[];
	usersFormAssetList: UserFormAssetData[];
	questionList: Question[];
	questionOptionsList: Option[];
	searchText: string;
	firstTime: boolean;
	assetCodes: any;
	userFormAssetData: UserFormAssetData;
	userFormAssetDataSelectedInTable: UserFormAssetData;
	users: User[];
	FormId: number | null;
	FormName: string | null;
	formSelectedInTable: Form;
	SectionId: number | null;
	SectionName: string | null;
	QuestionId: number | null;
	QuestionText: string | null;
	OptionId: number | null;
	OptionText: string | null;
	sectionSelectedInTable: Section;
	searchSectionsText: string | null;
	questionSelectedInTable: Question;
	optionSelectedInTable: Option;
	formAssetIdToSearch: number | null;
	dataForPreview: any[];
	formInstanceRows: any[];
	formInstanceSections: any[];
	formInstanceQuestions: any[];

	FormAsset: FormAsset;
} = {
	formsList: [],
	searchText: "",
	firstTime: true,
	formAssetsList: [],
	formSectionsList: [],
	questionList: [],
	questionOptionsList: [],
	assetCodes: {},
	userFormAssetData: INITIAL_VALUES_MODAL,
	users: [],
	FormId: null,
	FormName: "",
	searchSectionsText: "",
	SectionId: null,
	SectionName: "",
	QuestionId: null,
	QuestionText: "",
	OptionId: null,
	OptionText: "",
	formSelectedInTable: INITIAL_VALUES_FORM,
	sectionSelectedInTable: INITIAL_VALUES_SECTION,
	questionSelectedInTable: INITIAL_VALUES_QUESTION,
	optionSelectedInTable: INITIAL_VALUES_OPTION,
	userFormAssetDataSelectedInTable: INITIAL_VALUES_USER_FORM_ASSET,
	FormAsset: INITIAL_VALUES_FORM_ASSET,
	formAssetIdToSearch: null,
	usersFormAssetList: [],
	dataForPreview: [],
	formInstanceRows: [],
	formInstanceSections: [],
	formInstanceQuestions: [],
};

export const FormsReducer = createSlice({
	name: "__",
	initialState: FormsInitialState,
	reducers: {
		setFormsList(state, { payload }) {
			state.formsList = payload;
		},
		setSearchText(state, { payload }) {
			state.searchText = payload;
		},
		setFirstTime(state, { payload }) {
			state.firstTime = payload;
		},
		setFormAssetsList(state, { payload }) {
			state.formAssetsList = payload;
		},
		setAssetCodes(state, { payload }) {
			state.assetCodes = payload;
		},
		setUsers(state, { payload }) {
			state.users = payload;
		},
		setFormName(state, { payload }) {
			state.FormName = payload;
		},
		setUserFormAssetData(state, { payload }) {
			state.userFormAssetData = payload;
		},
		setAssetId(state, { payload }) {
			state.userFormAssetData.AssetId = payload;
		},
		setAssetName(state, { payload }) {
			state.userFormAssetData.AssetName = payload;
		},
		setUserId(state, { payload }) {
			state.userFormAssetData.UserId = payload;
		},
		setIsDefault(state, { payload }) {
			state.userFormAssetData.IsDefault = payload;
		},
		setFormAssetId(state, { payload }) {
			state.userFormAssetData.FormAssetId = payload;
		},
		setFormSelectedInTable(state, { payload }) {
			state.formSelectedInTable = payload;
		},
		setFormIdFormSelectedInTable(state, { payload }) {
			state.formSelectedInTable.FormId = payload;
		},
		setFormNameFormSelectedInTable(state, { payload }) {
			state.formSelectedInTable.FormName = payload;
		},
		setIsEnabledFormSelectedInTable(state, { payload }) {
			state.formSelectedInTable.IsEnabled = payload;
		},
		setLayoutCodeFormSelectedInTable(state, { payload }) {
			state.formSelectedInTable.LayoutCode = payload;
		},
		setIncludesCommentFormSelectedInTable(state, { payload }) {
			state.formSelectedInTable.IncludesComment = payload;
		},
		setSearchSectionsText(state, { payload }) {
			state.searchSectionsText = payload;
		},
		setFormSectionsList(state, { payload }) {
			state.formSectionsList = payload;
		},
		setSectionId(state, { payload }) {
			state.SectionId = payload;
		},
		setSectionName(state, { payload }) {
			state.SectionName = payload;
		},
		setSectionSelectedInTable(state, { payload }) {
			state.sectionSelectedInTable = payload;
		},
		setSectionNameSelectedInTable(state, { payload }) {
			state.sectionSelectedInTable.SectionName = payload;
		},
		setSectionBackgroundColorSelectedInTable(state, { payload }) {
			state.sectionSelectedInTable.BackgroundColor = payload;
		},
		setSectionSubtitleSelectedInTable(state, { payload }) {
			state.sectionSelectedInTable.Subtitle = payload;
		},
		setSectionLayoutParametersSelectedInTable(state, { payload }) {
			state.sectionSelectedInTable.LayoutParameters = payload;
		},
		setFormAssetIdToSearch(state, { payload }) {
			state.formAssetIdToSearch = payload;
		},
		setUsersFormAssetList(state, { payload }) {
			state.usersFormAssetList = payload;
		},
		setUserFormAssetDataSelectedInTable(state, { payload }) {
			state.userFormAssetDataSelectedInTable = payload;
		},
		setUserIdFormAssetDataSelectedInTable(state, { payload }) {
			state.userFormAssetDataSelectedInTable.UserId = payload;
		},
		setUserNameFormAssetDataSelectedInTable(state, { payload }) {
			state.userFormAssetDataSelectedInTable.UserName = payload;
		},
		setIsDefaultUserFormAssetDataSelectedInTable(state, { payload }) {
			state.userFormAssetDataSelectedInTable.IsDefault = payload;
		},

		// QUESTIONS

		setQuestionId(state, { payload }) {
			state.QuestionId = payload;
		},
		setQuestionText(state, { payload }) {
			state.QuestionText = payload;
		},
		setQuestionList(state, { payload }) {
			state.questionList = payload;
		},
		setQuestionSelectedInTable(state, { payload }) {
			state.questionSelectedInTable = payload;
		},
		setQuestionTypeCodeInTable(state, { payload }) {
			state.questionSelectedInTable.QuestionTypeCode = payload;
		},
		setQuestionTextInTable(state, { payload }) {
			state.questionSelectedInTable.QuestionText = payload;
		},
		setQuestionIncludesCommentInTable(state, { payload }) {
			state.questionSelectedInTable.IncludesComment = payload;
		},
		setQuestionIsRequiredInTable(state, { payload }) {
			state.questionSelectedInTable.IsRequired = payload;
		},
		// OPTIONS
		setOptionId(state, { payload }) {
			state.OptionId = payload;
		},
		setOptionText(state, { payload }) {
			state.OptionText = payload;
		},
		setQuestionOptionsList(state, { payload }) {
			state.questionOptionsList = payload;
		},
		setOptionSelectedInTable(state, { payload }) {
			state.optionSelectedInTable = payload;
		},
		setOptionTextInTable(state, { payload }) {
			state.optionSelectedInTable.OptionText = payload;
		},
		setOptionIsEnabledInTable(state, { payload }) {
			state.optionSelectedInTable.IsEnabled = payload;
		},
		setOptionOptionTypeCodeInTable(state, { payload }) {
			state.optionSelectedInTable.OptionTypeCode = payload;
		},

		setFormDataForPreview(state, { payload }) {
			state.dataForPreview = payload;
		},
		setFormInstanceRows(state, { payload }) {
			state.formInstanceRows = payload;
		},
		setFormInstanceSections(state, { payload }) {
			state.formInstanceSections = payload;
		},
		setFormInstanceQuestions(state, { payload }) {
			state.formInstanceQuestions = payload;
		},
		setFormId(state, { payload }) {
			state.FormId = payload;
		},
		// FORM ASSET
		setFormAsset(state, { payload }) {
			state.FormAsset = payload;
		},
		setFormAssetFormAssetId(state, { payload }) {
			state.FormAsset.FormAssetId = payload;
		},
		setFormAssetFormId(state, { payload }) {
			state.FormAsset.FormId = payload;
		},
		setFormAssetAssetId(state, { payload }) {
			state.FormAsset.AssetId = payload;
		},
		setFormAssetAssetName(state, { payload }) {
			state.FormAsset.AssetName = payload;
		},
		setFormAssetSchedulePolicyCode(state, { payload }) {
			state.FormAsset.SchedulePolicyCode = payload;
		},
		setFormAssetPeriods(state, { payload }) {
			state.FormAsset.Periods = payload;
		},
		setFormAssetStartDate(state, { payload }) {
			state.FormAsset.StartDate = payload;
		},
	},
});
