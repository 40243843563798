import { createSlice } from "@reduxjs/toolkit";
import { INITIAL_VALUES_MODAL } from "../models/ModalInitialValue";
import { ModalWorkOrder } from "../models/ModalWorkOrder";
import { Drawing } from "../components/work-order-resources/models/WorkOrderDrawing";
import { WorkOrderLOTO } from "../components/work-order-resources/models/WorkOrderLOTO";
import { WorkOrderPart } from "../components/work-order-resources/models/WorkOrderPart";
import { METADATA_INITIAL_VALUES } from "@/pages/drawings/search-documents/models/metaDataInitialValue";
import { DocumentParameters } from "@/pages/drawings/search-documents/models/DocumentParameters";
import { Attachments } from "@/pages/asset-details/models/Attachment";
import { LOTOResource } from "@/pages/asset-details/models/LOTOResource";
import { Parts } from "@/pages/drawings/search-documents/models/Parts";
import { WorkOrderAttachment } from "../components/work-order-resources/models/WorkOrderAttachment";
import { Part } from "../components/work-order-resources/models/Part";

const initialState: {
	modalWorkOrderSelected: ModalWorkOrder;

	assetCodes: any;

	searchAssetName: string;

	// --------------------- WORK ORDER RESOURCES ---------------------------

	firstTime: boolean;

	listAllDrawings: Drawing[];
	workOrderDrawingToAdd: any;
	workOrderLOTOToAdd: any;
	workOrderAttachmentToAdd: any;

	assetDrawings: Drawing[];
	assetAttachments: Attachments[] | WorkOrderAttachment[];
	assetLOTO: LOTOResource[] | WorkOrderLOTO[];
	assetParts: Parts[];
	drawingSearch: string;
	showListOfDrawings: boolean;
	loaderListSearchDrawings: boolean;

	dataDrawingSelected: Drawing | null;
	dataLOTOSelected: WorkOrderLOTO | null;
	dataAttachmentSelected: WorkOrderAttachment | null;
	dataPartSelected: WorkOrderPart | null;

	drawingsDocumentMetadata: DocumentParameters;

	rowSelectedInDrawing: any;
	rowSelectedInLOTO: any;
	rowSelectedInAttachment: any;
	rowSelectedInPart: any;

	LOTONumberToLink: string;
	LOTONameToLink: string;

	displayViewerAttachment: boolean;

	listAttachmentSelected: any[] | null;
	listDrawingsSelected: any[] | null;
	listPartsSelected: any[] | null;
	listLOTOSelected: any[] | null;

	uploadFileDialog: boolean;

	workOrderDrawingsList: Drawing[];
	workOrderAttachmentsList: WorkOrderAttachment[];
	workOrderLOTOList: WorkOrderLOTO[];

	workOrderPartsList: WorkOrderPart[];

	listAllParts: WorkOrderPart[];
	workOrderPartsToAdd: any;
	partSearch: string;
	showListOfParts: boolean;
	loaderListSearchParts: boolean;
	isCreatingLOTO: boolean;

	// ----------------------------------------------------------------------
} = {
	modalWorkOrderSelected: INITIAL_VALUES_MODAL,

	assetCodes: {},

	searchAssetName: "",

	// --------------------- WORK ORDER RESOURCES ---------------------------
	firstTime: true,

	assetDrawings: [],
	assetAttachments: [],
	assetLOTO: [],
	assetParts: [],

	// --------------------- WORK ORDER DRAWINGS ----------------------------
	rowSelectedInDrawing: null,
	listAllDrawings: [],
	workOrderDrawingsList: [],
	dataDrawingSelected: null,
	workOrderDrawingToAdd: [],
	drawingsDocumentMetadata: METADATA_INITIAL_VALUES,
	drawingSearch: "",
	showListOfDrawings: false,
	loaderListSearchDrawings: false,

	// --------------------- WORK ORDER LOTO --------------------------------

	workOrderLOTOToAdd: {},
	rowSelectedInLOTO: [],
	workOrderLOTOList: [],
	dataLOTOSelected: null,

	LOTONumberToLink: "",
	LOTONameToLink: "",

	isCreatingLOTO: false,

	// --------------------- WORK ORDER ATTACHMENTS -------------------------

	workOrderAttachmentToAdd: {},
	rowSelectedInAttachment: [],
	workOrderAttachmentsList: [],

	dataAttachmentSelected: null,
	displayViewerAttachment: false,

	listAttachmentSelected: [],
	listDrawingsSelected: [],
	listPartsSelected: [],
	listLOTOSelected: [],

	uploadFileDialog: false,

	// --------------------- WORK ORDER PARTS -------------------------------

	rowSelectedInPart: [],
	listAllParts: [],
	workOrderPartsList: [],
	dataPartSelected: null,
	workOrderPartsToAdd: [],
	partSearch: "",
	showListOfParts: false,
	loaderListSearchParts: false,
	// ----------------------------------------------------------------------
};

export const WorkOrderModalsReducer = createSlice({
	name: "__",
	initialState,
	reducers: {
		// ================= Modal WorkOrder =====================

		setAssetCodes(state, { payload }) {
			state.assetCodes = payload;
		},

		setSearchAssetName(state, { payload }) {
			state.searchAssetName = payload;
		},

		setmodalWorkOrderSelected(state, { payload }) {
			state.modalWorkOrderSelected.Comments = payload;
		},
		setTypeCode(state, { payload }) {
			state.modalWorkOrderSelected.TypeCode = payload;
		},
		setComments(state, { payload }) {
			state.modalWorkOrderSelected.Comments = payload;
		},
		setTemplateTitle(state, { payload }) {
			state.modalWorkOrderSelected.TemplateTitle = payload;
		},
		setWorkOrderPlannedHours(state, { payload }) {
			state.modalWorkOrderSelected.WorkOrderPlannedHours = payload;
		},
		setWorkOrderNumberOfWorkers(state, { payload }) {
			state.modalWorkOrderSelected.WorkOrderNumberOfWorkers = payload;
		},
		setWorkOrderOpenedBy(state, { payload }) {
			state.modalWorkOrderSelected.WorkOrderOpenedBy = payload;
		},
		setWorkOrderOpenTimestamp(state, { payload }) {
			state.modalWorkOrderSelected.WorkOrderOpenTimestamp = payload;
		},
		setWorkOrderName(state, { payload }) {
			state.modalWorkOrderSelected.WorkOrderName = payload;
		},
		setTitle(state, { payload }) {
			state.modalWorkOrderSelected.Title = payload;
		},
		setAssetName(state, { payload }) {
			state.modalWorkOrderSelected.AssetName = payload;
		},
		setWorkOrderId(state, { payload }) {
			state.modalWorkOrderSelected.WorkOrderId = payload;
		},
		setWorkOrderConsumedHours(state, { payload }) {
			state.modalWorkOrderSelected.ConsumedHours = payload;
		},
		setWorkOrderCloseTimestamp(state, { payload }) {
			state.modalWorkOrderSelected.CloseTimestamp = payload;
		},

		setAssetId(state, { payload }) {
			state.modalWorkOrderSelected.AssetId = payload;
		},
		setDownHours(state, { payload }) {
			state.modalWorkOrderSelected.DownHours = payload;
		},
		setCraftId(state, { payload }) {
			state.modalWorkOrderSelected.CraftId = payload;
		},
		setWorkOrderStatusCode(state, { payload }) {
			state.modalWorkOrderSelected.WorkOrderStatusCode = payload;
		},

		// --------------------- WORK ORDER RESOURCES ---------------------------
		setFirstTime(state, { payload }) {
			state.firstTime = payload;
		},
		setAttachmentsLinked(state, { payload }) {
			state.modalWorkOrderSelected.AttachmentsLinked = payload;
		},
		setAssetDrawings(state, { payload }) {
			state.assetDrawings = payload;
		},
		setAssetLOTOList(state, { payload }) {
			state.assetLOTO = payload;
		},
		setAssetAttachmentsList(state, { payload }) {
			state.assetAttachments = payload;
		},
		setAssetPartsList(state, { payload }) {
			state.assetParts = payload;
		},

		// Drawings
		setListAllDrawings(state, { payload }) {
			state.listAllDrawings = payload;
		},
		setWorkOrderDrawingsList(state, { payload }) {
			state.workOrderDrawingsList = payload;
		},
		setWorkOrderDrawingToAdd(state, { payload }) {
			state.workOrderDrawingToAdd = payload;
		},

		setShowListOfDrawings(state, { payload }) {
			state.showListOfDrawings = payload;
		},
		setLoaderListSearchDrawings(state, { payload }) {
			state.loaderListSearchDrawings = payload;
		},
		setDrawingSearch(state, { payload }) {
			state.drawingSearch = payload;
		},
		setDataDrawingSelected(state, { payload }) {
			state.dataDrawingSelected = payload;
		},

		setListDrawingsSelected(state, { payload }) {
			state.listDrawingsSelected = payload;
		},
		setDocumentMetadata(state, { payload }) {
			state.drawingsDocumentMetadata = payload;
		},
		setVersionDocumentMetadata(state, { payload }) {
			state.drawingsDocumentMetadata.Version = payload;
		},
		setExtensionDocumentMetadata(state, { payload }) {
			state.drawingsDocumentMetadata.Extension = payload;
		},
		setUploadTimestampDocumentMetadata(state, { payload }) {
			state.drawingsDocumentMetadata.UploadTimestamp = payload;
		},
		setUserNameDocumentMetadata(state, { payload }) {
			state.drawingsDocumentMetadata.UserName = payload;
		},
		setRowSelectedInDrawing(state, { payload }) {
			state.rowSelectedInDrawing = payload;
		},
		setDocumentIdRowSelected(state, { payload }) {
			state.rowSelectedInDrawing.DocumentId = payload;
		},
		// ----------------------------------------------------------------------
		// ATTACHMENTS

		setWorkOrderAttachmentsList(state, { payload }) {
			state.workOrderAttachmentsList = payload;
		},
		setWorkOrderAttachmentToAdd(state, { payload }) {
			state.workOrderAttachmentToAdd = payload;
		},
		setRowSelectedInAttachment(state, { payload }) {
			state.rowSelectedInAttachment = payload;
		},
		setDataAttachmentSelected(state, { payload }) {
			state.dataAttachmentSelected = payload;
		},
		setDisplayViewerAttachment(state, { payload }) {
			state.displayViewerAttachment = payload;
		},
		setListAttachmentSelected(state, { payload }) {
			state.listAttachmentSelected = payload;
		},
		setUploadFileDialog(state, { payload }) {
			state.uploadFileDialog = payload;
		},

		// ----------------------------------------------------------------------
		// LOTO
		setWorkOrderLOTOList(state, { payload }) {
			state.workOrderLOTOList = payload;
		},
		setDataLOTOSelected(state, { payload }) {
			state.dataLOTOSelected = payload;
		},
		setRowSelectedInLOTO(state, { payload }) {
			state.rowSelectedInLOTO = payload;
		},
		setListLOTOSelected(state, { payload }) {
			state.listLOTOSelected = payload;
		},
		setLOTONumberToLink(state, { payload }) {
			state.LOTONumberToLink = payload;
		},
		setLOTONameToLink(state, { payload }) {
			state.LOTONameToLink = payload;
		},
		setWorkOrderLOTOToAdd(state, { payload }) {
			state.workOrderLOTOToAdd = payload;
		},
		setIsCreatingLOTO(state, { payload }) {
			state.isCreatingLOTO = payload;
		},
		// PARTS
		setListAllParts(state, { payload }) {
			state.listAllParts = payload;
		},
		setWorkOrderPartsList(state, { payload }) {
			state.workOrderPartsList = payload;
		},
		setWorkOrderPartsToAdd(state, { payload }) {
			state.workOrderPartsToAdd = payload;
		},

		setShowListOfParts(state, { payload }) {
			state.showListOfParts = payload;
		},
		setListPartsSelected(state, { payload }) {
			state.listPartsSelected = payload;
		},
		setLoaderListSearchParts(state, { payload }) {
			state.loaderListSearchParts = payload;
		},
		setPartSearch(state, { payload }) {
			state.drawingSearch = payload;
		},
		setDataPartSelected(state, { payload }) {
			state.dataPartSelected = payload;
		},
		setAssetParts(state, { payload }) {
			state.assetParts = payload;
		},
		setRowSelectedInPart(state, { payload }) {
			state.rowSelectedInPart = payload;
		},
		setPartIdRowSelected(state, { payload }) {
			state.rowSelectedInPart.DocumentId = payload;
		},
	},
});
