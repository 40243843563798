import React from "react";
import { Button, Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { EditableCallbackParams } from "ag-grid-community";
import { useFormsContext } from "../../context/FormsContext";

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			"& .ag-icon-menu": {
				display: "none",
			},
			"& .ag-checkbox-input-wrapper": {
				margin: "0 10px",
			},
			"& .ag-cell": {
				alignItems: "center",
				padding: "0 !important",
				border: "unset !important",
			},
			"& .ag-header-cell": {
				padding: "0px !important",
				textAlign: "center !important",
				fontSize: 10,
			},
			"& .ag-root-wrapper": {
				minHeight: "100% !important",
				borderRadius: 3,
				paddingBottom: 45,
			},
			"& .ag-cell-wrapper": {
				width: "95%",
				textAlign: "center",
			},
			"& .ag-body-viewport": {
				borderBottom: "2px solid #DDE2EB",
			},
			"& .ag-menu-option": {
				cursor: "pointer",
			},
		},
	}),
);

type Props = {
	params: EditableCallbackParams;
	setOpenFormAssetAndUsersTable: Function;
};

export const ButtonToShowFormsAssetAndUsers = ({
	params,
	setOpenFormAssetAndUsersTable,
}: Props) => {
	const classes = useStyles();

	const {
		actions: { setFormId, setFormName },
	} = useFormsContext();

	const handleOpenAssetAndUsersTable = () => {
		setOpenFormAssetAndUsersTable(true);
		setFormId(params.data?.FormId);
		setFormName(params.data?.FormName);
	};

	if (params.data) {
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "100%",
					padding: 5,
				}}
			>
				<Button
					style={{ height: "100%", maxHeight: "27px" }}
					fullWidth
					variant={params.data?.AssetCount !== 0 ? "contained" : "outlined"}
					color="primary"
					onClick={() => handleOpenAssetAndUsersTable()}
				>
					{params.data?.AssetCount !== 0
						? params.data?.AssetCount <= 1
							? `${params.data?.AssetCount} ASSET`
							: `${params.data?.AssetCount} ASSETS`
						: "add asset"}
				</Button>
			</div>
		);
	} else return <></>;
};
