import { get } from "react-hook-form";
import { getFormAssets } from "../repositories/FormsRepository";
import { useFormsContext } from "../context/FormsContext";
type Props = {
	setIsLoading: (isLoading: boolean) => void;
	setError: (err: string) => void;
};

export const useSearchFormAssets = ({ setIsLoading, setError }: Props) => {
	const {
		state: { FormId },
		actions: { setFormAssetsList },
	} = useFormsContext();

	return async () => {
		setFormAssetsList([]);
		setIsLoading(true);
		const res = await getFormAssets(FormId);
		if (res.ok) {
			const rowsSource = get(res, "data.tables[0].rows", []);
			if (rowsSource.length) {
				setFormAssetsList(rowsSource);
			} else {
				setFormAssetsList([]);
			}
			setIsLoading(false);
		} else {
			setError(res.message);
			setIsLoading(false);
		}
	};
};
