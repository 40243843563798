import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { AgGridReact } from "ag-grid-react";
import {
	ColDef,
	EditableCallbackParams,
	GetContextMenuItemsParams,
	GridApi,
	MenuItemDef,
} from "ag-grid-community";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormsContext } from "../../context/FormsContext";
import { LazyLoading } from "@/components/loaders/Loader/LazyLoading";
import { ErrorModal, useAssetContext } from "@dexteel/mesf-core";
import FormatListBulletedSharpIcon from "@material-ui/icons/FormatListBulletedSharp";
import { useSearchUsersFormAsset } from "../../hooks/useSearchUsersFormAsset";
import { UserFormAssetData } from "../../models/userFormAssetData";
import { DeleteUserFormAssetDialog } from "./components/delete-user-form-asset-modal/delete-user-form-asset-modal";
import { NewAndEditUserFormAssetModal } from "./components/new-user-form-asset-modal/modal-new-and-edit-user-form-asset";
import { INITIAL_VALUES_USER_FORM_ASSET } from "../../models/UserFormAssetInitialValue";
import { EditFormAsset } from "./components/edit-form-asset/edit-form-asset";
import { useSearchFormAsset } from "../../hooks/useSearchFormAsset";

type Props = {
	FormAssetIdToSearchUserFormAssets: number;
	setOpenNewEditUserFormAsset: (setter: boolean) => void;
	setOpenDeleteUserFormAsset: (setter: boolean) => void;
	setNewUserFormAssetMode: (setter: boolean) => void;
	setShouldUpdateWhenExit: (shouldUpdate: boolean) => void;
	openNewEditUserFormAsset: boolean;
	openDeleteUserFormAsset: boolean;
	newUserFormAssetMode: boolean;
};

export const UsersFormAssetTable = ({
	setShouldUpdateWhenExit,
	FormAssetIdToSearchUserFormAssets,
	setOpenNewEditUserFormAsset,
	openNewEditUserFormAsset,
	openDeleteUserFormAsset,
	setOpenDeleteUserFormAsset,
	newUserFormAssetMode,
	setNewUserFormAssetMode,
}: Props) => {
	const [gridApi, setGridApi] = useState<GridApi | null>(null);
	const gridStyle = useMemo(
		() => ({ height: "100%", width: "100%", padding: 10 }),
		[],
	);
	const [UsersFormAssetColumnDefs, setUsersFormAssetColumnDefs] = useState<
		ColDef[]
	>([]);

	const {
		state: {
			userFormAssetDataSelectedInTable,
			usersFormAssetList,
			FormAsset: { FormAssetId },
			FormAsset,
		},
		actions: {
			setUserFormAssetDataSelectedInTable,
			setUserIdFormAssetDataSelectedInTable,
			setIsDefaultUserFormAssetDataSelectedInTable,
		},
	} = useFormsContext();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>("");
	const searchUsersFormAsset = useSearchUsersFormAsset({
		setIsLoading,
		setError,
		FormAssetIdToSearchUserFormAssets,
	});
	const searchFormAsset = useSearchFormAsset({
		setIsLoading,
		setError,
		FormAssetIdToSearchUserFormAssets,
	});

	const loadingOverlayComponent = useMemo<any>(() => {
		return LazyLoading;
	}, []);

	const defaultColDef = useMemo<ColDef>(() => {
		return {
			sortable: true,
			wrapText: true,
			autoHeight: true,
			wrapHeaderText: true,
		};
	}, []);

	const onActionsClick = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		params: EditableCallbackParams,
	) => {
		(params.api as any).contextMenuFactory.showMenu(
			params.node,
			params.column,
			undefined,
			e,
		);
	};

	const getUsersFormAssetContextMenuItems = useCallback(
		(params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
			const data: UserFormAssetData = params.node?.data;
			const result: (string | MenuItemDef)[] = [];
			if (data) {
				{
					result.push({
						name: "<strong>Edit User</strong>",
						action: () => {
							setNewUserFormAssetMode(false);
							setUserFormAssetDataSelectedInTable(data);
							setUserIdFormAssetDataSelectedInTable(data.UserId);
							setIsDefaultUserFormAssetDataSelectedInTable(data.IsDefault);
							setOpenNewEditUserFormAsset(true);
						},
						icon: '<i class="fas fa-edit"></i>',
					});
				}
			}

			if (data) {
				result.push({
					name: "Add User",
					action: () => {
						setUserFormAssetDataSelectedInTable(INITIAL_VALUES_USER_FORM_ASSET);
						setNewUserFormAssetMode(true);
						setOpenNewEditUserFormAsset(true);
					},
					icon: '<i class="fas fa-plus"></i>',
				});
			}

			if (data) {
				{
					result.push({
						name: "Delete User",
						action: () => {
							setNewUserFormAssetMode(false);
							setOpenDeleteUserFormAsset(true);
							setUserFormAssetDataSelectedInTable(data);
						},
						icon: '<i class="fas fa-trash"></i>',
					});
				}
			}

			const addNewUserFormAsset: (string | MenuItemDef)[] = [];

			if (!data && FormAssetIdToSearchUserFormAssets !== null) {
				addNewUserFormAsset.push({
					name: "Add User",
					action: () => {
						setUserFormAssetDataSelectedInTable(INITIAL_VALUES_USER_FORM_ASSET);
						setNewUserFormAssetMode(true);
						setOpenNewEditUserFormAsset(true);
					},
					icon: '<i class="fas fa-plus"></i>',
				});
			}

			return data ? result : addNewUserFormAsset;
		},
		[usersFormAssetList],
	);

	const renderColumns = () => {
		setUsersFormAssetColumnDefs([]);
		const newColumns: ColDef[] = [];
		newColumns.push({
			field: "UserName",
			headerName: "User",
			sortable: false,
			flex: 4,
			minWidth: 130,
		}),
			newColumns.push({
				field: "IsDefault",
				headerName: "Default",
				sortable: false,
				flex: 1,
				minWidth: 80,
				valueFormatter: function (params) {
					return params.value ? "Yes" : "No";
				},
			});
		newColumns.push({
			headerName: "",
			flex: 1,
			minWidth: 65,
			maxWidth: 65,
			cellClass: "ag-columns",
			wrapText: false,
			autoHeight: false,
			cellRenderer: (params: EditableCallbackParams) => {
				return (
					<Grid
						container
						style={{
							height: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Grid
							item
							xs={12}
							md={12}
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Grid
								container
								spacing={1}
								style={{ justifyContent: "flex-end", minWidth: 30 }}
							>
								<Grid item xs={12} md={12}>
									<Button
										fullWidth
										style={{}}
										onClick={(e) => onActionsClick(e, params)}
									>
										<FormatListBulletedSharpIcon
											style={{ height: "auto" }}
											color="action"
										/>
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				);
			},
		});
		setUsersFormAssetColumnDefs(newColumns);
	};

	useEffect(() => {
		renderColumns();
	}, [usersFormAssetList]);

	useEffect(() => {
		if (isLoading) {
			gridApi?.showLoadingOverlay();
		} else gridApi?.hideOverlay();
	}, [isLoading, usersFormAssetList, gridApi]);

	useEffect(() => {
		if (FormAssetIdToSearchUserFormAssets) {
			searchUsersFormAsset();
			searchFormAsset();
		}
	}, [FormAssetIdToSearchUserFormAssets]);
	return (
		<>
			<Grid
				container
				item
				md={8}
				xs={6}
				style={{ paddingLeft: 20, height: "58vh" }}
			>
				{isLoading ? (
					<Grid
						item
						md={12}
						xs={12}
						style={{
							height: "58vh",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<LazyLoading />
					</Grid>
				) : (
					<>
						<Grid
							item
							md={12}
							xs={12}
							style={{
								height: "43%",
							}}
						>
							{FormAssetId && <EditFormAsset FormAsset={FormAsset} />}
						</Grid>
						{FormAssetIdToSearchUserFormAssets && (
							<Grid
								item
								md={12}
								xs={12}
								style={{
									height: "56%",
									border: "4px solid #ccc",
									borderRadius: 10,
								}}
							>
								<div style={gridStyle} className="ag-theme-alpine">
									<AgGridReact
										rowData={usersFormAssetList}
										columnDefs={UsersFormAssetColumnDefs}
										defaultColDef={defaultColDef}
										rowHeight={38}
										headerHeight={42}
										loadingOverlayComponent={loadingOverlayComponent}
										animateRows={true}
										getContextMenuItems={(e: any) =>
											getUsersFormAssetContextMenuItems(e)
										}
										pagination={true}
										onRowDoubleClicked={(event) => {
											setNewUserFormAssetMode(false);
											setUserFormAssetDataSelectedInTable(event.data);
											setOpenNewEditUserFormAsset(true);
										}}
										rowSelection="single"
										onGridReady={(params) => setGridApi(params.api)}
										overlayNoRowsTemplate={"No users"}
									/>
								</div>
							</Grid>
						)}
					</>
				)}
			</Grid>
			<NewAndEditUserFormAssetModal
				show={openNewEditUserFormAsset}
				data={userFormAssetDataSelectedInTable}
				FormAssetIdToSearchUserFormAssets={
					FormAssetIdToSearchUserFormAssets as number
				}
				newMode={newUserFormAssetMode}
				setShouldUpdateWhenExit={setShouldUpdateWhenExit}
				onHide={(shouldUpdate: boolean) => {
					if (shouldUpdate) {
						searchUsersFormAsset();
					}
					setOpenNewEditUserFormAsset(false);
				}}
			/>
			<DeleteUserFormAssetDialog
				show={openDeleteUserFormAsset}
				onHide={(shouldUpdate: boolean) => {
					if (shouldUpdate) {
						searchUsersFormAsset();
						setShouldUpdateWhenExit(true);
					}
					setOpenDeleteUserFormAsset(false);
				}}
			/>
			<ErrorModal error={error} onHide={() => setError("")} />
		</>
	);
};
