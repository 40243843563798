import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import { AgGridReact } from "ag-grid-react";
import {
	ColDef,
	EditableCallbackParams,
	GetContextMenuItemsParams,
	GridApi,
	MenuItemDef,
	RowDoubleClickedEvent,
} from "ag-grid-community";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormsContext } from "../../context/FormsContext";
import { LazyLoading } from "@/components/loaders/Loader/LazyLoading";
import { ErrorModal } from "@dexteel/mesf-core";
import FormatListBulletedSharpIcon from "@material-ui/icons/FormatListBulletedSharp";
import { INITIAL_VALUES_QUESTION } from "../../models/QuestionInitialValue";
import { useSearchQuestions } from "../../hooks/useSearchQuestions";
import { NewAndEditQuestionModal } from "./questions-options-modals/modal-new-and-edit-question";
import { Question } from "../../models/Question";
import { DeleteQuestionDialog } from "./questions-options-modals/modal-delete-question";
import { OptionsTable } from "./optionsTable";
import { useClone } from "../../hooks/useClone";
import AddBoxIcon from "@material-ui/icons/AddBox";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
	root: {
		"& h3": {
			display: "inline-block",
			marginTop: 0,
			paddingBotton: 0,
			paddingLeft: 20,
			border: "none",
			userSelect: "none",
		},
		"& .content-wrapper": {
			border: "none",
		},
		"& .ag-icon-menu": {
			display: "none",
		},
		"& .ag-root-wrapper": {
			borderRadius: 3,
		},
		"& .ag-header-cell": {
			padding: "0px !important",
			textAlign: "center !important",
			fontSize: 10,
		},
		"& .ag-header-cell-label": {
			justifyContent: "center",
		},
		"& .ag-cell": {
			padding: "0px !important",
			textAlign: "center !important",
			fontSize: 12,
			lineBreak: "auto",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			border: "0 !important",
			wordBreak: "break-word",
		},
		"& .ag-comments": {
			display: "flex",
			justifyContent: "flex-start",
			alignItems: "center",
		},
		"& .ag-selection-checkbox": {
			marginLeft: "10px !important",
		},
	},
	relative: {
		position: "relative",
	},
	modalTitle: {
		backgroundColor: "#ced2cc",
		color: "#495057",
		textAlign: "center",
	},
	btnAdd: {
		position: "absolute",
		padding: 0,
		bottom: 16,
		left: 20,
	},
	modalTitleCloseWO: {
		backgroundColor: "#28a745",
		color: "white",
		textAlign: "center",
	},
	modalWarning: {
		color: "white",
		backgroundColor: "#dc3545",
		textAlign: "center",
	},
	messageTitle: {
		fontSize: 15,
		fontWeight: "bold",
		color: "#0000008A",
	},
	responsive: {
		[theme.breakpoints.up("md")]: {
			padding: "0 10px 0 0 !important",
			marginTop: "0 !important",
		},
	},
	responsiveTwo: {
		[theme.breakpoints.up("md")]: {
			padding: "0 0 0 10px !important",
			marginTop: "0 !important",
		},
	},
	responsiveThree: {
		[theme.breakpoints.up("md")]: {
			padding: "0 !important",
		},
	},
	responsiveFour: {
		[theme.breakpoints.up("md")]: {
			padding: "0 10px 0 0 !important",
		},
	},
	responsiveFive: {
		[theme.breakpoints.up("md")]: {
			padding: "0 0 0 10px !important",
		},
	},
}));

type Props = {
	show: boolean;
	onHide: (onHide: boolean) => void;
};

export const QuestionsAndOptionsTable = ({ show, onHide }: Props) => {
	const [gridApi, setGridApi] = useState<GridApi | null>(null);
	const classes = useStyles();
	const gridStyle = useMemo(
		() => ({ height: "100%", width: "100%", padding: 10 }),
		[],
	);
	const [fullWidth, setFullWidth] = useState(true);
	const [maxWidth, setMaxWidth] = useState<DialogProps["maxWidth"]>("lg");
	const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
	const [shouldUpdateWhenExit, setShouldUpdateWhenExit] = useState(false);
	const [newMode, setNewMode] = useState<boolean>(false);
	const [questionIdToSearchOptions, setQuestionIdToSearchOptions] = useState<
		number | null
	>(null);

	const [openNewEditQuestion, setOpenNewEditQuestion] = useState(false);
	const [openDeleteQuestion, setOpenDeleteQuestion] = useState(false);
	const [openNewEditOption, setOpenNewEditOption] = useState(false);
	const [openDeleteOption, setOpenDeleteOption] = useState(false);
	const [newOptionMode, setNewOptionMode] = useState<boolean>(false);
	const [addButton, setAddButton] = useState<boolean>(true);

	const {
		state: { SectionName, questionList, questionSelectedInTable },
		actions: {
			setQuestionSelectedInTable,
			setQuestionId,
			setQuestionText,
			setQuestionOptionsList,
		},
	} = useFormsContext();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [error, setError] = useState<string>("");

	const searchQuestions = useSearchQuestions({ setIsLoading, setError });
	const { CloneQuestionAndOptions } = useClone();

	const handleClone = (questionId: number) => {
		CloneQuestionAndOptions({ QuestionId: questionId, searchQuestions });
		setShouldUpdateWhenExit(true);
	};

	const handleNewQuestion = () => {
		setQuestionSelectedInTable(INITIAL_VALUES_QUESTION);
		setQuestionId(null);
		setQuestionText("");
		setNewMode(true);
		setOpenNewEditQuestion(true);
	};

	const loadingOverlayComponent = useMemo<any>(() => {
		return LazyLoading;
	}, []);

	const defaultColDef = useMemo<ColDef>(() => {
		return {
			sortable: true,
			wrapText: true,
			autoHeight: true,
			wrapHeaderText: true,
		};
	}, []);

	const onActionsClick = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		params: EditableCallbackParams,
	) => {
		(params.api as any).contextMenuFactory.showMenu(
			params.node,
			params.column,
			undefined,
			e,
		);
	};

	const rowClicked = (event: RowDoubleClickedEvent) => {
		if (event.data) setQuestionIdToSearchOptions(event.data.QuestionId);
	};

	const handleCancel = () => {
		onHide(shouldUpdateWhenExit);
	};

	const getQuestionContextMenuItems = useCallback(
		(params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
			const data: Question = params.node?.data;
			const result: (string | MenuItemDef)[] = [];

			if (data) {
				{
					result.push({
						name: "<strong>Edit</strong>",
						action: () => {
							setNewMode(false);
							setQuestionSelectedInTable(data);
							setQuestionId(data.QuestionId);
							setQuestionText(data.QuestionText);
							setOpenNewEditQuestion(true);
						},
						icon: '<i class="fas fa-edit"></i>',
					});
				}
			}

			if (data) {
				result.push({
					name: "New Question",
					action: () => {
						setQuestionSelectedInTable(INITIAL_VALUES_QUESTION);
						setQuestionId(null);
						setQuestionText("");
						setNewMode(true);
						setOpenNewEditQuestion(true);
					},
					icon: '<i class="fas fa-plus"></i>',
				});
			}

			if (data) {
				result.push({
					name: "New Option",
					action: () => {
						setNewOptionMode(true);
						setQuestionId(null);
						setQuestionText("");
						setQuestionSelectedInTable(INITIAL_VALUES_QUESTION);
						setQuestionIdToSearchOptions(data?.QuestionId);
						setOpenNewEditOption(true);
					},
					icon: '<i class="fas fa-plus"></i>',
				});
			}

			if (data) {
				{
					result.push(
						// "separator",
						{
							name: "Duplicate Question",
							action: () => {
								setQuestionSelectedInTable(data);
								handleClone(data.QuestionId as number);
							},
							icon: '<i class="fas fa-clone"></i>',
						},
					);
				}
			}

			if (data) {
				{
					result.push(
						// "separator",
						{
							name: "Delete",
							action: () => {
								setNewMode(false);
								setOpenDeleteQuestion(true);
								setQuestionSelectedInTable(data);
							},
							icon: '<i class="fas fa-trash"></i>',
						},
					);
				}
			}

			const addNewQuestion: (string | MenuItemDef)[] = [];

			if (!data) {
				addNewQuestion.push({
					name: "New Question",
					action: () => {
						setQuestionSelectedInTable(INITIAL_VALUES_QUESTION);
						setNewMode(true);
						setQuestionId(null);
						setQuestionText("");
						setOpenNewEditQuestion(true);
					},
					icon: '<i class="fas fa-plus"></i>',
				});
			}

			return data ? result : addNewQuestion;
		},
		[questionList],
	);

	useEffect(() => {
		if (show) {
			searchQuestions();
			setShouldUpdateWhenExit(false);
			setColumnDefs([]);
			setQuestionOptionsList([]);
			setQuestionIdToSearchOptions(null);

			const newColumns: ColDef[] = [];

			newColumns.push({
				field: "QuestionText",
				headerName: "Question Name",
				sortable: true,
				flex: 4,
				minWidth: 130,
			}),
				newColumns.push({
					field: "IncludesComment",
					headerName: "Includes Comment",
					sortable: false,
					flex: 1,
					minWidth: 130,
					valueFormatter: function (params) {
						return params.value ? "Yes" : "No";
					},
				}),
				newColumns.push({
					field: "IsRequired",
					headerName: "Required",
					sortable: false,
					flex: 1,
					minWidth: 130,
					valueFormatter: function (params) {
						return params.value ? "Yes" : "No";
					},
				}),
				newColumns.push({
					headerName: "",
					flex: 1,
					minWidth: 65,
					maxWidth: 65,
					sortable: false,
					cellClass: "ag-columns",
					wrapText: false,
					autoHeight: false,
					cellRenderer: (params: EditableCallbackParams) => {
						return (
							<Grid
								container
								style={{
									height: "100%",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<Grid
									item
									xs={12}
									md={12}
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<Grid
										container
										spacing={1}
										style={{ justifyContent: "flex-end", minWidth: 30 }}
									>
										<Grid item xs={12} md={12}>
											<Button
												fullWidth
												style={{}}
												onClick={(e) => onActionsClick(e, params)}
											>
												<FormatListBulletedSharpIcon
													style={{ height: "auto" }}
													color="action"
												/>
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						);
					},
				});

			setColumnDefs(newColumns);
		}
	}, [show]);

	useEffect(() => {
		if (isLoading) {
			gridApi?.showLoadingOverlay();
		} else gridApi?.hideOverlay();
	}, [isLoading, questionList, gridApi]);

	return (
		<>
			<Dialog
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				open={show}
				onClose={handleCancel}
				style={{ margin: "0", padding: 30, width: "100% !important" }}
				className={classes.root}
			>
				<Grid
					container
					justifyContent="center"
					style={{ height: "100vh", paddingBottom: 0 }}
				>
					<Grid
						item
						md={12}
						xs={12}
						style={{ padding: "0 20px", height: "8vh" }}
					>
						<Typography
							variant="h5"
							style={{ margin: "20px 0 0", fontWeight: 600 }}
						>
							{SectionName} / Questions - Options
						</Typography>
					</Grid>
					<DialogContent
						dividers
						style={{ height: "64vh", paddingTop: "25px" }}
					>
						<Grid
							container
							justifyContent="center"
							alignItems="center"
							className={classes.root}
						>
							<Grid item md={12} xs={12} className={classes.relative}>
								<Grid container justifyContent="center" alignItems="center">
									<Grid
										item
										md={6}
										xs={6}
										style={{
											height: "58vh",
											border: "4px solid #ccc",
											marginLeft: "20px !important",
											borderRadius: 10,
											position: "relative",
										}}
									>
										<div style={gridStyle} className="ag-theme-alpine">
											<AgGridReact
												rowData={questionList}
												columnDefs={columnDefs}
												defaultColDef={defaultColDef}
												rowHeight={38}
												headerHeight={42}
												loadingOverlayComponent={loadingOverlayComponent}
												animateRows={true}
												getContextMenuItems={(e: any) =>
													getQuestionContextMenuItems(e)
												}
												pagination={true}
												onRowClicked={rowClicked}
												onRowDoubleClicked={(event) => {
													setNewMode(false);
													setQuestionSelectedInTable(event.data);
													setQuestionId(event.data?.QuestionId);
													setQuestionText(event.data?.QuestionText);
													setOpenNewEditQuestion(true);
												}}
												rowSelection="single"
												onGridReady={(params) => setGridApi(params.api)}
											/>
										</div>
										<>
											<IconButton
												onClick={() => handleNewQuestion()}
												aria-label="add"
												color="primary"
												className={classes.btnAdd}
											>
												<AddBoxIcon style={{ width: 35, height: 35 }} />
											</IconButton>
										</>
									</Grid>
									<OptionsTable
										questionIdToSearchOptions={
											questionIdToSearchOptions as number
										}
										openNewEditOption={openNewEditOption}
										openDeleteOption={openDeleteOption}
										setOpenNewEditOption={setOpenNewEditOption}
										setOpenDeleteOption={setOpenDeleteOption}
										setNewOptionMode={setNewOptionMode}
										newOptionMode={newOptionMode}
										setShouldUpdateWhenExit={setShouldUpdateWhenExit}
									/>
								</Grid>
								<NewAndEditQuestionModal
									show={openNewEditQuestion}
									data={questionSelectedInTable}
									newMode={newMode}
									setShouldUpdateWhenExit={setShouldUpdateWhenExit}
									onHide={(shouldUpdate: boolean) => {
										if (shouldUpdate) {
											searchQuestions();
											setQuestionIdToSearchOptions(null);
											setQuestionOptionsList([]);
										}
										setOpenNewEditQuestion(false);
									}}
								/>
								<DeleteQuestionDialog
									show={openDeleteQuestion}
									setShouldUpdateWhenExit={setShouldUpdateWhenExit}
									onHide={(shouldUpdate: boolean) => {
										if (shouldUpdate) {
											setQuestionIdToSearchOptions(null);
											searchQuestions();
											setQuestionOptionsList([]);
										}
										setOpenDeleteQuestion(false);
									}}
								/>
							</Grid>
						</Grid>
					</DialogContent>

					<Grid item md={12} xs={12} justifyContent="flex-start">
						<DialogActions style={{ padding: "0px 24px" }}>
							<Grid
								container
								justifyContent="flex-start"
								spacing={2}
								style={{ margin: 0 }}
							>
								<Grid item md={3} xs={4}>
									<Button
										fullWidth
										variant="contained"
										color="default"
										onClick={handleCancel}
									>
										Close
									</Button>
								</Grid>
							</Grid>
						</DialogActions>
					</Grid>
					<ErrorModal error={error} onHide={() => setError("")} />
				</Grid>
			</Dialog>
		</>
	);
};
