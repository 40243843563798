import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import {
	ColDef,
	EditableCallbackParams,
	GetContextMenuItemsParams,
	GridApi,
	MenuItemDef,
} from "ag-grid-community";
import Grid from "@material-ui/core/Grid";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Form } from "../../models/Form";
import { useFormsContext } from "../../context/FormsContext";
import { Button, IconButton } from "@material-ui/core";
import { Filters } from "../filters";
import { ButtonToShowFormsAssetAndUsers } from "../columns-table/ButtonToShowFormsAssetAndUsers";
import { ButtonToShowFormSectionsTable } from "../columns-table/ButtonToShowFormSectionsTable";
import { ErrorModal } from "@dexteel/mesf-core";
import CustomLoadingOverlay from "../../../../../MMS/components/loaders/LazyLoading/LazyLoading";
import { NewAndEditFormModal } from "./forms-modals/modal-new-and-edit-form";
import FormatListBulletedSharpIcon from "@material-ui/icons/FormatListBulletedSharp";
import { DeleteFormDialog } from "./forms-modals/modal-delete-form";
import { INITIAL_VALUES_FORM } from "../../models/FormInitialValues";
import { FormSectionsTable } from "../formsSectionsTable/formSectionsTable";
import { useSearchFormsSections } from "../../hooks/useSearcFormSections";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { FormAssetsAndUsersTable } from "../formAssetsAndUsersTable/formAssetandUsersTable";
import { FormPreviewer } from "../previewFormInstance/viewer-form-instance";
import { useClone } from "../../hooks/useClone";

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		"& .ag-icon-menu": {
			display: "none",
		},
		"& .ag-header-cell-label": {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},

		"& .ag-header-cell-text": {
			textAlign: "center !important",
		},

		"& .ag-checkbox-input-wrapper": {
			margin: "0 10px",
		},
		"& .ag-cell": {
			alignItems: "center",
			padding: "0 !important",
			border: "unset !important",
		},
		"& .ag-header-cell": {
			padding: "0px !important",
			textAlign: "center !important",
			fontSize: 10,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		"& .ag-cell-wrapper": {
			textAlign: "center",
		},
		"& .ag-menu-option": {
			cursor: "pointer",
		},
		"& .MuiDataGrid-row": {
			cursor: "pointer",
		},
		"& .MuiDataGrid-columnHeaderTitle, & .MuiTablePagination-caption": {
			fontWeight: "bold",
			fontSize: "12px",
		},
		"& .MuiDataGrid-columnHeaderTitleContainer": {
			padding: "0",
		},
		"& .MuiDataGrid-cell--textLeft": {
			fontSize: "12px",
		},
	},
	btnAdd: {
		position: "absolute",
		padding: 0,
		bottom: 17,
		left: 17,
	},
	relative: {
		position: "relative",
	},
	textfield: {
		margin: theme.spacing(2),
	},
	btnModal: {
		display: "flex",
		justifyContent: "flex-end",
	},
	btnSearch: {
		[theme.breakpoints.up("md")]: {
			marginLeft: "10px !important",
		},
	},
	buttonsFilter: {
		[theme.breakpoints.up("md")]: {
			padding: "0 8px 8px 8px",
		},
	},
	flexEnd: {
		[theme.breakpoints.up("md")]: {
			justifyContent: "flex-end",
			marginTop: "-12px",
		},
	},
	dataTable: {
		padding: theme.spacing(1, 2),
	},
}));

export const FormsTable = ({
	isLoading,
	searchForms,
	error,
	setError,
}: {
	isLoading: boolean;
	searchForms: Function;
	error: string;
	setError: (err: string) => void;
}) => {
	const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
	const classes = useStyles();
	const [gridApi, setGridApi] = useState<GridApi | null>(null);
	const [isSectionsLoading, setIsSectionsLoading] = useState<boolean>(false);
	const [sectionsError, setSectionsError] = useState<string>("");
	const [columnDefs, setColumnDefs] = useState<ColDef[]>();
	const [newMode, setNewMode] = useState<boolean>(false);
	const [openNewEditDialog, setOpenNewEditDialog] = useState<boolean>(false);
	const searchFormSections = useSearchFormsSections({
		setIsSectionsLoading,
		setSectionsError,
	});
	const [openFormAssetAndUsersTable, setOpenFormAssetAndUsersTable] =
		useState(false);

	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [openSectionsTable, setOpenSectionsTable] = useState(false);
	const [openFormPreviewer, setOpenFormPreviewer] = useState(false);
	const [openAddFormAssetAndUserModal, setOpenAddFormAssetAndUserModal] =
		useState(false);

	const {
		state: { FormId, formsList, formSelectedInTable },
		actions: {
			setFormId,
			setFormName,
			setFormSelectedInTable,
			setSearchText,
			setFormInstanceSections,
			setFormInstanceQuestions,
		},
	} = useFormsContext();

	const onKeyDown = (e: any) => {
		if (e.keyCode === 13) {
			searchForms();
		}
	};

	const onActionsClick = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		params: EditableCallbackParams,
	) => {
		(params.api as any).contextMenuFactory.showMenu(
			params.node,
			params.column,
			undefined,
			e,
		);
	};

	const { CloneForm } = useClone();

	const handleClone = (formId: number) => {
		CloneForm({ FormId: formId, searchForms });
	};

	const loadingOverlayComponent = useMemo<any>(() => {
		return CustomLoadingOverlay;
	}, []);

	const defaultColDef = useMemo<ColDef>(() => {
		return {
			sortable: true,
			wrapText: true,
			autoHeight: true,
			wrapHeaderText: true,
		};
	}, []);

	const renderColumns = () => {
		setColumnDefs([]);
		const newColumns: ColDef[] = [];
		newColumns.push({
			field: "FormName",
			headerName: "Form Name",
			sortable: true,
			flex: 3,
			minWidth: 130,
		}),
			newColumns.push({
				field: "IncludesComment",
				headerName: "Includes Comment",
				sortable: false,
				flex: 1,
				minWidth: 130,
				valueFormatter: function (params) {
					return params.value ? "Yes" : "No";
				},
			}),
			newColumns.push({
				field: "LayoutCode",
				headerName: "Layout",
				sortable: false,
				flex: 1,
				minWidth: 50,
				maxWidth: 50,
			}),
			newColumns.push({
				field: "IsEnabled",
				headerName: "Is Enabled",
				sortable: false,
				flex: 1,
				minWidth: 50,
				maxWidth: 50,
				valueFormatter: function (params) {
					return params.value ? "Yes" : "No";
				},
			}),
			newColumns.push({
				field: "",
				headerName: "Sections / Questions",
				sortable: false,
				flex: 3,
				minWidth: 200,
				maxWidth: 200,
				cellRenderer: (params: EditableCallbackParams) =>
					ButtonToShowFormSectionsTable({ params, setOpenSectionsTable }),
			}),
			newColumns.push({
				field: "AssetAndUsers",
				headerName: "Assets and Users",
				sortable: false,
				flex: 3,
				minWidth: 200,
				maxWidth: 200,
				cellRenderer: (params: EditableCallbackParams) =>
					ButtonToShowFormsAssetAndUsers({
						params,
						setOpenFormAssetAndUsersTable,
					}),
			}),
			newColumns.push({
				headerName: "",
				flex: 1,
				minWidth: 65,
				maxWidth: 65,
				cellClass: "ag-columns",
				wrapText: false,
				autoHeight: false,
				cellRenderer: (params: EditableCallbackParams) => {
					return (
						<Grid
							container
							style={{
								height: "100%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Grid
								item
								xs={12}
								md={12}
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<Grid
									container
									spacing={1}
									style={{ justifyContent: "flex-end", minWidth: 30 }}
								>
									<Grid item xs={12} md={12}>
										<Button
											fullWidth
											style={{}}
											onClick={(e) => onActionsClick(e, params)}
										>
											<FormatListBulletedSharpIcon
												style={{ height: "auto" }}
												color="action"
											/>
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					);
				},
			});
		setColumnDefs(newColumns);
	};

	const getContextMenuItems = useCallback(
		(params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
			const data: Form = params.node?.data;
			const result: (string | MenuItemDef)[] = [];
			const onlyNew: (string | MenuItemDef)[] = [];

			if (data) {
				result.push({
					name: "<strong>Content</strong>",
					action: () => {
						setNewMode(false);
						setFormId(data?.FormId);
						setFormName(data?.FormName);
						setFormSelectedInTable(data);
						setOpenSectionsTable(true);
					},
					icon: '<i class="fas fa-table"></i>',
				});
			}

			if (
				data &&
				(data.TotalSections as number) >= 1 &&
				(data.TotalQuestions as number) >= 1
			) {
				result.push({
					name: "Preview",
					action: () => {
						setFormId(null);
						setFormId(data?.FormId);
						setOpenFormPreviewer(true);
					},
					icon: '<i class="fas fa-file-invoice"></i>',
				});
			}

			//New

			result.push({
				name: "New Form",
				action: () => {
					setFormSelectedInTable(INITIAL_VALUES_FORM);
					setNewMode(true);
					setOpenNewEditDialog(true);
					setFormId(null);
					setFormName("");
				},
				icon: '<i class="fas fa-plus"></i>',
			});

			//Edit
			if (data) {
				result.push({
					name: "Edit Form",
					action: () => {
						setNewMode(false);
						setFormSelectedInTable(data);
						setFormId(data?.FormId);
						setFormName(data?.FormName);
						setOpenNewEditDialog(true);
					},
					icon: '<i class="fas fa-edit"></i>',
				});
			}

			//View Form Asset Users table
			if (data) {
				result.push({
					name: "Form / Asset / Users",
					action: () => {
						setNewMode(false);
						setFormId(data?.FormId);
						setFormName(data?.FormName);
						setOpenFormAssetAndUsersTable(true);
					},
					icon: '<i class="fas fa-file-invoice"></i>',
				});
			}

			// Content

			if (data) {
				{
					result.push(
						// "separator",
						{
							name: "Duplicate Form",
							action: () => {
								setFormSelectedInTable(data);
								handleClone(data.FormId as number);
							},
							icon: '<i class="fas fa-clone"></i>',
						},
					);
				}
			}

			//Delete
			if (data) {
				result.push({
					name: "Delete",
					action: () => {
						setNewMode(false);
						setOpenDeleteDialog(true);
						setFormSelectedInTable(data);
					},
					icon: '<i class="fas fa-trash"></i>',
				});
			}

			return result ? result : onlyNew;
		},
		[gridApi],
	);

	const resetFilter = () => {
		setSearchText("");
	};

	useEffect(() => {
		if (isLoading) {
			gridApi?.showLoadingOverlay();
		} else {
			gridApi?.hideOverlay();
		}
	}, [isLoading, formsList, gridApi]);

	useEffect(() => {
		renderColumns();
	}, [formsList]);

	return (
		<Grid container justifyContent="center">
			<Grid item md={12} xs={12} style={{ padding: "0 15px" }}>
				<Typography
					variant="h5"
					style={{ margin: "20px 0 20px", fontWeight: 600 }}
				>
					Forms Settings
				</Typography>
			</Grid>
			<Grid container>
				<Grid item md={6} xs={6}>
					<Filters searchForms={searchForms} onKeyDown={onKeyDown} />
				</Grid>
				<Grid
					item
					md={2}
					xs={12}
					style={{ marginTop: "10px", marginLeft: "auto", paddingRight: 20 }}
				>
					<Button
						fullWidth
						variant="contained"
						color="default"
						onClick={resetFilter}
					>
						RESET
					</Button>
				</Grid>
				<Grid
					item
					md={2}
					xs={12}
					style={{ marginTop: "10px", marginLeft: "auto", paddingRight: 20 }}
					className={classes.btnSearch}
				>
					<Button
						fullWidth
						variant="contained"
						color="primary"
						onClick={() => searchForms()}
					>
						SEARCH
					</Button>
				</Grid>
			</Grid>
			<Grid
				container
				justifyContent="center"
				alignItems="center"
				className={classes.root}
			>
				<Grid
					item
					md={12}
					xs={12}
					style={{ padding: "0 15px" }}
					className={classes.relative}
				>
					<FormAssetsAndUsersTable
						show={openFormAssetAndUsersTable}
						onHide={(shouldUpdate: boolean) => {
							if (shouldUpdate) {
								searchForms();
							}
							setOpenFormAssetAndUsersTable(false);
						}}
						setOpenAddFormAssetAndUserModal={setOpenAddFormAssetAndUserModal}
						openAddFormAssetAndUserModal={openAddFormAssetAndUserModal}
					/>

					<Grid
						item
						md={12}
						xs={12}
						style={{
							height: "61vh",
							border: "4px solid #ccc",
							borderRadius: 10,
							padding: 10,
							margin: "20px 0 10px",
							position: "relative",
						}}
					>
						<div style={gridStyle} className="ag-theme-alpine">
							<AgGridReact
								rowData={formsList}
								columnDefs={columnDefs}
								defaultColDef={defaultColDef}
								rowHeight={38}
								headerHeight={42}
								loadingOverlayComponent={loadingOverlayComponent}
								animateRows={true}
								getContextMenuItems={(e: any) => getContextMenuItems(e)}
								pagination={true}
								onRowDoubleClicked={(event) => {
									setFormSelectedInTable(event.data);
									setFormId(event.data?.FormId);
									setFormName(event.data?.FormName);
									setOpenSectionsTable(true);
								}}
								rowSelection="single"
								onGridReady={(params) => setGridApi(params.api)}
							/>
						</div>

						<IconButton
							onClick={() => {
								setFormSelectedInTable(INITIAL_VALUES_FORM);
								setOpenNewEditDialog(true);
								setNewMode(true);
							}}
							aria-label="add"
							color="primary"
							className={classes.btnAdd}
						>
							<AddBoxIcon style={{ width: 35, height: 35 }} />
						</IconButton>

						<NewAndEditFormModal
							show={openNewEditDialog}
							data={formSelectedInTable}
							newMode={newMode}
							onHide={(shouldUpdate: boolean) => {
								if (shouldUpdate) {
									searchForms();
								}
								setOpenNewEditDialog(false);
							}}
						/>
						<DeleteFormDialog
							show={openDeleteDialog}
							searchData={searchForms}
							onHide={(shouldUpdate: boolean) => {
								if (shouldUpdate) {
									searchForms();
								}
								setOpenDeleteDialog(false);
							}}
						/>
						<FormSectionsTable
							show={openSectionsTable}
							searchFormSections={searchFormSections}
							searchForms={searchForms}
							isSectionsLoading={isSectionsLoading}
							onHide={(shouldUpdate: boolean) => {
								if (shouldUpdate) {
									searchForms();
									searchFormSections();
								}
								setOpenSectionsTable(false);
							}}
						/>
						<FormPreviewer
							show={openFormPreviewer}
							FormId={FormId as number}
							onHide={(shouldUpdate: boolean) => {
								setOpenFormPreviewer(false);
								setFormId(null);
								setFormInstanceQuestions([]);
								setFormInstanceSections([]);
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
			<ErrorModal error={error} onHide={() => setError("")} />
			<ErrorModal error={sectionsError} onHide={() => setSectionsError("")} />
		</Grid>
	);
};
