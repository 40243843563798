import { Form } from "./Form";

export const INITIAL_VALUES_FORM: Form = {
	FormId: null,
	FormName: "",
	IsEnabled: true,
	//check this => solutions has default value LayoutCode => L ('list')
	LayoutCode: "T",
	IncludesComment: false,
	IsActive: true,
	TotalSections: null,
	TotalQuestions: null,
};
