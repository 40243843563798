import { ErrorModal } from "@dexteel/mesf-core";
import {
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	DialogTitle,
	FormControlLabel,
	TextField,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { SyntheticEvent, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Question } from "../../../models/Question";
import { useFormsContext } from "../../../context/FormsContext";
import { upsertQuestion } from "../../../repositories/FormsRepository";
import { INITIAL_VALUES_QUESTION } from "../../../models/QuestionInitialValue";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .input-group-text": {
			display: "none",
		},
		"& .MuiDialogContent-dividers": {
			padding: 0,
		},
	},
	assetPicker: {
		"& .MuiGrid-item": {
			width: "500px",
		},
	},

	checkbox: {
		userSelect: "none",
	},
	modalTitle: {
		backgroundColor: "#ced2cc",
		color: "#495057",
		textAlign: "center",
	},
	modalWarning: {
		color: "white",
		backgroundColor: "#dc3545",
		textAlign: "center",
	},
	messageTitle: {
		fontSize: 15,
		fontWeight: "bold",
		color: "#0000008A",
	},
	errorLabel: {
		fontSize: 12,
		color: "#F44336",
	},
	inputEditable: {
		"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			border: "2px solid #3F51B5",
		},
	},
	Subtitle: {
		"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			border: "1px solid #3F51B5",
		},
	},
}));

type Props = {
	show: boolean;
	onHide: (shouldUpdate: boolean) => void;
	setShouldUpdateWhenExit: (shouldUpdate: boolean) => void;
	data: Question | null;
	newMode?: boolean;
};

export const NewAndEditQuestionModal = ({
	show,
	onHide,
	data,
	newMode = false,
	setShouldUpdateWhenExit,
}: Props) => {
	const classes = useStyles();
	const {
		state: { SectionId, questionSelectedInTable, QuestionId, QuestionText },
		actions: {
			setQuestionTextInTable,
			setQuestionIncludesCommentInTable,
			setQuestionIsRequiredInTable,
		},
	} = useFormsContext();

	const [editedSuccess, setEditedSuccess] = useState(false);
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);

	const [error, setError] = useState<string>("");
	const [deleteByIdError, setDeleteByIdError] = useState<string>("");
	const {
		control,
		handleSubmit,
		watch,
		setValue,
		formState: { errors },
		reset,
	} = useForm<Question>({ defaultValues: INITIAL_VALUES_QUESTION });

	//states
	const [fullWidth, setFullWidth] = useState(true);
	const [maxWidth, setMaxWidth] = useState<DialogProps["maxWidth"]>("sm");

	const handleClose = (event?: SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setEditedSuccess(false);
	};
	const handleCancel = async () => {
		reset();
		onHide(false);
	};

	const questionText = watch("QuestionText");

	const changeQuestionText = () => {
		setQuestionTextInTable(questionText);
	};

	const isRequired = watch("IsRequired");

	const changeIsRequired = () => {
		setQuestionIsRequiredInTable(isRequired);
	};
	const includesComment = watch("IncludesComment");

	const changeIncludesComment = () => {
		setQuestionIncludesCommentInTable(includesComment);
	};

	const onSubmit = async (questionData: Question) => {
		setIsSubmitLoading(true);
		const res = await upsertQuestion(
			QuestionId,
			SectionId,
			questionData.QuestionTypeCode,
			questionData.QuestionText,
			questionData.IncludesComment,
			questionData.IsRequired,
		);
		if (res.ok) {
			reset();
			setIsSubmitLoading(false);
			setEditedSuccess(true);
			setShouldUpdateWhenExit(true);
			onHide(true);
		} else {
			setIsSubmitLoading(false);
			setError(res.message);
		}
	};

	useEffect(() => {
		if (data != null && show) {
			reset(INITIAL_VALUES_QUESTION);
			setQuestionTextInTable(data.QuestionText);
			setQuestionIsRequiredInTable(data.IsRequired);
			setQuestionIncludesCommentInTable(data.IncludesComment);
			setValue("QuestionText", data.QuestionText);
			setValue("IsRequired", data.IsRequired);
			setValue("IncludesComment", data.IncludesComment);
			setIsSubmitLoading(false);
		} else {
			reset(INITIAL_VALUES_QUESTION);
		}
	}, [show]);

	useEffect(() => {
		changeQuestionText();
	}, [questionText]);

	useEffect(() => {
		changeIsRequired();
	}, [isRequired]);

	useEffect(() => {
		changeIncludesComment();
	}, [includesComment]);

	return (
		<>
			<Dialog
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				open={show}
				onClose={() => handleCancel()}
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container style={{ padding: "15px 0 20px" }}>
						<DialogTitle
							style={{
								padding: "5px 40px 10px 24px",
								marginRight: 20,
								width: "100%",
							}}
						>
							<Grid item md={12} xs={12}>
								<h1 style={{ fontSize: "1.7em" }}>
									{newMode
										? `QUESTION: ${questionText}`
										: `EDIT: ${data?.QuestionText}`}
								</h1>
							</Grid>
						</DialogTitle>
						<Grid container>
							<DialogContent dividers>
								<Grid container>
									<Grid
										item
										md={12}
										xs={12}
										style={{ padding: 0, marginTop: "-5px" }}
									>
										<Controller
											name="QuestionText"
											control={control}
											rules={{
												validate: (value) => value !== "",
											}}
											render={({ field: { onChange, value } }) => (
												<TextField
													label="Question Name"
													variant="outlined"
													fullWidth
													error={!!errors.QuestionText}
													margin="dense"
													autoComplete="off"
													value={value}
													onChange={(e) => onChange(e)}
													className={classes.inputEditable}
												/>
											)}
										/>
									</Grid>

									<Grid
										container
										item
										md={12}
										xs={12}
										style={{
											justifyContent: "flex-end",
											alignItems: "flex-end",
											marginTop: "20px",
											paddingLeft: "10px",
										}}
									>
										<Grid item md={6} xs={6}>
											<Controller
												name="IsRequired"
												control={control}
												defaultValue={questionSelectedInTable?.IsRequired}
												render={({ field }) => (
													<FormControlLabel
														className={classes.checkbox}
														control={
															<Checkbox
																checked={field.value as boolean}
																onChange={(e) =>
																	field.onChange(e.target.checked)
																}
																name="IsRequired"
																color="primary"
															/>
														}
														label="Required"
													/>
												)}
											/>
										</Grid>
										<Grid item md={6} xs={6}>
											<Controller
												name="IncludesComment"
												control={control}
												render={({ field }) => (
													<FormControlLabel
														className={classes.checkbox}
														control={
															<Checkbox
																checked={field.value as boolean}
																onChange={(e) =>
																	field.onChange(e.target.checked)
																}
																name="IncludesComment"
																color="primary"
															/>
														}
														label="Includes Comment"
													/>
												)}
											/>
										</Grid>
									</Grid>
								</Grid>
							</DialogContent>
							<Grid
								item
								md={12}
								xs={12}
								justifyContent="flex-end"
								style={{ marginTop: "15px" }}
							>
								<DialogActions style={{ padding: "0px 24px" }}>
									<Grid
										container
										justifyContent="flex-end"
										spacing={2}
										style={{ margin: 0 }}
									>
										<Grid item md={3} xs={4}>
											<Button
												fullWidth
												variant="contained"
												color="default"
												onClick={handleCancel}
											>
												Cancel
											</Button>
										</Grid>
										<Grid item md={3} xs={4} style={{ paddingRight: 0 }}>
											<Button
												fullWidth
												startIcon={
													isSubmitLoading && <CircularProgress size="1rem" />
												}
												disabled={isSubmitLoading}
												variant="contained"
												color="primary"
												type="submit"
											>
												Save
											</Button>
										</Grid>
									</Grid>
								</DialogActions>
							</Grid>
						</Grid>
					</Grid>
				</form>
			</Dialog>
			<Snackbar
				open={editedSuccess}
				autoHideDuration={2500}
				onClose={handleClose}
			>
				<Alert severity="info" onClose={handleClose}>
					Success
				</Alert>
			</Snackbar>
			<ErrorModal error={error} onHide={() => setError("")} />
			<ErrorModal
				error={deleteByIdError}
				onHide={() => setDeleteByIdError("")}
			/>
		</>
	);
};
