import { createContext, default as React, ReactNode, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { FormsReducer } from "../reducers/formsReducer";

const FormsContext = createContext({
	state: FormsReducer.getInitialState(),
	actions: FormsReducer.actions,
});

export const useFormsContext = () => useContext(FormsContext);

export const FormProvider = ({ children }: { children: ReactNode }) => {
	const [state, actions] = useComplexState({
		initialState: FormsReducer.getInitialState(),
		reducers: FormsReducer.caseReducers,
	});

	return (
		<FormsContext.Provider value={{ state, actions }}>
			{children}
		</FormsContext.Provider>
	);
};
