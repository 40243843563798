import React from "react";
import { FormsProvider } from "./context/FormsContext";
import { ConfigurationFormsPage } from "./FormsPage";

const FormsPage = () => {
	return (
		<FormsProvider>
			<ConfigurationFormsPage />
		</FormsProvider>
	);
};

export default FormsPage;
