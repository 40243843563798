import { KeyboardEventHandler } from "react";
import Grid from "@material-ui/core/Grid";
import { SearchFilter } from "./searchFilter";

type Props = {
	onKeyDown: KeyboardEventHandler<HTMLDivElement> | undefined;
	searchForms: Function;
};

export const Filters = ({ onKeyDown }: Props) => {
	return (
		<Grid
			container
			item
			spacing={2}
			style={{
				marginLeft: 10,
				justifyContent: "flex-start",
				alignItems: "center",
			}}
		>
			<Grid item md={6} xs={6}>
				<SearchFilter onKeyDown={onKeyDown} />
			</Grid>
		</Grid>
	);
};
