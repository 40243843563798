import { get } from "react-hook-form";
import { getOptionsByQuestionId } from "../repositories/FormsRepository";
import { useFormsContext } from "../context/FormsContext";

type Props = {
	setIsLoading: (isLoading: boolean) => void;
	setError: (err: string) => void;
	questionIdToSearchOptions: number | null;
};

export const useSearchQuestionOptions = ({
	setIsLoading,
	setError,
	questionIdToSearchOptions,
}: Props) => {
	const {
		actions: { setQuestionOptionsList },
	} = useFormsContext();

	return async () => {
		setIsLoading(true);

		const res = await getOptionsByQuestionId(
			questionIdToSearchOptions as number,
		);
		if (res.ok) {
			const rowsSource = get(res, "data.tables[0].rows", []);
			if (rowsSource.length) {
				setQuestionOptionsList(rowsSource);
			} else {
				setQuestionOptionsList([]);
			}
			setIsLoading(false);
		} else {
			setError(res.message);
			setIsLoading(false);
		}
	};
};
