import { FormAsset } from "./FormAsset";

export const INITIAL_VALUES_FORM_ASSET: FormAsset = {
	FormAssetId: null,
	FormId: null,
	AssetId: null,
	AssetName: null,
	SchedulePolicyCode: "M",
	Periods: 1,
	StartDate: new Date(),
};
