import { get } from "react-hook-form";
import { getShifts } from "../repositories/FormsRepository";
import { useFormsContext } from "../context/formsContext";

export const useGetShiftsByProductionDate = () => {
	const {
		state: { newInstanceProductionDate },
		actions: { setNewInstanceShifts },
	} = useFormsContext();

	return async () => {
		return getShifts(newInstanceProductionDate).then((res) => {
			if (res.ok) {
				const instanceRows = get(res, "data", null);
				const rows = instanceRows?.tables[0]?.rows;
				setNewInstanceShifts(rows);
			}
			return res;
		});
	};
};
