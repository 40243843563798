import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogProps,
	DialogTitle,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { SyntheticEvent, useState } from "react";

import { ErrorModal } from "@dexteel/mesf-core";
import { deleteQuestion } from "../../../repositories/FormsRepository";
import { useFormsContext } from "../../../context/FormsContext";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .input-group-text": {
			display: "none",
		},
		"& .MuiDialogContent-dividers": {
			padding: 0,
		},
	},
	modalTitle: {
		backgroundColor: "#ced2cc",
		color: "#495057",
		textAlign: "center",
	},
	modalTitleCloseWO: {
		backgroundColor: "#28a745",
		color: "white",
		textAlign: "center",
	},
	modalWarning: {
		color: "white",
		backgroundColor: "#dc3545",
		textAlign: "center",
	},
	messageTitle: {
		fontSize: 15,
		fontWeight: "bold",
		color: "#0000008A",
	},
	questionControl: {
		margin: theme.spacing(2),
		minWidth: 120,
	},
	errorLabel: {
		fontSize: 12,
		color: "#F44336",
	},
	infoLabel: {
		fontSize: 12,
		color: "#757575",
	},
}));

type Props = {
	show: boolean;
	onHide: (onHide: boolean) => void;
	setShouldUpdateWhenExit: (onHide: boolean) => void;
};

export const DeleteQuestionDialog = ({
	show,
	onHide,
	setShouldUpdateWhenExit,
}: Props) => {
	const classes = useStyles();
	const [fullWidth, setFullWidth] = useState(true);
	const [maxWidth, setMaxWidth] = useState<DialogProps["maxWidth"]>("sm");
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [questionRemovedSuccess, setQuestionRemovedSuccess] =
		useState<boolean>(false);
	const [error, setError] = useState<string>("");
	const {
		state: {
			questionSelectedInTable: { QuestionId, QuestionText },
		},
	} = useFormsContext();

	const handleCancel = () => {
		setIsSubmitLoading(false);
		onHide(false);
	};

	const handleClose = (event?: SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setQuestionRemovedSuccess(false);
	};

	const handleRemoveQuestion = async () => {
		setIsSubmitLoading(true);
		const res = await deleteQuestion(QuestionId as number);
		if (res.ok) {
			setQuestionRemovedSuccess(true);
			setIsSubmitLoading(false);
			onHide(true);
			setShouldUpdateWhenExit(true);
		} else {
			setError(res.message);
			setIsSubmitLoading(false);
		}
	};

	return (
		<>
			<Dialog
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				open={show}
				onClose={() => handleCancel()}
				disableEnforceFocus
				className={classes.root}
			>
				<DialogTitle id="responsive-dialog-title" style={{ paddingBottom: 0 }}>
					{"WARNING"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText style={{ padding: 0, margin: 0 }}>
						Be careful, you are about to remove: <b>{QuestionText}</b>. Once
						deleted you will not be able to recover this question. Are you sure
						you want to delete it?
					</DialogContentText>
				</DialogContent>
				<DialogActions style={{ padding: "0 24px 16px" }}>
					<Button onClick={() => onHide(false)} autoFocus color="default">
						CANCEL
					</Button>
					<Button
						autoFocus
						color="secondary"
						startIcon={isSubmitLoading && <CircularProgress size="1rem" />}
						onClick={handleRemoveQuestion}
					>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
			<Snackbar
				open={questionRemovedSuccess}
				autoHideDuration={2500}
				onClose={handleClose}
			>
				<Alert severity="info" onClose={handleClose}>
					Question removed succesfully
				</Alert>
			</Snackbar>
			<ErrorModal error={error} onHide={() => setError("")} />
		</>
	);
};
