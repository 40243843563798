import { UserFormAssetData } from "./userFormAssetData";

export const INITIAL_VALUES_MODAL: UserFormAssetData = {
	FormAssetId: null,
	AssetId: 1,
	AssetName: "Plant",
	UserId: null,
	UserName: "",
	IsDefault: false,
};
