import { DocumentParameters } from "./DocumentParameters";

export const METADATA_INITIAL_VALUES: DocumentParameters = {
	DocumentId: null,
	DocumentName: "",
	Title: "",
	Extension: "",
	TreeId: null,
	VendorName: "",
	VendorDatetime: null,
	VendorVersion: "",
	VendorDocument: "",
	CreationTimestamp: new Date(),
	CreationUserName: "",
	UpdatingFlag: false,
	UpdatingUserName: "",
	UpdatingTimestamp: null,
	Version: null,
	DocPath: null,
	PDFPath: null,
	PNGPath: null,
	TMBPath: null,
	UserName: null,
	UploadSource: null,
	UploadTimestamp: null,
	FileTimestamp: null,
	FileSize: null,
	isLeaf: null,
	UpdatingComment: "",
	shelfNameInRowSelected: null,
	IsObsolete: false,
	DocumentParts: null,
	VersionId: null,
};
