import { MESApiService, spParameter } from "@dexteel/mesf-core";
import moment from "moment"; // Import moment library

export const getForms = async (
	Start: Date,
	End: Date,
	AssetId: number | null,
	FormId: number | null,
	SearchText: string | null,
	FormInstanceStatusCodes: string | null,
) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	let year = End.getFullYear();
	let month = String(End.getMonth() + 1).padStart(2, "0");
	let day = String(End.getDate()).padStart(2, "0");

	const endString = `${year}-${month}-${day}`;

	year = Start.getFullYear();
	month = String(Start.getMonth() + 1).padStart(2, "0");
	day = String(Start.getDate()).padStart(2, "0");

	const startString = `${year}-${month}-${day}`;

	parameters.push({ name: "Start", value: startString });
	parameters.push({ name: "End", value: endString });
	parameters.push({ name: "AssetId", value: AssetId == 1 ? null : AssetId });
	parameters.push({ name: "FormId", value: FormId });
	parameters.push({
		name: "Search",
		value: SearchText === "" ? null : SearchText,
	});
	parameters.push({
		name: "FormInstanceStatusCodes",
		value: FormInstanceStatusCodes === "All" ? null : FormInstanceStatusCodes,
	});

	return await apiService.callV2(
		"[FORMS].[GetFormInstancesByRange]",
		parameters,
	);
};

export const getFormsForUser = async (userId: number) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];
	parameters.push({ name: "UserId", value: userId });
	return await apiService.callV2("[FORMS].[GetFormsForUser]", parameters);
};

export const getFormInstance = async (
	FormInstanceId: number | null | undefined,
) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({
		name: "FormInstanceId",
		value: FormInstanceId ? FormInstanceId : null,
	});

	return await apiService.callV2("[FORMS].[GetFormInstance]", parameters);
};

export const upsertRadioButtonAnswer = async (
	ModifiedRows: string | null,
	Comments: string | null,
	formInstanceId: number,
) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "jsonData", value: ModifiedRows });
	parameters.push({ name: "Comments", value: Comments });
	parameters.push({ name: "formInstanceId", value: formInstanceId });

	const resp = await apiService.callV2(
		`[FORMS].[UpsertRadioButtonAnswers]`,
		parameters,
	);
	return resp;
};

export const createFormInstance = async (
	FormId: number | null,
	AssetId: number | null,
	ShiftId: number | null,
) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({ name: "FormId", value: FormId });
	parameters.push({ name: "AssetId", value: AssetId });
	parameters.push({ name: "ShiftId", value: ShiftId });

	return await apiService.callV2("[FORMS].[CreateFormInstance]", parameters);
};

export const getShifts = async (ProductionDate: Date | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({
		name: "Start",
		value: ProductionDate ? ProductionDate : moment().format("YYYY-MM-DD"),
	});
	parameters.push({
		name: "End",
		value: ProductionDate ? ProductionDate : moment().format("YYYY-MM-DD"),
	});

	return await apiService.callV2("[MES].[GetShiftsByParameters]", parameters);
};

export const removeInstance = async (instanceId: number | null) => {
	if (instanceId === null) return;
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];
	parameters.push({ name: "FormInstanceId", value: instanceId });

	return await apiService.callV2("[FORMS].[DeleteFormInstance]", parameters);
};

export const submitFormInstance = async (
	FormInstanceId: number,
	Comments: string | null,
	SubmitInitials: string,
) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];
	parameters.push({ name: "FormInstanceId", value: FormInstanceId });
	parameters.push({ name: "Comments", value: Comments });
	parameters.push({ name: "SubmitSignature", value: SubmitInitials });
	return await apiService.callV2("[FORMS].[SubmitFormInstance]", parameters);
};

export const approveFormInstance = async (
	FormInstanceId: number,
	ApproveInitials: string,
) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];
	parameters.push({ name: "FormInstanceId", value: FormInstanceId });
	parameters.push({ name: "ApprovalSignature", value: ApproveInitials });

	return await apiService.callV2("[FORMS].[ApproveFormInstance]", parameters);
};

export const unapproveFormInstance = async (FormInstanceId: number) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];
	parameters.push({ name: "FormInstanceId", value: FormInstanceId });
	return await apiService.callV2("[FORMS].[UnapproveFormInstance]", parameters);
};
