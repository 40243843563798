export const ColorCircleRenderer = ({ value }: { value: string }) => {
	return (
		<div style={{ display: "flex", alignItems: "center" }}>
			<div
				style={{
					width: 20,
					height: 20,
					borderRadius: "50%",
					backgroundColor: value,
					marginRight: 5,
				}}
			></div>
		</div>
	);
};
