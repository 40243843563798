import { Questions } from "@/pages/forms/components";
import { Dialog, DialogContent, DialogProps, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSearchDataForFormPreview } from "../../hooks/useSearchDataForFormPreview";
import { ErrorModal } from "@dexteel/mesf-core";
import { LazyLoading } from "@/components/loaders/Loader/LazyLoading";
import { useFormsContext } from "../../context/FormsContext";

type Props = {
	show: boolean;
	onHide: (onHide: boolean) => void;
	FormId: number;
};

export const FormPreviewer = ({ show, onHide, FormId }: Props) => {
	const [fullWidth, setFullWidth] = useState(true);
	const [maxWidth, setMaxWidth] = useState<DialogProps["maxWidth"]>("xl");
	const [dataForPreviewIsLoading, setDataForPreviewIsLoading] = useState(false);
	const [dataForPreviewError, setDataForPreviewError] = useState("");

	const searchFormsSections = useSearchDataForFormPreview({
		setDataForPreviewIsLoading,
		setDataForPreviewError,
		FormId,
	});

	const {
		state: { formInstanceSections, formInstanceQuestions },
	} = useFormsContext();

	const handleCancel = () => {
		onHide(false);
	};

	useEffect(() => {
		if (FormId) searchFormsSections();
	}, [FormId]);

	return (
		<>
			<Dialog
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				open={show}
				onClose={handleCancel}
				style={{ margin: "0", padding: 30, width: "100% !important" }}
			>
				<DialogContent dividers style={{ height: "", paddingTop: "25px" }}>
					<Grid container>
						{dataForPreviewIsLoading ? (
							<Grid
								container
								item
								md={12}
								xs={12}
								style={{
									justifyContent: "center",
									alignItems: "center",
									height: "40vh",
								}}
							>
								<LazyLoading />
							</Grid>
						) : (
							<Grid item md={12} xs={12}>
								<Questions
									formInstanceSections={formInstanceSections}
									formInstanceQuestions={formInstanceQuestions}
									layoutCode={"T"}
								/>
							</Grid>
						)}
						<ErrorModal
							error={dataForPreviewError}
							onHide={() => setDataForPreviewError("")}
						/>
					</Grid>
				</DialogContent>
			</Dialog>
		</>
	);
};
