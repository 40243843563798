import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import { AgGridReact } from "ag-grid-react";
import {
	ColDef,
	EditableCallbackParams,
	GetContextMenuItemsParams,
	GridApi,
	MenuItemDef,
	RowDoubleClickedEvent,
} from "ag-grid-community";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormsContext } from "../../context/FormsContext";
import { LazyLoading } from "@/components/loaders/Loader/LazyLoading";
import { ErrorModal } from "@dexteel/mesf-core";
import FormatListBulletedSharpIcon from "@material-ui/icons/FormatListBulletedSharp";
import { useSearchFormAssets } from "../../hooks/useSearchFormAssets";
import { FormAsset } from "../../models/FormAsset";
import { UsersFormAssetTable } from "./usersFormAssetTable";
import { AssetNameColumn } from "./components/columns-table/assetName-column";
import { DeleteFormAssetModal } from "./components/delete-form-asset-modal/delete-form-asset-modal";
import { useSearchFormAsset } from "../../hooks/useSearchFormAsset";
import { NewFormAssetModal } from "./components/new-form-asset-modal/new-form-asset-modal";
import { INITIAL_VALUES_FORM_ASSET } from "../../models/FormAssetInitialValue";
import { INITIAL_VALUES_USER_FORM_ASSET } from "../../models/UserFormAssetInitialValue";

const useStyles = makeStyles((theme) => ({
	root: {
		"& h3": {
			display: "inline-block",
			marginTop: 0,
			paddingBotton: 0,
			paddingLeft: 20,
			border: "none",
			userSelect: "none",
		},
		"& .content-wrapper": {
			border: "none",
		},
		"& .ag-icon-menu": {
			display: "none",
		},
		"& .ag-root-wrapper": {
			borderRadius: 3,
		},
		"& .ag-header-cell": {
			padding: "0px !important",
			textAlign: "center !important",
			fontSize: 10,
		},
		"& .ag-header-cell-label": {
			justifyContent: "center",
		},
		"& .ag-cell": {
			padding: "0px !important",
			textAlign: "center !important",
			fontSize: 12,
			lineBreak: "auto",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			border: "0 !important",
			wordBreak: "break-word",
		},
		"& .ag-comments": {
			display: "flex",
			justifyContent: "flex-start",
			alignItems: "center",
		},
		"& .ag-selection-checkbox": {
			marginLeft: "10px !important",
		},
	},
	relative: {
		position: "relative",
	},
	modalTitle: {
		backgroundColor: "#ced2cc",
		color: "#495057",
		textAlign: "center",
	},
	modalTitleCloseWO: {
		backgroundColor: "#28a745",
		color: "white",
		textAlign: "center",
	},
	modalWarning: {
		color: "white",
		backgroundColor: "#dc3545",
		textAlign: "center",
	},
	messageTitle: {
		fontSize: 15,
		fontWeight: "bold",
		color: "#0000008A",
	},
	responsive: {
		[theme.breakpoints.up("md")]: {
			padding: "0 10px 0 0 !important",
			marginTop: "0 !important",
		},
	},
	responsiveTwo: {
		[theme.breakpoints.up("md")]: {
			padding: "0 0 0 10px !important",
			marginTop: "0 !important",
		},
	},
	responsiveThree: {
		[theme.breakpoints.up("md")]: {
			padding: "0 !important",
		},
	},
	responsiveFour: {
		[theme.breakpoints.up("md")]: {
			padding: "0 10px 0 0 !important",
		},
	},
	responsiveFive: {
		[theme.breakpoints.up("md")]: {
			padding: "0 0 0 10px !important",
		},
	},
}));

type Props = {
	show: boolean;
	onHide: (onHide: boolean) => void;
	setOpenAddFormAssetAndUserModal: Function;
	openAddFormAssetAndUserModal: boolean;
};

export const FormAssetsAndUsersTable = ({
	show,
	onHide,
	setOpenAddFormAssetAndUserModal,
	openAddFormAssetAndUserModal,
}: Props) => {
	const [gridApi, setGridApi] = useState<GridApi | null>(null);
	const classes = useStyles();
	const gridStyle = useMemo(
		() => ({ height: "100%", width: "100%", padding: 10 }),
		[],
	);
	const [maxWidth, setMaxWidth] = useState<DialogProps["maxWidth"]>("lg");
	const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
	const [shouldUpdateWhenExit, setShouldUpdateWhenExit] = useState(false);
	const [formAssetId, setFormAssetId] = useState<number | null>(null);
	const [openDeleteFormAssetModal, setOpenDeleteFormAssetModal] =
		useState(false);

	const [openNewEditUserFormAsset, setOpenNewEditUserFormAsset] =
		useState(false);
	const [openDeleteUserFormAsset, setOpenDeleteUserFormAsset] = useState(false);
	const [newUserFormAssetMode, setNewUserFormAssetMode] =
		useState<boolean>(false);

	const {
		state: {
			FormName,
			formAssetsList,
			FormAsset: { FormAssetId },
		},
		actions: {
			setFormAssetsList,
			setFormId,
			setAssetId,
			setUsersFormAssetList,
			setFormAsset,
			setUserFormAssetDataSelectedInTable,
		},
	} = useFormsContext();

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [error, setError] = useState<string>("");

	const searchFormAssets = useSearchFormAssets({ setIsLoading, setError });

	const loadingOverlayComponent = useMemo<any>(() => {
		return LazyLoading;
	}, []);

	const defaultColDef = useMemo<ColDef>(() => {
		return {
			sortable: true,
			wrapText: true,
			autoHeight: true,
			wrapHeaderText: true,
		};
	}, []);

	const onActionsClick = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		params: EditableCallbackParams,
	) => {
		(params.api as any).contextMenuFactory.showMenu(
			params.node,
			params.column,
			undefined,
			e,
		);
	};

	const rowClicked = (event: RowDoubleClickedEvent) => {
		if (event.data) {
			setFormAssetId(event.data.FormAssetId);
		}
	};

	const handleCancel = () => {
		onHide(shouldUpdateWhenExit);
		setFormAssetId(null);
		setFormAsset(INITIAL_VALUES_FORM_ASSET);
	};

	const getFormAssetContextMenuItems = useCallback(
		(params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
			const data: FormAsset = params.node?.data;
			const result: (string | MenuItemDef)[] = [];

			if (data) {
				result.push({
					name: "Add Asset",
					action: () => {
						setFormId(data?.FormId);
						setOpenAddFormAssetAndUserModal(true);
					},
					icon: '<i class="fas fa-plus"></i>',
				});
			}
			if (data) {
				{
					result.push("separator", {
						name: "Remove Asset",
						action: () => {
							setFormId(data?.FormId);
							setAssetId(data?.AssetId);
							setOpenDeleteFormAssetModal(true);
						},
						icon: '<i class="fas fa-trash"></i>',
					});
				}
			}

			const addNewForm: (string | MenuItemDef)[] = [];

			if (!data) {
				addNewForm.push({
					name: "Add Asset",
					action: () => {
						setOpenAddFormAssetAndUserModal(true);
					},
					icon: '<i class="fas fa-plus"></i>',
				});
			}

			return data ? result : addNewForm;
		},
		[],
	);

	useEffect(() => {
		if (show) {
			setShouldUpdateWhenExit(false);
			searchFormAssets();
			setColumnDefs([]);
			setFormAssetsList([]);
			setFormAssetId(null);

			const newColumns: ColDef[] = [];

			newColumns.push({
				field: "AssetId",
				headerName: "Asset",
				sortable: false,
				flex: 7,
				minWidth: 130,
				cellRenderer: AssetNameColumn,
			}),
				newColumns.push({
					headerName: "",
					flex: 1,
					minWidth: 65,
					maxWidth: 65,
					sortable: false,
					cellClass: "ag-columns",
					wrapText: false,
					autoHeight: false,
					cellRenderer: (params: EditableCallbackParams) => {
						return (
							<Grid
								container
								style={{
									height: "100%",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<Grid
									item
									xs={12}
									md={12}
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<Grid
										container
										spacing={1}
										style={{ justifyContent: "flex-end", minWidth: 30 }}
									>
										<Grid item xs={12} md={12}>
											<Button
												fullWidth
												style={{}}
												onClick={(e) => onActionsClick(e, params)}
											>
												<FormatListBulletedSharpIcon
													style={{ height: "auto" }}
													color="action"
												/>
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						);
					},
				});

			setColumnDefs(newColumns);
		} else setUsersFormAssetList([]);
	}, [show]);

	useEffect(() => {
		if (isLoading) {
			gridApi?.showLoadingOverlay();
		} else gridApi?.hideOverlay();
	}, [isLoading, gridApi]);

	return (
		<>
			<Dialog
				maxWidth={maxWidth}
				open={show}
				onClose={handleCancel}
				fullWidth={true}
				style={{ margin: "0", padding: 30, width: "100% !important" }}
				className={classes.root}
			>
				<Grid
					container
					justifyContent="center"
					style={{ height: "100vh", paddingBottom: 0 }}
				>
					<Grid
						item
						md={12}
						xs={12}
						style={{ padding: "0 30px", height: "8vh" }}
					>
						<Typography
							variant="h5"
							style={{ margin: "30px 0 0", fontWeight: 600 }}
						>
							CONFIGURE FORM {FormName}
						</Typography>
					</Grid>
					<DialogContent
						dividers
						style={{ height: "64vh", paddingTop: "25px", overflowY: "hidden" }}
					>
						<Grid
							container
							justifyContent="center"
							alignItems="center"
							className={classes.root}
						>
							<Grid item md={12} xs={12} className={classes.relative}>
								<Grid container justifyContent="center" alignItems="center">
									<Grid
										item
										md={4}
										xs={6}
										style={{
											height: "58vh",
											minHeight: "100%",
											border: "4px solid #ccc",
											marginLeft: "20px !important",
											borderRadius: 10,
										}}
									>
										<div style={gridStyle} className="ag-theme-alpine">
											<AgGridReact
												rowData={formAssetsList}
												columnDefs={columnDefs}
												defaultColDef={defaultColDef}
												rowHeight={38}
												headerHeight={42}
												loadingOverlayComponent={loadingOverlayComponent}
												animateRows={true}
												getContextMenuItems={(e: any) =>
													getFormAssetContextMenuItems(e)
												}
												pagination={true}
												onRowClicked={rowClicked}
												rowSelection="single"
												onGridReady={(params) => setGridApi(params.api)}
											/>
										</div>
									</Grid>
									<UsersFormAssetTable
										FormAssetIdToSearchUserFormAssets={formAssetId as number}
										openNewEditUserFormAsset={openNewEditUserFormAsset}
										openDeleteUserFormAsset={openDeleteUserFormAsset}
										setOpenNewEditUserFormAsset={setOpenNewEditUserFormAsset}
										setOpenDeleteUserFormAsset={setOpenDeleteUserFormAsset}
										newUserFormAssetMode={newUserFormAssetMode}
										setNewUserFormAssetMode={setNewUserFormAssetMode}
										setShouldUpdateWhenExit={setShouldUpdateWhenExit}
									/>
								</Grid>
								<NewFormAssetModal
									show={openAddFormAssetAndUserModal}
									onHide={(shouldUpdate: boolean) => {
										if (shouldUpdate) {
											searchFormAssets();
											setShouldUpdateWhenExit(true);
											setFormAssetId(null);
										}
										setOpenAddFormAssetAndUserModal(false);
									}}
								/>
								<DeleteFormAssetModal
									show={openDeleteFormAssetModal}
									onHide={(shouldUpdate: boolean) => {
										if (shouldUpdate) {
											searchFormAssets();
											setShouldUpdateWhenExit(true);
											setFormAssetId(null);
										}
										setOpenDeleteFormAssetModal(false);
									}}
								/>
							</Grid>
						</Grid>
					</DialogContent>

					<Grid item md={12} xs={12} justifyContent="flex-start">
						<DialogActions style={{ padding: "0px 17px" }}>
							<Grid
								container
								justifyContent="flex-start"
								spacing={2}
								style={{ margin: 0 }}
							>
								<Grid item md={3} xs={4}>
									<Button
										fullWidth
										variant="contained"
										color="default"
										onClick={handleCancel}
									>
										Close
									</Button>
								</Grid>
								<Grid
									container
									item
									md={9}
									xs={12}
									style={{ display: "flex", justifyContent: "flex-end" }}
								>
									<Grid item md={4} xs={4} style={{ paddingRight: 10 }}>
										<Button
											fullWidth
											variant="contained"
											color="primary"
											disabled={false}
											onClick={() => setOpenAddFormAssetAndUserModal(true)}
										>
											ADD ASSET
										</Button>
									</Grid>
									<Grid item md={4} xs={4} style={{ paddingLeft: 10 }}>
										<Button
											fullWidth
											variant="contained"
											color="primary"
											disabled={FormAssetId ? false : true}
											onClick={() => {
												setUserFormAssetDataSelectedInTable(
													INITIAL_VALUES_USER_FORM_ASSET,
												);
												setNewUserFormAssetMode(true);
												setOpenNewEditUserFormAsset(true);
											}}
										>
											ADD USER
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</DialogActions>
					</Grid>
					<ErrorModal error={error} onHide={() => setError("")} />
				</Grid>
			</Dialog>
		</>
	);
};
