import { MESFMain } from "@dexteel/mesf-core";
import "bootstrap/dist/css/bootstrap.css";
import "react-datepicker/dist/react-datepicker.css";
import { render } from "react-dom";
import "./app.css";
import "./setup/ag-grid-license";

import { AuthenticationConfig } from "./setup/auth-config";
import { customConfigurations } from "./setup/configurations";
import { CustomNavbar } from "./setup/navbar";
import { CustomRoutes } from "./setup/routes";

render(
	<MESFMain
		showAreaSelector={true}
		routes={CustomRoutes}
		navbar={CustomNavbar}
		authentication={AuthenticationConfig}
		configurations={customConfigurations}
	/>,
	document.getElementById("root"),
);
