import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { useFormsContext } from "../context/formsContext";
import { KeyboardEventHandler } from "react";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .MuiFormLabel-root": {
			transform: "translate(14px, -6px) scale(0.75) ",
			backgroundColor: "#FFFF",
			transition: "none !important",
			left: -3.5,
			padding: "0 5px",
		},
		"& .MuiInputBase-input": {
			color: "black",
			paddingRight: 45,
		},
	},
}));

type Props = {
	onKeyDown: KeyboardEventHandler<HTMLDivElement> | undefined;
};

// MuiGrid-item
export const SearchFilter = ({ onKeyDown }: Props) => {
	const classes = useStyles();
	const {
		state: {
			dataToGetFormInstances: { SearchText },
		},
		actions: { setSearchTextForms },
	} = useFormsContext();

	const handleFilter = (e: any) => {
		e.preventDefault();
		setSearchTextForms(e.target.value);
	};

	return (
		<div style={{ position: "relative" }}>
			<TextField
				className={classes.root}
				style={{ borderTopLeftRadius: 3, borderTopRightRadius: 3 }}
				label="Search"
				variant="outlined"
				margin="dense"
				value={SearchText}
				onChange={handleFilter}
				onKeyDown={onKeyDown}
				fullWidth
			/>
		</div>
	);
};
