import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	DialogTitle,
	Grid,
	Snackbar,
	TextField,
	makeStyles,
} from "@material-ui/core";
import { SyntheticEvent, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Alert } from "@material-ui/lab";
import { useFormsContext } from "../../../../context/FormsContext";
import {
	ErrorModal,
	TreePickerControl,
	useAssetContext,
} from "@dexteel/mesf-core";
import { Asset } from "@dexteel/mesf-core/dist/models/Asset";
import { FormAsset } from "../../../../models/FormAsset";
import { ScheduleCodePicker } from "../picker/ScheduleCodePicker";
import {
	KeyboardDatePicker,
	KeyboardTimePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { INITIAL_VALUES_FORM_ASSET } from "src/setup/configurations/forms/models/FormAssetInitialValue";
import { TransformAssetCodeModel } from "@/utils/transforms";
import { upsertFormAsset } from "src/setup/configurations/forms/repositories/FormsRepository";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .input-group-text": {
			display: "none",
		},
		"& .MuiDialogContent-dividers": {
			padding: 0,
		},
		"& .MuiOutlinedInput-root.Mui-disabled": {
			"& fieldset": {
				borderColor: "rgba(0, 0, 0, 0.23)",
			},
		},
	},
	unsetOutline: {
		"& .MuiOutlinedInput-root.Mui-disabled": {
			"& fieldset": {
				borderColor: "rgba(0, 0, 0, 0.23)",
			},
		},
	},
	checkbox: {
		userSelect: "none",
	},
	assetPicker: {
		"& .MuiGrid-item": {
			width: "500px",
		},
	},
	modalTitle: {
		backgroundColor: "#ced2cc",
		color: "#495057",
		textAlign: "center",
	},
	modalWarning: {
		color: "white",
		backgroundColor: "#dc3545",
		textAlign: "center",
	},
	messageTitle: {
		fontSize: 15,
		fontWeight: "bold",
		color: "#0000008A",
	},
	inputEditable: {
		"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			border: "2px solid #3F51B5",
		},
	},
	comments: {
		"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			border: "1px solid #3F51B5",
		},
	},
}));

type Props = {
	FormAsset: FormAsset;
};

export const EditFormAsset = ({ FormAsset }: Props) => {
	const classes = useStyles();
	const [addSuccess, setAddSuccess] = useState(false);
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [error, setError] = useState<string>("");
	const {
		AssetId,
		AssetName,
		FormAssetId,
		SchedulePolicyCode,
		Periods,
		StartDate,
		FormId,
	} = FormAsset;
	const {
		state: { assetCodes },
		actions: {
			setAssetCodes,
			setAssetId,
			setAssetName,
			setFormAssetAssetId,
			setFormAssetAssetName,
		},
	} = useFormsContext();

	const {
		state: { allAssets },
	} = useAssetContext();

	const {
		control,
		handleSubmit,
		setValue,
		formState: { isValid },
		reset,
	} = useForm<FormAsset>({
		defaultValues: INITIAL_VALUES_FORM_ASSET,
	});

	const handleCancel = async () => {
		reset();
		setAssetId(1);
		setAssetName("Plant");
	};

	const buildTreeAssets = (nodes: any) => {
		if (nodes === undefined) return;
		//tree build functions
		const idMapping = nodes.reduce((acc: any, el: any, i: number) => {
			acc[el.id] = i;
			return acc;
		}, {});
		let root;
		nodes.forEach((el: any) => {
			if (el.parentId === null) {
				root = el;
				return;
			}
			const parentEl = nodes[idMapping[el.parentId]];
			if (parentEl) {
				parentEl.children = [...(parentEl?.children || []), el];
			}
		});
		setAssetCodes(root as any);
	};

	const loadFilterAssets = () => {
		let mydataSource: any[] = [];
		allAssets.forEach((row: Asset) => {
			const code = TransformAssetCodeModel(row);
			mydataSource = [...mydataSource, code];
		});
		buildTreeAssets(mydataSource);
	};

	const handleClose = (event?: SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setAddSuccess(false);
	};

	const onSubmit = async (data: FormAsset) => {
		setIsSubmitLoading(true);
		const res = await upsertFormAsset(data);
		if (res.ok) {
			setIsSubmitLoading(false);
			setAddSuccess(true);
		} else {
			setIsSubmitLoading(false);
			setError(res.message);
		}
	};

	const getAssetName = () => {
		setFormAssetAssetName(
			allAssets.find((asset) => asset.AssetId === AssetId)?.AssetName ||
				"Unknown",
		);
	};

	useEffect(() => {
		if (FormAssetId) {
			handleCancel();
			reset();
			loadFilterAssets();
			setFormAssetAssetName(AssetId);
			getAssetName();
			setValue("AssetId", AssetId);
			setValue("SchedulePolicyCode", SchedulePolicyCode);
			setValue("Periods", Periods);
			setValue("StartDate", StartDate);
			setValue("FormId", FormId);
			setValue("FormAssetId", FormAssetId);
			setIsSubmitLoading(false);
		}
	}, []);

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid container>
					<DialogTitle
						style={{
							padding: "0 5px",
							marginRight: 20,
							width: "100%",
						}}
					>
						<Grid item md={12} xs={12}>
							<h1 style={{ fontSize: "1em" }}>Asset: {AssetName}</h1>
						</Grid>
					</DialogTitle>
					<DialogContent dividers style={{ padding: "10px 2px" }}>
						<Grid container>
							<Grid item md={4} xs={12} className={classes.inputEditable}>
								<Controller
									name="SchedulePolicyCode"
									control={control}
									render={({ field: { onChange, value } }) => (
										<ScheduleCodePicker
											value={value as string}
											onChange={onChange}
										/>
									)}
								/>
							</Grid>
							<Grid
								item
								md={2}
								xs={2}
								style={{ paddingLeft: 10 }}
								className={classes.inputEditable}
							>
								<Controller
									name="Periods"
									control={control}
									rules={{ required: true, pattern: /^[0-9]*$/ }}
									render={({
										field: { onChange, value },
										fieldState: { error },
									}) => (
										<TextField
											className={classes.root}
											style={{ margin: "8px 0" }}
											size="small"
											label="Periods"
											multiline
											variant="outlined"
											fullWidth
											autoComplete="off"
											value={value}
											onChange={(evt) => {
												const newValue = evt.target.value.replace(
													/[^0-9]/g,
													"",
												);
												if (newValue.length <= 99) onChange(newValue);
											}}
											error={!!error}
											helperText={error ? "Enter a period number" : ""}
										/>
									)}
								/>
							</Grid>
							<Grid
								container
								item
								md={6}
								xs={12}
								className={classes.inputEditable}
								style={{ paddingLeft: "22px" }}
							>
								<Controller
									name="StartDate"
									control={control}
									render={({ field: { value, onChange } }) => (
										<MuiPickersUtilsProvider utils={MomentUtils}>
											<Grid
												item
												md={6}
												xs={12}
												style={{ marginTop: "5px", paddingRight: 5 }}
											>
												<KeyboardDatePicker
													fullWidth
													disablePast
													disableToolbar
													variant="inline"
													format="MM/DD/yyyy"
													id="date-picker-inline"
													style={{
														minWidth: "100%",
														borderTopLeftRadius: 3,
														borderTopRightRadius: 3,
													}}
													label="Start"
													value={value}
													onChange={(evt: any) => onChange(evt?.toDate())}
													KeyboardButtonProps={{
														"aria-label": "change date",
													}}
													required={true}
													minDateMessage=""
												/>
											</Grid>
											<Grid
												item
												md={6}
												xs={12}
												style={{ marginTop: "5px", paddingLeft: 10 }}
											>
												<KeyboardTimePicker
													fullWidth
													variant="inline"
													id="time-picker"
													label="Time"
													value={value}
													onChange={(evt: any) => onChange(evt?.toDate())}
													required={true}
													KeyboardButtonProps={{
														"aria-label": "change time",
													}}
												/>
											</Grid>
										</MuiPickersUtilsProvider>
									)}
								/>
								<Grid
									container
									item
									style={{
										paddingLeft: 10,
										display: "flex",
										justifyContent: "flex-end",
										marginTop: 20,
									}}
								>
									<Grid item md={6} xs={4}>
										<Button
											fullWidth
											startIcon={
												isSubmitLoading && <CircularProgress size="1rem" />
											}
											disabled={!isValid || isSubmitLoading}
											variant="contained"
											color="primary"
											type="submit"
										>
											Save
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</DialogContent>
				</Grid>
			</form>
			<Snackbar open={addSuccess} autoHideDuration={2500} onClose={handleClose}>
				<Alert severity="info" onClose={handleClose}>
					Success
				</Alert>
			</Snackbar>
			<ErrorModal error={error} onHide={() => setError("")} />
		</>
	);
};
