import { ReactNode } from "react";
import FormsPage from "./configurations/forms";
import { MenuItem, MenuList } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";

type Component = {
	path: string;
	sidebar: () => ReactNode;
	main: () => ReactNode;
};

const components: Component[] = [
	{
		path: "forms",
		sidebar: () => <div>Forms</div>,
		main: () => <FormsPage />,
	},
];

const sidebar = () => {
	const { option } = useParams();

	return (
		<>
			<h3 className="mes-menu-group">Forms</h3>
			<MenuList className="mes-submenu">
				<MenuItem selected={option === "forms"} className="p-0">
					<Link to="/configuration/forms" className="nav-link">
						Form Settings
					</Link>
				</MenuItem>
			</MenuList>
		</>
	);
};

const customConfigurations: any = [components, sidebar];

export { customConfigurations };
