import { UserFormAssetData } from "./userFormAssetData";

export const INITIAL_VALUES_USER_FORM_ASSET: UserFormAssetData = {
	AssetId: null,
	AssetName: null,
	UserId: null,
	UserName: "",
	IsDefault: false,
	FormAssetId: null,
};
