import React, { useMemo } from "react";
import { useEffect, useState } from "react";
import {
	FormControl,
	FormControlLabel,
	IconButton,
	Radio,
	RadioGroup,
} from "@material-ui/core";
import { useFormsContext } from "../context/formsContext";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Popover from "@material-ui/core/Popover";
import CommentIcon from "@material-ui/icons/Comment";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	root: {
		"&:hover": {
			backgroundColor: "rgba(33, 150, 243, 0.39)",
			borderRadious: "2px",
			cursor: "pointer",
		},
	},
	optionLabel: {
		fontSize: "0.75rem",
		padding: "5px",
		borderRadius: "5px",
		overflow: "hidden",
		whiteSpace: "nowrap",
		display: "block",
	},
	radioGroup: {
		width: "100%",
		display: "flex",
		justifyContent: "flex-start",
		flexWrap: "nowrap",
		overflowX: "auto",
	},
	questionText: {
		fontSize: "0.85rem",
		whiteSpace: "normal",
		wordBreak: "break-word",
	},
	questionTextRequired: {
		fontWeight: "bold",
	},
	formControlLabel: {
		marginRight: "8px",
	},
	optionsContainer: {
		display: "flex",
		alignItems: "center",
		paddingRight: "16px",
	},
});

interface RowQuestion {
	question: [string, QuestionData[]];
}

interface QuestionData {
	QuestionId: number;
	RadioButtonSelected: number | null;
	Comments: string;
	IsRequired: boolean;
	QuestionText: string;
	OptionId: number;
	OptionText: string;
	OptionTypeCode: string;
	IsEnabled: boolean;
}

interface FormInstanceRow {
	QuestionId: number;
	OptionId: number;
	RadioButtonSelected: number;
	Comments: string;
}

export const RadioQuestion: React.FC<RowQuestion> = (rowQuestion) => {
	const [selectedOption, setSelectedOption] = useState<number | null>(null);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [comment, setComment] = useState<string>("");
	const classes = useStyles();
	const {
		state: { formInstanceRows, formInstanceModifiedRows, formInstanceStatus },
		actions: { setFormInstanceModifiedRows },
	} = useFormsContext();

	const handleOptionChange = (optionId: string) => {
		setSelectedOption(parseInt(optionId));
		const questionId = rowQuestion.question[1][0].QuestionId;
		const newOptionId = parseInt(optionId);
		const newModifiedRows = [...formInstanceModifiedRows];
		let comment: string | null = null;
		let newInstance = filterInstance(questionId, newOptionId);

		const existingIndex = formInstanceModifiedRows.findIndex(
			(instance) => instance.QuestionId === questionId,
		);

		if (existingIndex !== -1) {
			comment =
				formInstanceModifiedRows[existingIndex].Comments === ""
					? null
					: formInstanceModifiedRows[existingIndex].Comments;
			newModifiedRows.splice(existingIndex, 1);
		} else {
			comment = newInstance[0].Comments === "" ? null : newInstance[0].Comments;
		}

		newInstance = [
			{
				...newInstance[0],
				Comments: comment as string,
			},
		];
		setFormInstanceModifiedRows([...newModifiedRows, ...newInstance]);
	};

	const filterInstance = (
		questionId: number,
		optionId: number,
	): FormInstanceRow[] => {
		return formInstanceRows.filter(
			(row) => row.QuestionId === questionId && row.OptionId === optionId,
		);
	};

	useEffect(() => {
		setSelectedOption(rowQuestion.question[1][0].RadioButtonSelected);
	}, [rowQuestion.question[1]]);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const filterInstanceByQuestionId = (
		questionId: number,
	): FormInstanceRow[] => {
		return formInstanceRows.filter(
			(row) =>
				row.QuestionId === questionId &&
				row.OptionId === row.RadioButtonSelected,
		);
	};

	const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setComment(event.target.value);

		const questionId = rowQuestion.question[1][0].QuestionId;

		const newModifiedRows = [...formInstanceModifiedRows];

		const existingIndex = newModifiedRows.findIndex(
			(instance) => instance.QuestionId === questionId,
		);

		if (existingIndex !== -1) {
			newModifiedRows[existingIndex] = {
				...newModifiedRows[existingIndex],
				Comments: event.target.value,
			};
			setFormInstanceModifiedRows([...newModifiedRows]);
		} else {
			let newInstance = filterInstanceByQuestionId(questionId);
			newInstance = [
				{
					...newInstance[0],
					Comments: event.target.value,
				},
			];

			setFormInstanceModifiedRows([...newModifiedRows, ...newInstance]);
		}
	};

	const open = Boolean(anchorEl);
	const id = open ? "multiline-textfield-popover" : undefined;

	useEffect(() => {
		if (
			rowQuestion &&
			rowQuestion.question &&
			rowQuestion.question[1] &&
			rowQuestion.question[1][0] &&
			rowQuestion.question[1][0].Comments
		) {
			setComment(rowQuestion.question[1][0].Comments);
		} else {
			setComment("");
		}
	}, [rowQuestion.question[1][0].Comments]);

	const getOptionColor = (optionTypeCode: string) => {
		switch (optionTypeCode) {
			case "W":
				return "#FF7F50";
			case "A":
				return "#FD2D00";
			default:
				return "initial";
		}
	};

	const sortedOptions = useMemo(() => {
		const getOptionOrder = (optionText: string) => {
			switch (optionText) {
				case "OK":
					return 1;
				case "Action Req.":
					return 2;
				case "Repaired":
					return 3;
				default:
					return 4;
			}
		};

		return [...rowQuestion.question[1]].sort(
			(a, b) => getOptionOrder(a.OptionText) - getOptionOrder(b.OptionText),
		);
	}, [rowQuestion.question[1]]);
	return (
		<Grid container className={classes.root} alignItems="center">
			<Grid item xs={12} md={3} style={{ paddingRight: "10px" }}>
				<label
					className={`${classes.questionText} ${
						rowQuestion.question[1][0].IsRequired
							? classes.questionTextRequired
							: ""
					}`}
				>
					{`${rowQuestion.question[1][0].QuestionText} ${
						rowQuestion.question[1][0].IsRequired ? "(*)" : ""
					}`}
				</label>
			</Grid>
			<Grid item xs={12} md={9}>
				<FormControl component="fieldset" fullWidth>
					<RadioGroup
						row
						aria-labelledby="demo-row-radio-buttons-group-label"
						name={`${rowQuestion.question[1][0].QuestionId}-row-radio-buttons-group`}
						value={selectedOption}
						onChange={(event) => handleOptionChange(event.target.value)}
						className={classes.radioGroup}
					>
						{sortedOptions.map((q: QuestionData) => (
							<FormControlLabel
								key={`${q.QuestionId}-${q.OptionId}`}
								value={q.OptionId}
								control={
									<Radio
										size="small"
										color={q.OptionTypeCode !== "O" ? "secondary" : "primary"}
									/>
								}
								label={
									<span
										className={classes.optionLabel}
										style={{
											backgroundColor: getOptionColor(q.OptionTypeCode),
											color: ["W", "A"].includes(q.OptionTypeCode)
												? "#FFF"
												: "initial",
										}}
									>
										{q.OptionText}
									</span>
								}
								disabled={!q.IsEnabled || formInstanceStatus !== "O"}
							/>
						))}
						<IconButton
							disabled={!selectedOption}
							aria-describedby={id}
							onClick={handleClick}
						>
							<CommentIcon
								style={comment ? { color: "green" } : { color: "lightgrey" }}
							/>
						</IconButton>
					</RadioGroup>
				</FormControl>
			</Grid>
			<Popover
				id={id}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				<TextField
					disabled={formInstanceStatus !== "O"}
					multiline
					minRows={6}
					variant="outlined"
					fullWidth
					placeholder="Enter the comments here"
					onChange={handleCommentChange}
					value={comment}
					style={{ width: "500px" }}
				/>
			</Popover>
		</Grid>
	);
};
