import { useEffect, useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { useFormsContext } from "../context/formsContext";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .MuiSelect-root": {
			backgroundColor: "#FFF",
		},
	},
	inputLabel: {
		position: "absolute",
		top: -6,
		left: 8,
		fontSize: 11.3,
		backgroundColor: "#FFF",
		color: "#0000008A",
		width: "auto",
		height: "auto",
		zIndex: 100,
		padding: "0 3px",
		borderRadius: 3,
		fontWeight: 400,
		lineHeight: "1.1876em",
		letterSpacing: "0.04208em",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
	},
}));
interface Props {
	selectedValue: string | undefined;
}

export const ShiftSelectorModal = () => {
	const {
		state: { newInstanceShifts },
		actions: { setNewInstanceShift },
	} = useFormsContext();
	const [selectedValue, setSelectedValue] = useState<string>("");

	const classes = useStyles();
	const handleSelectChange = (event: any) => {
		const selected = event.target.value as string;
		setSelectedValue(selected);
		setNewInstanceShift(selected);
	};

	useEffect(() => {
		if (newInstanceShifts[0] && newInstanceShifts[0].Shift) {
			setSelectedValue(newInstanceShifts[0].Shift);
			setNewInstanceShift(newInstanceShifts[0].Shift);
		}
	}, [newInstanceShifts]);

	return (
		<div className={classes.root} style={{ position: "relative" }}>
			<p className={classes.inputLabel}>Shift</p>
			<Select
				variant="outlined"
				margin="dense"
				labelId="demo-simple-select-label"
				id="demo-simple-select"
				value={selectedValue}
				onChange={handleSelectChange}
				style={{ minWidth: "100%" }}
			>
				{newInstanceShifts?.map((shift: any, index) => (
					<MenuItem key={`${shift.Shift} - ${index}`} value={shift.Shift}>
						{shift.Shift}
					</MenuItem>
				))}
			</Select>
		</div>
	);
};
