import { get } from "react-hook-form";
import { createFormInstance } from "../repositories/FormsRepository";
import { useFormsContext } from "../context/formsContext";

export const useCreateNewInstance = () => {
	const {
		state: {
			newInstanceAssetId,
			newInstanceShifts,
			newInstanceFormId,
			newInstanceShift,
		},
		actions: { setNewInstanceId, setSelectedInstanceId, setApiMessage },
	} = useFormsContext();

	return async () => {
		const shiftId = newInstanceShifts.find(
			(shift) => shift.Shift === newInstanceShift,
		)?.ShiftId;
		return createFormInstance(
			parseInt(newInstanceFormId as string),
			parseInt(newInstanceAssetId as string),
			parseInt(shiftId as string),
		).then((res) => {
			if (res.ok) {
				const instanceRows = get(res, "data", null);
				const instanceMessage = get(res, "message", null);
				const newInstanceId = instanceRows?.tables[0]?.rows[0]?.FormInstanceId
					? instanceRows.tables[0].rows[0].FormInstanceId
					: undefined;

				if (newInstanceId) {
					setNewInstanceId(newInstanceId);
					setSelectedInstanceId(newInstanceId);
					setApiMessage(null);
				}
				{
					setApiMessage(instanceMessage);
					setNewInstanceId(null);
				}
			}
			return res;
		});
	};
};
