import { get } from "react-hook-form";
import { getSectionsByFormId } from "../repositories/FormsRepository";
import { useFormsContext } from "../context/FormsContext";
type Props = {
	setIsSectionsLoading: (isLoading: boolean) => void;
	setSectionsError: (err: string) => void;
};

export const useSearchFormsSections = ({
	setIsSectionsLoading,
	setSectionsError,
}: Props) => {
	const {
		state: { FormId, searchSectionsText },
		actions: { setFormSectionsList },
	} = useFormsContext();

	return async () => {
		setFormSectionsList([]);
		setIsSectionsLoading(true);

		const res = await getSectionsByFormId(FormId as number, searchSectionsText);
		if (res.ok) {
			const rowsSource = get(res, "data.tables[0].rows", []);
			if (rowsSource.length) {
				setFormSectionsList(rowsSource);
			} else {
				setFormSectionsList([]);
			}
			setIsSectionsLoading(false);
		} else {
			setSectionsError(res.message);
			setIsSectionsLoading(false);
		}
	};
};
