import { FormLabel, Grid } from "@material-ui/core";

import { RadioQuestion } from "./RadioQuestion";
import { Paper } from "@material-ui/core";
import { useEffect, useState } from "react";
import { LazyLoading } from "@/components/loaders/Loader/LazyLoading";

type Props = {
	formInstanceSections?: any[] | null;
	formInstanceQuestions?: any[];
	layoutCode?: any;
};

export const Questions = ({
	formInstanceSections,
	formInstanceQuestions,
	layoutCode,
}: Props) => {
	const [mounted, setMounted] = useState(false);
	useEffect(() => {
		setMounted(true);
	}, []);

	if (formInstanceSections?.length === 0 || !mounted)
		return (
			<>
				<div style={{ display: "flex", justifyContent: "center" }}>
					<LazyLoading />
				</div>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						fontSize: "20px",
					}}
				>
					Loading...
				</div>
			</>
		);

	return (
		<>
			{layoutCode === "D" &&
				formInstanceSections &&
				Object.entries(formInstanceSections).map((section, index) => {
					return (
						<div key={`${section[1][0].SectionName}`}>
							<Paper
								elevation={1}
								style={{
									width: "100%",
									padding: "10px 0px 10px 10px",
									borderRadius: "0.1rem",
									borderBottom: "unset",
									backgroundColor: section[1][0].BackgroundColor,
								}}
							>
								<FormLabel
									id="demo-row-radio-buttons-group-label"
									className="Mui-focused"
									style={{
										display: "flex",
										justifyContent: "center",
										fontSize: "16px",
										textTransform: "uppercase",
									}}
								>
									{section[1][0].SectionName}
								</FormLabel>
								<FormLabel
									id="demo-row-radio-buttons-group-label"
									className="Mui-focused"
									style={{
										display: "flex",
										justifyContent: "center",
										fontSize: "14px",
										textTransform: "uppercase",
										color: "#505050",
									}}
								>
									{section[1][0].SubTitle}
								</FormLabel>

								<Grid container spacing={5} style={{ padding: "10px" }}>
									<Grid item md={6} xs={6}>
										{formInstanceQuestions &&
											Object.entries(formInstanceQuestions)
												.slice(0, 9)
												.map((question, index) => {
													// if (index >= 9) return;
													if (
														question[1][0].SectionId === section[1][0].SectionId
													) {
														if (question[1][0].QuestionTypeCode === "R") {
															return (
																<RadioQuestion
																	key={question[1][0].QuestionId}
																	question={question}
																/>
															);
														}
													}
												})}
									</Grid>
									<Grid item md={6} xs={6}>
										{formInstanceQuestions &&
											Object.entries(formInstanceQuestions)
												.slice(9, 17)
												.map((question, index) => {
													// if (index < 9 ) return;
													if (
														question[1][0].SectionId === section[1][0].SectionId
													) {
														if (question[1][0].QuestionTypeCode === "R") {
															return (
																<RadioQuestion
																	key={question[1][0].QuestionId}
																	question={question}
																	// BackgroundColor={
																	// 	section[1][0].BackgroundColor
																	// }
																/>
															);
														}
													}
												})}
									</Grid>
								</Grid>
							</Paper>
							{index < Object.entries(formInstanceSections).length - 1 && (
								<hr />
							)}
						</div>
					);
				})}

			{layoutCode === "T" && (
				<Grid container spacing={1}>
					{/* First Column */}

					<Grid item lg={4} md={6} xs={12}>
						{formInstanceSections &&
							Object.entries(formInstanceSections)
								.filter(
									([, sectionDetails]) =>
										sectionDetails[0].LayoutParameters === "Column=1",
								)
								.map((section) => (
									<Paper
										elevation={1}
										style={{
											width: "100%",
											padding: "10px 0px 10px 10px",
											marginBottom: "10px",
											borderRadius: "0.1rem",
											backgroundColor: section[1][0].BackgroundColor,
										}}
									>
										<div key={section[1][0].SectionName}>
											<FormLabel
												id="demo-row-radio-buttons-group-label"
												className="Mui-focused"
												style={{
													display: "flex",
													justifyContent: "center",
													fontSize: "16px",
													textTransform: "uppercase",
													paddingBottom: "-10px",
												}}
											>
												{section[1][0].SectionName}
											</FormLabel>
											<FormLabel
												id="demo-row-radio-buttons-group-label"
												className="Mui-focused"
												style={{
													display: "flex",
													justifyContent: "center",
													fontSize: "14px",
													textTransform: "uppercase",
													color: "#505050",
												}}
											>
												{section[1][0].SubTitle}
											</FormLabel>
											{formInstanceQuestions &&
												Object.entries(formInstanceQuestions).map(
													(question, index) => {
														if (
															question[1][0].SectionId ===
																section[1][0].SectionId &&
															question[1][0].QuestionTypeCode === "R"
														) {
															return (
																<RadioQuestion
																	key={question[1][0].QuestionId}
																	question={question}
																/>
															);
														}
														return null;
													},
												)}
										</div>
									</Paper>
								))}
					</Grid>

					{/* Second Column */}
					<Grid item lg={4} md={6} xs={12}>
						{formInstanceSections &&
							Object.entries(formInstanceSections)
								.filter(
									([, sectionDetails]) =>
										sectionDetails[0].LayoutParameters === "Column=2",
								)
								.map((section) => (
									<Paper
										elevation={1}
										style={{
											width: "100%",
											padding: "10px 0px 10px 10px",
											marginBottom: "10px",
											borderRadius: "0.1rem",
											backgroundColor: section[1][0].BackgroundColor,
										}}
									>
										<div key={section[1][0].SectionName}>
											<FormLabel
												id="demo-row-radio-buttons-group-label"
												className="Mui-focused"
												style={{
													display: "flex",
													justifyContent: "center",
													fontSize: "16px",
													textTransform: "uppercase",
													paddingBottom: "-10px",
												}}
											>
												{section[1][0].SectionName}
											</FormLabel>
											<FormLabel
												id="demo-row-radio-buttons-group-label"
												className="Mui-focused"
												style={{
													display: "flex",
													justifyContent: "center",
													fontSize: "14px",
													textTransform: "uppercase",
													color: "#505050",
												}}
											>
												{section[1][0].SubTitle}
											</FormLabel>

											{formInstanceQuestions &&
												Object.entries(formInstanceQuestions)
													.sort(
														(a, b) =>
															b[1][0].OptionSortOrder - a[1][0].OptionSortOrder,
													)
													.map((question) => {
														if (
															question[1][0].SectionId ===
																section[1][0].SectionId &&
															question[1][0].QuestionTypeCode === "R"
														) {
															return (
																<RadioQuestion
																	key={question[1][0].QuestionId}
																	question={question}
																/>
															);
														}
														return null;
													})}
										</div>
									</Paper>
								))}
					</Grid>

					{/* Third Column */}
					<Grid item lg={4} md={6} xs={12}>
						{formInstanceSections &&
							Object.entries(formInstanceSections)
								.filter(
									([, sectionDetails]) =>
										sectionDetails[0].LayoutParameters === "Column=3",
								)
								.map((section) => (
									<Paper
										elevation={1}
										style={{
											width: "100%",
											padding: "10px 0px 10px 10px",
											marginBottom: "10px",
											borderRadius: "0.1rem",
											backgroundColor: section[1][0].BackgroundColor,
										}}
									>
										<div key={section[1][0].SectionName}>
											<FormLabel
												id="demo-row-radio-buttons-group-label"
												className="Mui-focused"
												style={{
													display: "flex",
													justifyContent: "center",
													fontSize: "16px",
													textTransform: "uppercase",
													paddingBottom: "-10px",
												}}
											>
												{section[1][0].SectionName}
											</FormLabel>
											<FormLabel
												id="demo-row-radio-buttons-group-label"
												className="Mui-focused"
												style={{
													display: "flex",
													justifyContent: "center",
													fontSize: "14px",
													textTransform: "uppercase",
													color: "#505050",
												}}
											>
												{section[1][0].SubTitle}
											</FormLabel>

											{formInstanceQuestions &&
												Object.entries(formInstanceQuestions).map(
													(question) => {
														if (
															question[1][0].SectionId ===
																section[1][0].SectionId &&
															question[1][0].QuestionTypeCode === "R"
														) {
															return (
																<RadioQuestion
																	key={question[1][0].QuestionId}
																	question={question}
																/>
															);
														}
														return null;
													},
												)}
										</div>
									</Paper>
								))}
					</Grid>
				</Grid>
			)}
		</>
	);
};
