import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import React, { useState } from "react";
import { tagColors } from "../utils/sectionColors";
import { useTagColorsStyles } from "../styles/sectionColorsStyles";

interface SwatchProps {
	color: string;
	onSelect: (color: string) => void;
	onClose: () => void;
}

const Swatch = ({ color, onSelect, onClose }: SwatchProps) => {
	const classes = useTagColorsStyles();
	const handleClick = () => {
		onSelect(color);
		onClose();
	};

	return (
		<div
			className={classes.swatch}
			style={{ backgroundColor: color }}
			onClick={handleClick}
		/>
	);
};

interface Props {
	onChange: (color: string) => void;
	color: string;
}

export const ColorPicker = ({ onChange, color }: Props) => {
	const classes = useTagColorsStyles();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	return (
		<div>
			<Button
				aria-describedby={id}
				variant="outlined"
				onClick={handleClick}
				style={{
					backgroundColor: color,
					width: "100%",
					height: "38px",
					minWidth: "100%",
					paddingLeft: 50,
					marginBottom: 1,
				}}
			/>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<div className={classes.popover}>
					{tagColors.map((color) => (
						<Swatch
							key={color}
							color={color}
							onSelect={onChange}
							onClose={handleClose}
						/>
					))}
				</div>
			</Popover>
		</div>
	);
};
