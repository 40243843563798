import {
	cloneForm,
	cloneQuestionAndOptions,
	cloneSection,
} from "../repositories/FormsRepository";

export const useClone = () => {
	const CloneQuestionAndOptions = async ({
		QuestionId,
		searchQuestions,
	}: { QuestionId: number | null; searchQuestions: Function }) => {
		const res = await cloneQuestionAndOptions(QuestionId);
		if (res.ok) {
			searchQuestions();
		} else {
			// setCloneError(res.message);
		}
	};

	const CloneSection = async ({
		SectionId,
		searchFormSections,
		setShouldUpdateWhenExit,
	}: {
		SectionId: number | null;
		searchFormSections: Function;
		setShouldUpdateWhenExit: Function;
	}) => {
		const res = await cloneSection(SectionId);
		if (res.ok) {
			searchFormSections();
			setShouldUpdateWhenExit(true);
		} else {
			// setCloneError(res.message);
		}
	};

	const CloneForm = async ({
		FormId,
		searchForms,
	}: { FormId: number | null; searchForms: Function }) => {
		const res = await cloneForm(FormId);
		if (res.ok) {
			searchForms();
		} else {
			// setCloneError(res.message);
		}
	};

	return { CloneQuestionAndOptions, CloneSection, CloneForm };
};
