import { get } from "react-hook-form";
import { getQuestionsBySectionId } from "../repositories/FormsRepository";
import { useFormsContext } from "../context/FormsContext";

type Props = {
	setIsLoading: (isLoading: boolean) => void;
	setError: (err: string) => void;
};

export const useSearchQuestions = ({ setIsLoading, setError }: Props) => {
	const {
		state: { SectionId },
		actions: { setQuestionList },
	} = useFormsContext();

	return async () => {
		// setFormsList([]);
		setIsLoading(true);

		const res = await getQuestionsBySectionId(SectionId as number);
		if (res.ok) {
			const rowsSource = get(res, "data.tables[0].rows", []);
			if (rowsSource.length) {
				setQuestionList(rowsSource);
			} else {
				setQuestionList([]);
			}
			setIsLoading(false);
		} else {
			setError(res.message);
			setIsLoading(false);
		}
	};
};
