import { ErrorModal } from "@dexteel/mesf-core";
import {
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	DialogTitle,
	FormControlLabel,
	TextField,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { SyntheticEvent, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Form } from "../../../models/Form";
import { useFormsContext } from "../../../context/FormsContext";
import { INITIAL_VALUES_FORM } from "../../../models/FormInitialValues";
import { upsertForm } from "../../../repositories/FormsRepository";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .input-group-text": {
			display: "none",
		},
		"& .MuiDialogContent-dividers": {
			padding: 0,
		},
	},
	assetPicker: {
		"& .MuiGrid-item": {
			width: "500px",
		},
	},

	checkbox: {
		userSelect: "none",
	},
	modalTitle: {
		backgroundColor: "#ced2cc",
		color: "#495057",
		textAlign: "center",
	},
	modalWarning: {
		color: "white",
		backgroundColor: "#dc3545",
		textAlign: "center",
	},
	messageTitle: {
		fontSize: 15,
		fontWeight: "bold",
		color: "#0000008A",
	},
	inputEditable: {
		"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			border: "2px solid #3F51B5",
		},
	},
	comments: {
		"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			border: "1px solid #3F51B5",
		},
	},
}));

type Props = {
	show: boolean;
	onHide: (shouldUpdate: boolean) => void;
	data: Form | null;
	newMode?: boolean;
};

export const NewAndEditFormModal = ({
	show,
	onHide,
	data,
	newMode = false,
}: Props) => {
	const classes = useStyles();
	const {
		state: { formSelectedInTable, FormId },
		actions: {
			setFormNameFormSelectedInTable,
			setIsEnabledFormSelectedInTable,
			setIncludesCommentFormSelectedInTable,
			setFormId,
		},
	} = useFormsContext();

	const [editedSuccess, setEditedSuccess] = useState(false);
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);

	const [error, setError] = useState<string>("");
	const [deleteByIdError, setDeleteByIdError] = useState<string>("");
	const [errorAssetPath, setErrorAssetPath] = useState<string>("");

	const {
		control,
		handleSubmit,
		watch,
		setValue,
		formState: { errors },
		reset,
	} = useForm<Form>({ defaultValues: INITIAL_VALUES_FORM });

	//states
	const [fullWidth, setFullWidth] = useState(true);
	const [maxWidth, setMaxWidth] = useState<DialogProps["maxWidth"]>("sm");

	const handleClose = (event?: SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setEditedSuccess(false);
	};
	const handleCancel = async () => {
		onHide(false);
	};

	const formName = watch("FormName");

	const changeFormName = () => {
		setFormNameFormSelectedInTable(formName);
	};

	const isEnabled = watch("IsEnabled");

	const changeIsEnabled = () => {
		setIsEnabledFormSelectedInTable(isEnabled);
	};
	const includesComment = watch("IncludesComment");

	const changeIncludesComment = () => {
		setIncludesCommentFormSelectedInTable(includesComment);
	};

	const onSubmit = async (formData: Form) => {
		setIsSubmitLoading(true);
		const res = await upsertForm(
			FormId,
			formData?.FormName,
			formData?.IsEnabled,
			formData?.LayoutCode,
			formData?.IncludesComment,
		);
		if (res.ok) {
			reset(INITIAL_VALUES_FORM);
			setIsSubmitLoading(false);
			setEditedSuccess(true);
			setFormId(null);
			onHide(true);
		} else {
			setIsSubmitLoading(false);
			setError(res.message);
		}
	};

	useEffect(() => {
		if (data !== null && show && !newMode) {
			setFormNameFormSelectedInTable(data.FormName);
			setIsEnabledFormSelectedInTable(data.IsEnabled);
			setIncludesCommentFormSelectedInTable(data.IncludesComment);
			setValue("FormName", data.FormName);
			setValue("IsEnabled", data.IsEnabled);
			setValue("IncludesComment", data.IncludesComment);
			setIsSubmitLoading(false);
		} else {
			setFormId(null);
			reset(INITIAL_VALUES_FORM);
		}
	}, [show]);

	useEffect(() => {
		changeFormName();
	}, [formName]);

	useEffect(() => {
		changeIsEnabled();
	}, [isEnabled]);

	useEffect(() => {
		changeIncludesComment();
	}, [includesComment]);

	return (
		<>
			<Dialog
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				open={show}
				onClose={() => handleCancel()}
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container style={{ padding: "15px 0 20px" }}>
						<DialogTitle
							style={{
								padding: "5px 40px 10px 24px",
								marginRight: 20,
								width: "100%",
							}}
						>
							<Grid item md={12} xs={12}>
								<h1 style={{ fontSize: "1.7em" }}>
									{newMode ? `FORM: ${formName}` : `EDIT: ${data?.FormName}`}
								</h1>
							</Grid>
						</DialogTitle>
						<Grid container>
							<DialogContent dividers>
								<Grid container>
									<Grid
										item
										md={12}
										xs={12}
										style={{ padding: 0, marginTop: "-5px" }}
									>
										<Controller
											name="FormName"
											control={control}
											rules={{
												validate: (value) => value !== "",
											}}
											render={({ field: { onChange, value } }) => (
												<TextField
													label="Form Name"
													variant="outlined"
													fullWidth
													error={!!errors.FormName}
													margin="dense"
													autoComplete="off"
													value={value}
													onChange={(e) => onChange(e)}
													className={classes.inputEditable}
												/>
											)}
										/>
									</Grid>
									<Grid
										container
										item
										md={12}
										xs={12}
										style={{
											justifyContent: "flex-end",
											alignItems: "flex-end",
											marginTop: "20px",
											paddingLeft: "10px",
										}}
									>
										<Grid item md={6} xs={6}>
											<Controller
												name="IsEnabled"
												control={control}
												defaultValue={formSelectedInTable?.IsEnabled}
												render={({ field }) => (
													<FormControlLabel
														className={classes.checkbox}
														control={
															<Checkbox
																checked={field.value as boolean}
																onChange={(e) =>
																	field.onChange(e.target.checked)
																}
																name="IsEnabled"
																color="primary"
															/>
														}
														label="IsEnabled"
													/>
												)}
											/>
										</Grid>
										<Grid item md={6} xs={6}>
											<Controller
												name="IncludesComment"
												control={control}
												render={({ field }) => (
													<FormControlLabel
														className={classes.checkbox}
														control={
															<Checkbox
																checked={field.value as boolean}
																onChange={(e) =>
																	field.onChange(e.target.checked)
																}
																name="IncludesComment"
																color="primary"
															/>
														}
														label="Includes Comment"
													/>
												)}
											/>
										</Grid>
									</Grid>
								</Grid>
							</DialogContent>
							<Grid
								item
								md={12}
								xs={12}
								justifyContent="flex-end"
								style={{ marginTop: "15px" }}
							>
								<DialogActions style={{ padding: "0px 24px" }}>
									<Grid
										container
										justifyContent="flex-end"
										spacing={2}
										style={{ margin: 0 }}
									>
										<Grid item md={3} xs={4}>
											<Button
												fullWidth
												variant="contained"
												color="default"
												onClick={handleCancel}
											>
												Cancel
											</Button>
										</Grid>
										<Grid item md={3} xs={4} style={{ paddingRight: 0 }}>
											<Button
												fullWidth
												startIcon={
													isSubmitLoading && <CircularProgress size="1rem" />
												}
												disabled={isSubmitLoading}
												variant="contained"
												color="primary"
												type="submit"
											>
												Save
											</Button>
										</Grid>
									</Grid>
								</DialogActions>
							</Grid>
						</Grid>
					</Grid>
				</form>
			</Dialog>
			<Snackbar
				open={editedSuccess}
				autoHideDuration={2500}
				onClose={handleClose}
			>
				<Alert severity="info" onClose={handleClose}>
					Success
				</Alert>
			</Snackbar>
			<ErrorModal error={error} onHide={() => setError("")} />
			<ErrorModal
				error={deleteByIdError}
				onHide={() => setDeleteByIdError("")}
			/>
			<ErrorModal error={errorAssetPath} onHide={() => setErrorAssetPath("")} />
		</>
	);
};
