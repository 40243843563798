import React, { useEffect, useState } from "react"; // Import useState from React
import MomentUtils from "@date-io/moment";
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment"; // Import moment library
import { useFormsContext } from "../context/formsContext";

type Props = {};

export const ProductionDateSelectorModal = ({}: Props) => {
	const {
		state: {},
		actions: { setNewInstanceProductionDate },
	} = useFormsContext();

	const [productionDate, setProductionDate] = useState(
		moment().format("YYYY-MM-DD"),
	); // Initialize productionDate state with moment()

	const handleDateChange = (datePicker: any) => {
		setProductionDate(datePicker);
		setNewInstanceProductionDate(datePicker.format("YYYY-MM-DD"));
	};

	useEffect(() => {
		setProductionDate(moment().format("YYYY-MM-DD"));
	}, []);

	return (
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<KeyboardDatePicker
				disableToolbar
				variant="inline"
				format="MM/DD/yyyy"
				id="date-picker-inline"
				style={{
					minWidth: "100%",
					borderTopLeftRadius: 3,
					borderTopRightRadius: 3,
				}}
				label="Datetime"
				value={productionDate}
				onChange={handleDateChange}
				KeyboardButtonProps={{
					"aria-label": "change date",
				}}
			/>
		</MuiPickersUtilsProvider>
	);
};
