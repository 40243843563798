import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	DialogTitle,
	Grid,
	Snackbar,
	TextField,
	makeStyles,
} from "@material-ui/core";
import { SyntheticEvent, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Alert } from "@material-ui/lab";
import { useFormsContext } from "../../../../context/FormsContext";
import {
	ErrorModal,
	TreePickerControl,
	useAssetContext,
} from "@dexteel/mesf-core";
import { Asset } from "@dexteel/mesf-core/dist/models/Asset";
import { TransformAssetCodeModel } from "@/utils/transforms";
import { FormAsset } from "../../../../models/FormAsset";
import { upsertFormAsset } from "src/setup/configurations/forms/repositories/FormsRepository";
import { ScheduleCodePicker } from "../picker/ScheduleCodePicker";
import { INITIAL_VALUES_FORM_ASSET } from "src/setup/configurations/forms/models/FormAssetInitialValue";
import {
	KeyboardDatePicker,
	KeyboardTimePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .input-group-text": {
			display: "none",
		},
		"& .MuiDialogContent-dividers": {
			padding: 0,
		},
	},
	checkbox: {
		userSelect: "none",
	},
	assetPicker: {
		"& .MuiGrid-item": {
			width: "500px",
		},
	},
	modalTitle: {
		backgroundColor: "#ced2cc",
		color: "#495057",
		textAlign: "center",
	},
	modalWarning: {
		color: "white",
		backgroundColor: "#dc3545",
		textAlign: "center",
	},
	messageTitle: {
		fontSize: 15,
		fontWeight: "bold",
		color: "#0000008A",
	},
	inputEditable: {
		"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			border: "2px solid #3F51B5",
		},
	},
	comments: {
		"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			border: "1px solid #3F51B5",
		},
	},
}));

type Props = {
	show: boolean;
	onHide: (shouldUpdate: boolean) => void;
};

export const NewFormAssetModal = ({ show, onHide }: Props) => {
	const classes = useStyles();
	const [addSuccess, setAddSuccess] = useState(false);
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [error, setError] = useState<string>("");
	const {
		state: {
			assetCodes,
			FormName,
			FormId,
			userFormAssetData: { AssetId, AssetName },
		},
		actions: {
			setAssetCodes,
			setAssetId,
			setAssetName,
			setFormAssetFormAssetId,
			setFormAssetAssetId,
		},
	} = useFormsContext();

	const {
		state: { allAssets },
	} = useAssetContext();

	const {
		control,
		handleSubmit,
		setValue,
		formState: { isValid },
		reset,
	} = useForm<FormAsset>({
		defaultValues: INITIAL_VALUES_FORM_ASSET,
	});

	//states
	const [fullWidth, setFullWidth] = useState(true);
	const [maxWidth, setMaxWidth] = useState<DialogProps["maxWidth"]>("xs");

	const handleCancel = async () => {
		reset();
		setAssetId(1);
		setAssetName("Plant");
		onHide(false);
	};

	const buildTreeAssets = (nodes: any) => {
		if (nodes === undefined) return;
		//tree build functions
		const idMapping = nodes.reduce((acc: any, el: any, i: number) => {
			acc[el.id] = i;
			return acc;
		}, {});
		let root;
		nodes.forEach((el: any) => {
			if (el.parentId === null) {
				root = el;
				return;
			}
			const parentEl = nodes[idMapping[el.parentId]];
			if (parentEl) {
				parentEl.children = [...(parentEl?.children || []), el];
			}
		});
		setAssetCodes(root as any);
	};

	const loadFilterAssets = () => {
		let mydataSource: any[] = [];
		allAssets.forEach((row: Asset) => {
			const code = TransformAssetCodeModel(row);
			mydataSource = [...mydataSource, code];
		});
		buildTreeAssets(mydataSource);
	};

	const changeSearchAsset = (value: any, description: any) => {
		setValue("AssetId", parseInt(value));
		setAssetId(parseInt(value));
		setFormAssetAssetId(parseInt(value));
		setAssetName(description);
	};

	const handleClose = (event?: SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setAddSuccess(false);
	};

	const onSubmit = async (data: FormAsset) => {
		setIsSubmitLoading(true);
		const res = await upsertFormAsset(data);
		if (res.ok) {
			setFormAssetFormAssetId(null);
			setIsSubmitLoading(false);
			setAddSuccess(true);
			reset();
			onHide(true);
		} else {
			setIsSubmitLoading(false);
			setError(res.message);
		}
	};

	useEffect(() => {
		if (show) {
			reset();
			setAssetId(1);
			setAssetName("Plant");
			setValue("AssetId", AssetId);
			setValue("FormId", FormId);
			setValue("StartDate", new Date());
			setIsSubmitLoading(false);
			loadFilterAssets();
		}
	}, [show]);

	return (
		<>
			<Dialog
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				open={show}
				onClose={() => handleCancel()}
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container style={{ padding: "15px 0 20px" }}>
						<DialogTitle
							style={{
								padding: "5px 40px 10px 24px",
								marginRight: 20,
								width: "100%",
							}}
						>
							<Grid item md={12} xs={12}>
								<h1 style={{ fontSize: "1em" }}>FORM: {FormName}</h1>
							</Grid>
						</DialogTitle>
						<Grid container item>
							<DialogContent dividers>
								<Grid container>
									<Grid
										item
										md={12}
										xs={12}
										style={{ paddingLeft: 0 }}
										className={classes.inputEditable}
									>
										<Controller
											name="AssetId"
											control={control}
											render={({}) => (
												<TreePickerControl
													className={classes.assetPicker}
													inputTitle="Asset"
													title="Choose Asset"
													dataSource={assetCodes}
													value={AssetId}
													description={AssetName}
													onSelect={changeSearchAsset}
													selectBranch={true}
													selectActive={false}
													selectInternal={false}
												></TreePickerControl>
											)}
										/>
									</Grid>
									<Grid
										item
										md={6}
										xs={12}
										style={{ paddingLeft: 0, marginTop: 10 }}
										className={classes.inputEditable}
									>
										<Controller
											name="SchedulePolicyCode"
											control={control}
											render={({ field: { onChange, value } }) => (
												<ScheduleCodePicker
													value={value as string}
													onChange={onChange}
												/>
											)}
										/>
									</Grid>
									<Grid
										item
										md={6}
										xs={12}
										style={{ paddingLeft: 10, marginTop: 10 }}
										className={classes.inputEditable}
									>
										<Controller
											name="Periods"
											control={control}
											rules={{ required: true, pattern: /^[0-9]*$/ }}
											render={({
												field: { onChange, value },
												fieldState: { error },
											}) => (
												<TextField
													className={classes.root}
													style={{ margin: "8px 0" }}
													size="small"
													// maxRows={5}
													label="Periods"
													multiline
													variant="outlined"
													fullWidth
													autoComplete="off"
													value={value}
													onChange={(evt) => {
														const newValue = evt.target.value.replace(
															/[^0-9]/g,
															"",
														);
														if (newValue.length <= 99) onChange(newValue);
													}}
													error={!!error}
													helperText={error ? "Enter a period number" : ""}
												/>
											)}
										/>
									</Grid>
									<Grid
										container
										item
										md={12}
										xs={12}
										className={classes.inputEditable}
										style={{ padding: "0 2px" }}
									>
										<Controller
											name="StartDate"
											control={control}
											render={({ field: { value, onChange } }) => (
												<MuiPickersUtilsProvider utils={MomentUtils}>
													<Grid
														item
														md={6}
														xs={12}
														style={{ margin: "8px 0 0", paddingRight: 5 }}
													>
														<KeyboardDatePicker
															fullWidth
															disablePast
															disableToolbar
															variant="inline"
															format="MM/DD/yyyy"
															id="date-picker-inline"
															style={{
																minWidth: "100%",
																borderTopLeftRadius: 3,
																borderTopRightRadius: 3,
															}}
															label="Start"
															value={value}
															onChange={(evt: any) => onChange(evt?.toDate())}
															KeyboardButtonProps={{
																"aria-label": "change date",
															}}
														/>
													</Grid>
													<Grid
														item
														md={6}
														xs={12}
														style={{ margin: "8px 0 18px", paddingLeft: 5 }}
													>
														<KeyboardTimePicker
															fullWidth
															variant="inline"
															id="time-picker"
															label="Time"
															value={value}
															onChange={(evt: any) => onChange(evt?.toDate())}
															KeyboardButtonProps={{
																"aria-label": "change time",
															}}
														/>
													</Grid>
												</MuiPickersUtilsProvider>
											)}
										/>
									</Grid>
								</Grid>
							</DialogContent>
							<Grid
								item
								md={12}
								xs={12}
								justifyContent="flex-end"
								style={{ marginTop: "15px" }}
							>
								<DialogActions style={{ padding: "0px 24px" }}>
									<Grid
										container
										justifyContent="flex-end"
										spacing={2}
										style={{ margin: 0 }}
									>
										<Grid item md={3} xs={4}>
											<Button
												fullWidth
												variant="contained"
												color="default"
												onClick={handleCancel}
											>
												Cancel
											</Button>
										</Grid>
										<Grid item md={3} xs={4} style={{ paddingRight: 0 }}>
											<Button
												fullWidth
												startIcon={
													isSubmitLoading && <CircularProgress size="1rem" />
												}
												disabled={!isValid || isSubmitLoading}
												variant="contained"
												color="primary"
												type="submit"
											>
												Save
											</Button>
										</Grid>
									</Grid>
								</DialogActions>
							</Grid>
						</Grid>
					</Grid>
				</form>
			</Dialog>
			<Snackbar open={addSuccess} autoHideDuration={2500} onClose={handleClose}>
				<Alert severity="info" onClose={handleClose}>
					Success
				</Alert>
			</Snackbar>
			<ErrorModal error={error} onHide={() => setError("")} />
		</>
	);
};
