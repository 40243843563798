import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { useSearchParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .MuiSelect-root": {
			backgroundColor: "#FFF",
		},
	},
	inputLabel: {
		position: "absolute",
		top: -6,
		left: 8,
		fontSize: 11.3,
		backgroundColor: "#FFF",
		color: "#0000008A",
		width: "auto",
		height: "auto",
		zIndex: 100,
		padding: "0 3px",
		borderRadius: 3,
		fontWeight: 400,
		lineHeight: "1.1876em",
		letterSpacing: "0.04208em",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
	},
}));

const StatusList = [
	{ code: "All", status: "All" },
	{ code: "O", status: "Open" },
	{ code: "S", status: "Submitted" },
	{ code: "A", status: "Approved" },
];

type Props = {};

export const StatusFilter = ({}: Props) => {
	const classes = useStyles();
	const [searchParams, setSearchParams] = useSearchParams();

	const statusCode = searchParams.get("status") ?? "";

	const handleFilter = (e: React.ChangeEvent<{ value: unknown }>) => {
		searchParams.set("status", e.target.value as string);
		setSearchParams(searchParams, { replace: true });
	};

	return (
		<div
			className={classes.root}
			style={{ position: "relative", marginTop: 8 }}
		>
			<p className={classes.inputLabel}>Status</p>
			<Select
				variant="outlined"
				margin="dense"
				labelId="demo-simple-select-label"
				id="demo-simple-select"
				value={statusCode}
				onChange={handleFilter}
				style={{ minWidth: "100%" }}
				displayEmpty
			>
				{StatusList.map((status) => (
					<MenuItem key={`status-${status.code}`} value={status.code}>
						{status.status}
					</MenuItem>
				))}
			</Select>
		</div>
	);
};
