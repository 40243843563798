import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { FormInstance } from "../models/FormInstance";

export const fromDate: Date = new Date();
fromDate.setDate(1);
fromDate.setMonth(fromDate.getMonth() - 1);

type FormInstanceFormInstanceStatusCodes = "O" | "S" | "A"; // O:Open | S: Submitted | A: Approved
export type LayoutCodes = "L" | "D" | "T"; // L: List | D: Double Column | T: Triple Column

const FormsInitialState: {
	rows: any;
	rowData: FormInstance[] | undefined;
	columns: any[];
	noResults: boolean;
	allRowsLoading: boolean;

	formInstanceModifiedRows: any[];

	formInstanceRows: any[];
	formInstanceSections: any[];
	formInstanceQuestions: any[];
	formsForUser: any[];

	selectedInstanceId: number | null;
	newInstanceId: number | string | null;
	newInstanceShift: string | null;
	newInstanceProductionDate: Date | null;
	newInstanceFormId: number | string | null;
	newInstanceAssetId: number | string | null;

	dataToGetFormInstances: {
		error: boolean;
		firstTime: boolean;
		AssetId: number | null;
		AssetName: string | null;
		FormId: number | null;
		Start: Date;
		End: Date;
		SearchText: string | null;
		FormInstanceStatusCode: string | null;
	};

	newInstanceShifts: any[];

	formComment: string;

	hasSubmitPermission: boolean;
	submitInitials: string;
	hasApprovePermission: boolean;
	approveInitials: string;
	formInstanceStatus: FormInstanceFormInstanceStatusCodes;

	openEditInstanceModal: boolean;
	refreshGrid: boolean;
	layoutCode: LayoutCodes;
	triggerEditIntance: boolean;
	apiMessage: string | null;
	selectedFormAsset: string | null;
	assetCodes: any;
} = {
	rows: [],
	rowData: undefined,
	columns: [],
	noResults: false,
	allRowsLoading: false,
	formInstanceModifiedRows: [],
	formInstanceRows: [],
	formInstanceSections: [],
	formInstanceQuestions: [],
	formsForUser: [],
	selectedInstanceId: null,
	newInstanceId: null,
	newInstanceShift: null,
	newInstanceProductionDate: null,
	newInstanceFormId: null,
	newInstanceAssetId: null,

	dataToGetFormInstances: {
		error: false,
		firstTime: true,
		AssetId: 1,
		AssetName: "Plant",
		FormId: null,
		Start: fromDate,
		End: moment().hour(23).minute(59).second(59).toDate(),
		SearchText: "",
		FormInstanceStatusCode: "All",
	},

	newInstanceShifts: [],

	formComment: "",
	hasSubmitPermission: true,
	submitInitials: "",
	hasApprovePermission: true,
	approveInitials: "",
	formInstanceStatus: "O",

	openEditInstanceModal: false,
	refreshGrid: false,
	layoutCode: "L",
	triggerEditIntance: false,
	apiMessage: null,

	selectedFormAsset: "",
	assetCodes: {},
};

export const FormsReducer = createSlice({
	name: "__",
	initialState: FormsInitialState,
	reducers: {
		setAllRowsLoading(state, { payload }) {
			state.allRowsLoading = payload;
		},

		setRows(state, { payload }) {
			state.rows = payload;
		},

		setRowData(state, { payload }) {
			state.rowData = payload;
		},

		setColumns(state, { payload }) {
			state.columns = payload;
		},

		setNoResults(state, { payload }) {
			state.noResults = payload;
		},
		setFormInstanceRows(state, { payload }) {
			state.formInstanceRows = payload;
		},
		setFormInstanceSections(state, { payload }) {
			state.formInstanceSections = payload;
		},
		setFormInstanceQuestions(state, { payload }) {
			state.formInstanceQuestions = payload;
		},
		setFormForUser(state, { payload }) {
			state.formsForUser = payload;
		},
		setNewInstanceShift(state, { payload }) {
			state.newInstanceShift = payload;
		},

		setNewInstanceProductionDate(state, { payload }) {
			state.newInstanceProductionDate = payload;
		},

		setNewInstanceFormId(state, { payload }) {
			state.newInstanceFormId = payload;
		},
		setNewInstanceAssetId(state, { payload }) {
			state.newInstanceAssetId = payload;
		},
		setNewInstanceId(state, { payload }) {
			state.newInstanceId = payload;
		},
		setStartForms(state, { payload }) {
			state.dataToGetFormInstances.Start = payload;
		},
		setEndForms(state, { payload }) {
			state.dataToGetFormInstances.End = payload;
		},
		setFirstTime(state, { payload }) {
			state.dataToGetFormInstances.firstTime = payload;
		},
		setSearchTextForms(state, { payload }) {
			state.dataToGetFormInstances.SearchText = payload;
		},
		setFormIdForms(state, { payload }) {
			state.dataToGetFormInstances.FormId = payload;
		},
		setAssetIdForms(state, { payload }) {
			state.dataToGetFormInstances.AssetId = payload;
		},
		setAssetNameForms(state, { payload }) {
			state.dataToGetFormInstances.AssetName = payload;
		},
		setFormInstanceStatusCodeForms(state, { payload }) {
			state.dataToGetFormInstances.FormInstanceStatusCode = payload;
		},
		setSelectedInstanceId(state, { payload }) {
			state.selectedInstanceId = payload;
		},
		setNewInstanceShifts(state, { payload }) {
			state.newInstanceShifts = payload;
		},
		setFormInstanceModifiedRows(state, { payload }) {
			state.formInstanceModifiedRows = payload;
		},
		setHasSubmitPermissions(state, { payload }) {
			state.hasSubmitPermission = payload;
		},
		setHasApprovePermissions(state, { payload }) {
			state.hasApprovePermission = payload;
		},
		setSubmitInitials(state, { payload }) {
			state.submitInitials = payload;
		},
		setApproveInitials(state, { payload }) {
			state.approveInitials = payload;
		},
		setformComment(state, { payload }) {
			state.formComment = payload;
		},
		setFormInstanceStatus(state, { payload }) {
			state.formInstanceStatus = payload;
		},
		setOpenEditInstanceModal(state, { payload }) {
			state.openEditInstanceModal = payload;
		},
		setRefreshGrid(state, { payload }) {
			state.refreshGrid = payload;
		},
		setLayoutCode(state, { payload }) {
			state.layoutCode = payload;
		},
		setTriggerEditIntance(state, { payload }) {
			state.triggerEditIntance = payload;
		},
		setApiMessage(state, { payload }) {
			state.apiMessage = payload;
		},
		setSelectedFormAsset(state, { payload }) {
			state.selectedFormAsset = payload;
		},
		setAssetCodes(state, { payload }) {
			state.assetCodes = payload;
		},
	},
});
