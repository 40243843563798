import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			"& .MuiSelect-root": {
				backgroundColor: "#FFF",
			},
		},
		inputLabel: {
			position: "absolute",
			top: -6,
			left: 8,
			fontSize: 11.3,
			backgroundColor: "#FFF",
			color: "#0000008A",
			width: "auto",
			height: "auto",
			zIndex: 100,
			padding: "0 3px",
			borderRadius: 3,
			fontWeight: 400,
			lineHeight: "1.1876em",
			letterSpacing: "0.04208em",
			fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		},
		formControl: {
			width: "99%",
			marginTop: 8,
		},
		chips: {
			display: "flex",
			flexWrap: "wrap",
		},
		chip: {
			margin: 2,
		},
		noLabel: {
			marginTop: theme.spacing(3),
		},
	}),
);

type Props = {
	value: number;
	onChange: Function;
	disabled?: boolean;
};

const optionTexts: { [key: string]: string } = {
	O: "OK",
	W: "Warning",
	A: "Alert",
};

export const OptionTypePicker = ({ value, onChange, disabled }: Props) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<FormControl
				className={classes.formControl}
				style={{ position: "relative" }}
			>
				<p className={classes.inputLabel}>Option</p>
				<Select
					disabled={disabled ? disabled : false}
					style={{ padding: "0 !important", marginBottom: 10 }}
					variant="outlined"
					margin="dense"
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					autoWidth={true}
					value={value}
					onChange={onChange as any}
				>
					{Object.entries(optionTexts).map(([code, text]) => (
						<MenuItem key={`option-${code}`} value={code}>
							<Grid container alignItems="center">
								<div style={{ marginLeft: 10 }}>{text}</div>
							</Grid>
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};
