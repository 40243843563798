import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import { KeyboardEventHandler } from "react";
import { useFormsContext } from "../../context/FormsContext";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .MuiSelect-root": {
			backgroundColor: "#FFF",
		},
	},
	inputLabel: {
		position: "absolute",
		top: -6,
		left: 8,
		fontSize: 11.3,
		backgroundColor: "#FFF",
		color: "#0000008A",
		width: "auto",
		height: "auto",
		zIndex: 100,
		padding: "0 3px",
		borderRadius: 3,
		fontWeight: 400,
		lineHeight: "1.1876em",
		letterSpacing: "0.04208em",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
	},
}));

type Props = {
	onKeyDown: KeyboardEventHandler<HTMLDivElement> | undefined;
};

export const SearchFilter = ({ onKeyDown }: Props) => {
	const classes = useStyles();

	const {
		state: { searchText },
		actions: { setSearchText },
	} = useFormsContext();

	const onTextChange = (e: any) => {
		setSearchText(e.target.value);
	};

	return (
		<div style={{ position: "relative" }}>
			<TextField
				className={classes.root}
				style={{ borderTopLeftRadius: 3, borderTopRightRadius: 3 }}
				label="Form name to search"
				variant="outlined"
				margin="dense"
				onKeyDown={onKeyDown}
				value={searchText}
				onChange={onTextChange}
				fullWidth
				autoComplete="off"
			/>
			<>
				<i
					className="fa fa-search"
					style={{
						position: "absolute",
						right: 17,
						top: 20,
						cursor: "pointer",
					}}
				></i>
			</>
		</div>
	);
};
