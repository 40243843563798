import { get } from "react-hook-form";
import { getForms } from "../repositories/FormsRepository";
import { useFormsContext } from "../context/FormsContext";
type Props = {
	setIsLoading: (isLoading: boolean) => void;
	setError: (err: string) => void;
};

export const useSearchForms = ({ setIsLoading, setError }: Props) => {
	const {
		state: { searchText },
		actions: { setFormsList },
	} = useFormsContext();

	return async () => {
		// setFormsList([]);
		setIsLoading(true);

		const res = await getForms(searchText);
		if (res.ok) {
			const rowsSource = get(res, "data.tables[0].rows", []);
			if (rowsSource.length) {
				setFormsList(rowsSource);
			} else {
				setFormsList([]);
			}
			setIsLoading(false);
		} else {
			setError(res.message);
			setIsLoading(false);
		}
	};
};
