import { Grid } from "@material-ui/core";
import {
	ShiftSelectorModal,
	FormAssetSelectorModal,
	ProductionDateSelectorModal,
} from "../filters";
import { Questions } from "./Questions";

export const ProductionDateStep = () => (
	<Grid container>
		<Grid item md={4} xs={12} />
		<Grid item md={4} xs={12}>
			<ProductionDateSelectorModal />
		</Grid>
	</Grid>
);
export const ShiftStep = () => (
	<Grid container>
		<Grid item md={4} xs={12} />
		<Grid item md={4} xs={12}>
			<ShiftSelectorModal />
		</Grid>
	</Grid>
);

export const FormAndAssetStep = () => (
	<Grid container>
		<Grid item md={4} xs={12} />
		<Grid item md={4} xs={12}>
			<FormAssetSelectorModal />
		</Grid>
	</Grid>
);

export const QuestionsStep = () => <Questions />;
