import { Option } from "./Option";

export const INITIAL_VALUES_OPTION: Option = {
	OptionId: null,
	OptionText: "",
	QuestionId: null,
	IsEnabled: true,
	OptionTypeCode: "O",
	SortOrder: null,
};
