import { makeStyles } from "@material-ui/core";

export const useTagColorsStyles = makeStyles({
	swatch: {
		width: "25px",
		height: "25px",
		display: "inline-block",
		margin: "2px",
		borderRadius: "4px",
		cursor: "pointer",
	},
	popover: {
		display: "flex",
		flexWrap: "wrap",
		padding: "10px",
		maxWidth: "165px",
	},
});
