import { Grid } from "@material-ui/core";
import { ReactNode, Suspense } from "react";
import { LazyLoading } from "../loaders/Loader/LazyLoading";

export const CustomSuspense = ({ children }: { children: ReactNode }) => {
	return (
		<Suspense
			fallback={
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					style={{ minHeight: "80vh" }}
				>
					<Grid item>
						<LazyLoading />
					</Grid>
				</Grid>
			}
		>
			{children}
		</Suspense>
	);
};
