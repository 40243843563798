export const getGroupedInstance = (formInstance: any) => {
	const groupedSections = formInstance.reduce((acc: any, question: any) => {
		acc[question.SectionId] = acc[question.SectionId] || [];
		acc[question.SectionId].push(question);
		return acc;
	}, {});

	const groupedQuestions = formInstance.reduce((acc: any, option: any) => {
		acc[option.QuestionId] = acc[option.QuestionId] || [];
		acc[option.QuestionId].push(option);
		return acc;
	}, {});

	return {
		formInstance,
		groupedSections,
		groupedQuestions,
	};
};
